var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center flex-nowrap gap-x-2"},[_c('div',{class:[
            {
                [_setup.SIZE.LARGE]:  'text-base',
                [_setup.SIZE.MEDIUM]: 'text-sm',
                [_setup.SIZE.SMALL]:  'text-sm',
            }[_vm.size],
        ]},[_vm._v(" Go to ")]),_c('base-select',{attrs:{"options":_setup.options,"type":_vm.type,"size":_vm.size,"disabled":_vm.disabled,"no-sorting":"","use-first":"","auto-width":""},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
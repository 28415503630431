<template>
    <div
        class="fixed inset-x-0 bottom-0 transition-all duration-500 ease-in-out z-60"
        :class="showBanner ? 'translate-y-0' : 'translate-y-full'"
    >
        <div class="px-4 pb-10 mx-auto max-w-7xl sm:pb-4">
            <div class="flex flex-col justify-between p-3 space-y-3 bg-gray-900 rounded-lg shadow-lg">
                <div class="flex justify-between items-center space-x-3">
                    <div class="flex items-center space-x-3">
                        <div v-if="icon" class="p-2 text-white bg-gray-700 rounded-lg">
                            <BaseIcon :name="icon.name" :prefix="icon.prefix" size="2x"/>
                        </div>
                        <p class="max-w-5xl text-base font-medium text-gray-200" v-html="text" :data-test="dataTest('text')"/>
                    </div>
                    <div class="flex items-center space-x-3">
                        <a
                            :href="buttonLink"
                            :data-test="dataTest('arrow-button')"
                            target="_blank"
                            class="hidden flex-shrink justify-center items-center p-2 text-base font-medium leading-6 text-gray-900 whitespace-nowrap bg-white rounded-md cursor-pointer sm:flex hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring"
                        >
                            <span class="hidden px-1 md:block">
                                {{ buttonText }}
                            </span>
                            <div class="flex justify-center items-center w-6 h-6">
                                <BaseIcon name="arrow-right" prefix="fas" size="sm"/>
                            </div>
                        </a>
                        <button
                            v-if="closable"
                            class="inline-flex justify-center items-center p-2 text-gray-100 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                            :data-test="dataTest('close-button')"
                            @click="close"
                        >
                            <svg class="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div v-if="closable && buttonLink" class="w-full sm:hidden">
                    <a
                        class="flex justify-center items-center px-3 py-2 space-x-2 text-base font-medium leading-6 text-gray-900 bg-white rounded-md cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring"
                        target="_blank"
                        :href="buttonLink"
                        :data-test="dataTest('mobile-button')"
                    >
                        <span>
                            {{ buttonText }}
                        </span>
                        <BaseIcon name="arrow-right" prefix="fas" size="sm"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:  'BaseBanner',
    props: {
        show:       Boolean,
        closable:   Boolean,
        icon:       Object,
        text:       String,
        buttonText: String,
        buttonLink: String,
    },
    data() {
        return {
            showBanner: this.show,
        };
    },
    watch:   {
        show(value) {
            this.showBanner = value;
        },
    },
    methods: {
        close() {
            this.showBanner = false;
            this.$emit('close');
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nativeType !== 'hidden'),expression:"nativeType !== 'hidden'"}],staticClass:"inline-flex group",class:{
        'cursor-not-allowed': _vm.disabled,
    },on:{"mouseover":_vm.handleMouseover,"mouseleave":_vm.handleMouseleave,"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.isTextarea)?[_c('div',{staticClass:"relative flex flex-1 items-center gap-x-2 self-start overflow-hidden rounded border transition-colors focus:outline-none",class:[
                _vm.typeClass,
                _vm.borderClass,
                _vm.backgroundClass,
                {
                    'pointer-events-none': _vm.disabled,
                }
            ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"textarea",staticClass:"grow border-none bg-transparent outline-none focus:outline-none",class:[
                    _vm.textareaTextClass,
                    _vm.placeholderClass,
                    _vm.paddingClass,
                    {
                        'pointer-events-none select-none': _vm.disabled,
                        'text-center': _vm.center,
                    }
                ],style:(_vm.textareaStyle),attrs:{"name":_vm.name,"placeholder":_vm.placeholder,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"rows":_vm.rows},domProps:{"value":(_vm.model)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange,"keydown":_vm.handleKeydown,"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}}),(_vm.showLimitation)?_c('span',{staticClass:"absolute right-2 whitespace-nowrap rounded px-1 text-xs text-opacity-75 bottom-0.5 py-0.5",class:[
                    _vm.typeClass,
                    _vm.backgroundClass,
                ]},[_vm._v(" "+_vm._s(_vm.length)+" / "+_vm._s(_vm.limit)+" ")]):_vm._e()])]:[(_vm.$slots.prepend)?_c('div',{class:[
                _vm.prependClass || ['rounded-l border-r-0', _vm.slotClass],
                _vm.heightClass
            ]},[_vm._t("prepend")],2):_vm._e(),_c('div',{staticClass:"relative flex flex-1 items-center self-start overflow-hidden border transition-colors focus:outline-none",class:[
                _vm.wrapperClass || [_vm.typeClass, _vm.borderClass, _vm.backgroundClass,],
                {
                    'rounded-l': !_vm.$slots.prepend,
                    'rounded-r': !_vm.$slots.append,
                    'pointer-events-none': _vm.disabled,
                }
            ]},[(_vm.showPrefix)?_c('span',{staticClass:"inline-flex items-center gap-x-2",class:_vm.paddingClass},[_vm._t("prefix")],2):_vm._e(),((_vm.internalNativeType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"w-full border-none bg-transparent outline-none focus:outline-none",class:[
                    _vm.inputClass || [_vm.paddingClass,],
                    _vm.textClass,
                    _vm.heightClass,
                    _vm.placeholderClass,
                    {
                        'pointer-events-none select-none': _vm.disabled,
                        'text-center': _vm.center,
                        'pl-0': _vm.showPrefix,
                        'pr-0': _vm.showSuffix,
                    }
                ],attrs:{"name":_vm.name,"placeholder":_vm.placeholder,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"size":_vm.nativeSize,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":[function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}},_vm.handleChange],"keydown":_vm.handleKeydown}}):((_vm.internalNativeType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"w-full border-none bg-transparent outline-none focus:outline-none",class:[
                    _vm.inputClass || [_vm.paddingClass,],
                    _vm.textClass,
                    _vm.heightClass,
                    _vm.placeholderClass,
                    {
                        'pointer-events-none select-none': _vm.disabled,
                        'text-center': _vm.center,
                        'pl-0': _vm.showPrefix,
                        'pr-0': _vm.showSuffix,
                    }
                ],attrs:{"name":_vm.name,"placeholder":_vm.placeholder,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"size":_vm.nativeSize,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":[function($event){_vm.model=null},_vm.handleChange],"keydown":_vm.handleKeydown}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"w-full border-none bg-transparent outline-none focus:outline-none",class:[
                    _vm.inputClass || [_vm.paddingClass,],
                    _vm.textClass,
                    _vm.heightClass,
                    _vm.placeholderClass,
                    {
                        'pointer-events-none select-none': _vm.disabled,
                        'text-center': _vm.center,
                        'pl-0': _vm.showPrefix,
                        'pr-0': _vm.showSuffix,
                    }
                ],attrs:{"name":_vm.name,"placeholder":_vm.placeholder,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"size":_vm.nativeSize,"type":_vm.internalNativeType},domProps:{"value":(_vm.model)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange,"keydown":_vm.handleKeydown,"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}}),(_vm.showSuffix)?_c('span',{staticClass:"inline-flex shrink-0 items-center gap-x-2",class:_vm.paddingClass},[(_vm.$slots.suffix)?[_vm._t("suffix")]:_vm._e(),(_vm.showClear)?_c('span',{staticClass:"inline-flex cursor-pointer items-center justify-end opacity-0 transition-opacity hover:opacity-100",class:{
                        'opacity-50': _vm.focused || _vm.hovering,
                    },on:{"mousedown":function($event){$event.preventDefault();return _vm.handleMousedown.apply(null, arguments)},"click":function($event){$event.preventDefault();return _vm.handleClear.apply(null, arguments)}}},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"}})])]):_vm._e(),(_vm.showPasswordVisible)?_c('span',{staticClass:"inline-flex cursor-pointer items-center justify-end opacity-0 transition-opacity hover:opacity-100",class:{
                        'opacity-50': !_vm.isEmptyModel || _vm.focused || _vm.hovering,
                    },on:{"mousedown":function($event){$event.preventDefault();return _vm.handleMousedown.apply(null, arguments)},"click":_vm.handlePasswordVisible}},[(_vm.passwordVisible)?_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}})]):_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"}})])]):_vm._e(),(_vm.showLimitation)?_c('span',{staticClass:"whitespace-nowrap text-xs text-opacity-75",class:_vm.typeClass},[_vm._v(" "+_vm._s(_vm.length)+" / "+_vm._s(_vm.limit)+" ")]):_vm._e()],2):_vm._e()]),(_vm.$slots.append)?_c('div',{class:[
                _vm.appendClass || ['rounded-r border-l-0', _vm.slotClass],
                _vm.heightClass
            ]},[_vm._t("append")],2):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="flex flex-1 justify-center">
        <the-loader/>
        <allow-cookie-banner/>
        <main class="overflow-auto relative flex-1 focus:outline-none" data-test="content">
            <slot/>
        </main>
    </div>
</template>

<script>
import AllowCookieBanner from '@/components/allow-cookie-banner';
import TheLoader from '@/components/the-loader';

export default {
    name:       'LayoutContent',
    components: {
        AllowCookieBanner,
        TheLoader,
    },
};
</script>

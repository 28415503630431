// Back-End Access list in file - module/APP/src/APP/Acl/AccessList.php

export const BACKUP = {
    VIEW:    'backup.view',
    CONNECT: 'backup.connect',
    LIST:    'backup.list',
};

export const BACKUP_INSTANCE = {
    VIEW: 'backup-instance.view',
};

export const BACKUP_ENTITY = {
    LIST: 'backup-entity.list',
};

export const BACKUP_EXPORT = {
    VIEW: 'backup-export.view',
};

export const BACKUP_SCHEDULE = {
    VIEW: 'backup-schedule.view',
};

export const BACKUP_PRICING = {
    LIST: 'backup-pricing.list',
};

export const CONNECTION = {
    VIEW:       'connection.view',
    CONNECT:    'connection.connect',
    DISCONNECT: 'connection.disconnect',
    LIST:       'connection.list',
    EDIT:       'connection.edit',
};

export const DEV = {
    SANDBOX: 'dev-sandbox.view',
};

export const ESTIMATE = {
    VIEW:    'estimate.view',
    CONNECT: 'estimate.connect',
    LIST:    'estimate.list',
};

export const ESTIMATOR_WIZARD = {
    PROCESS: 'estimator-wizard.process',
};

export const MIGRATION = {
    DETAILS:         'migration.details',
    EDIT:            'migration.edit',
    ENTITY_PRIORITY: 'migration.entity-priority',
    LIST:            'migration.list',
    MAPPING:         'migration.mapping',
    MATCHING:        'migration.matching',
    NAME:            'migration.name',
    WEB_ACCESS:      'migration.web-access',
};

export const MIGRATION_ENTITY = {
    ADD:               'migration-entity.add',
    CHANGE_TARGET:     'migration-entity.change-target',
    FILTER:            'migration-entity.filter',
    RELATION_PRIORITY: 'migration-entity.relation-priority',
    TOGGLE:            'migration-entity.toggle',
};

export const MIGRATION_WIZARD = {
    PROCESS: 'migration-wizard.process',
};

export const MIGRATION_CUSTOM_MIGRATION_QUOTE = {
    FORM: 'migration-custom-migration-quote.form',
};

export const MAPPING = {
    VIEW:                  'mapping.view',
    COMPARE:               'mapping.compare',
    VALIDATE:              'mapping.validate',
    AUTO_MAP:              'mapping.auto-map',
    CLEAR:                 'mapping.clear',
    RESET:                 'mapping.reset',
    CONSTANT_FOR_RELATION: 'mapping.constant-for-relation',
    DATA_EXAMPLES:         'mapping.data-examples',
    DEFAULT_FOR_RELATION:  'mapping.default-for-relation',
    EXPORT:                'mapping.export',
    FIELDS_FILTERS:        'mapping.fields-filters',
    FIELDS_FUNCTIONS:      'mapping.fields-functions',
    FIELDS_OPTIONS:        'mapping.fields-options',
    FIELDS_USAGE:          'mapping.fields-usage',
    IMPORT:                'mapping.import',
    POINTERS_COMBINATIONS: 'mapping.pointers-combinations',
    SHOW_FIELD_NAME:       'mapping.show-field-name',
    SHOW_IGNORES:          'mapping.show-ignores',
    SHOW_OVERRIDDEN:       'mapping.show-overridden',
    SHOW_REFRESH_AUTO_MAP: 'mapping.show-refresh-auto-map',
    SHOW_RESET_CONFIRM:    'mapping.show-reset-confirm',
    SWITCH_RULES:          'mapping.switch-rules',
    UNIQUE_VALUES:         'mapping.unique-values',
    VERSIONS:              'mapping.versions',
    PRESET:                'mapping.preset',
    TEST_MODE:             'mapping.test-mode',
    CRM_FIELD:             'mapping.crm-field',
};

export const SERVICE_PLAN = {
    VIEW:                              'service-plan.view',
    CANCEL:                            'service-plan.cancel',
    SUBSCRIPTION_CANCEL_AT_PERIOD_END: 'service-plan.subscription-cancel-at-period-end',
    SUBSCRIPTION_CONTINUE:             'service-plan.subscription-continue',
};

export const USER = {
    APPROVE:          'user.approve',
    LOGIN:            'user.login',
    REGISTER:         'user.register',
    LOGOUT:           'user.logout',
    PARTNER_REQUEST:  'user.partner-request',
    SOCIAL:           'user.social',
    SETTINGS:         'user.settings',
    SHOW_GUEST_NAME:  'user.show-guest-name',
    TWO_FACTOR:       'user.two-factor',
    UN_SUBSCRIBE:     'user.un-subscribe',
    FORGOT_PASSWORD:  'user.forgot-password',
    RESET_PASSWORD:   'user.reset-password',
    CHANGE_PASSWORD:  'user.change-password',
    EXPIRED_PASSWORD: 'user.expired-password',
};

export const MANAGER = {
    ACL_ACCESS_VIEW:                           'manager.acl-access.view',
    ACL_ROLES_ACCESS_EXPORT:                   'manager.acl-roles-access.export',
    ACL_ROLES_ACCESS_IMPORT:                   'manager.acl-roles-access.import',
    ACL_ROLES_ACCESS_UPDATE:                   'manager.acl-roles-access.update',
    ACL_ROLES_ACCESS_VIEW:                     'manager.acl-roles-access.view',
    ACL_ROLE_VIEW:                             'manager.acl-role.view',
    API_DATA_CACHE_VIEW:                       'manager.api-data-cache.view',
    API_LOG_VIEW:                              'manager.api-log.view',
    BACKUP_INSTANCE_EXPORT_PAUSE:              'manager.backup-instance-export.pause',
    BACKUP_INSTANCE_EXPORT_RESUME:             'manager.backup-instance-export.resume',
    BACKUP_INSTANCE_EXPORT_STOP:               'manager.backup-instance-export.stop',
    BACKUP_INSTANCE_PAUSE:                     'manager.backup-instance.pause',
    BACKUP_INSTANCE_RESUME:                    'manager.backup-instance.resume',
    BACKUP_INSTANCE_STOP:                      'manager.backup-instance.stop',
    BACKUP_INSTANCE_ENTITY_THREAD_LIST:        'manager.backup-instance-entity-thread.list',
    BACKUP_INSTANCE_EXPORT_ENTITY_THREAD_LIST: 'manager.backup-instance-export-entity-thread.list',
    BACKUP_LIST:                               'manager.backup.list',
    BALANCE_VIEW:                              'manager.balance.view',
    CALENDAH_VIEW:                             'manager.calendah.view',
    COUPON_VIEW:                               'manager.coupon.view',
    CRM_VIEW:                                  'manager.crm.view',
    EMAIL_VIEW:                                'manager.email.view',
    ESTIMATE_DATA_REMOVAL:                     'manager.estimate.data-removal',
    ESTIMATE_INSTANCE_PAUSE:                   'manager.estimate-instance.pause',
    ESTIMATE_INSTANCE_RESUME:                  'manager.estimate-instance.resume',
    ESTIMATE_INSTANCE_STOP:                    'manager.estimate-instance.stop',
    ESTIMATE_LIST:                             'manager.estimate.list',
    ESTIMATE_UPDATE:                           'manager.estimate.update',
    EVENT_VIEW:                                'manager.event.view',
    JIRA_VIEW:                                 'manager.jira.view',
    LOG_VIEW:                                  'manager.log.view',
    MAPPING_PRESET_VIEW:                       'manager.mapping-preset.view',
    MIGRATION_INSTANCE_CREATE:                 'manager.migration.create-instance',
    MIGRATION_INSTANCE_PAUSE:                  'manager.migration.instance-pause',
    MIGRATION_INSTANCE_RESUME:                 'manager.migration.instance-resume',
    MIGRATION_INSTANCE_STOP:                   'manager.migration.instance-stop',
    MIGRATION_INSTANCE_ENTITY_THREAD_LIST:     'manager.migration-instance-entity-thread.list',
    MIGRATION_PAYMENT_VIEW:                    'manager.migration-payment.view',
    MIGRATION_VIEW:                            'manager.migration.view',
    PAYMENT_IPN_VIEW:                          'manager.payment-ipn.view',
    PAYMENT_VIEW:                              'manager.payment.view',
    PLATFORM_COMPARE:                          'manager.platform.compare',
    PLATFORM_METHODS:                          'manager.platform.methods',
    PRICES_VIEW:                               'manager.prices.view',
    PROCESS_RESUME:                            'manager.process.resume',
    PROCESS_TERMINATE:                         'manager.process.terminate',
    PROCESS_TERMINATION_VIEW:                  'manager.process.termination.view',
    REPORT_MIGRATION:                          'manager.report.migration',
    REPORT_SALES:                              'manager.report.sales',
    SERVICE_PAYMENT_VIEW:                      'manager.service-payment.view',
    SERVICE_PLAN_VIEW:                         'manager.service-plan.view',
    SERVICE_PLAN_MANAGE:                       'manager.service-plan.manage',
    SURVEY_ANSWER_VIEW:                        'manager.survey-answer.view',
    TEST_VIEW:                                 'manager.test.view',
    USER_VIEW:                                 'manager.user.view',
};

export const OTHER = {
    ERROR_NOTIFICATION: 'front-end.error-notification',
    PAYMENT_ADD:        'payment.add',
};

<template>
    <sidebar-nested-section
        :title="$t('home backups title')"
        :tooltip="$t('home backups title')"
        :go-back-title="$t('backup back to list')"
        :go-back-title-tooltip="$t('backup back to list')"
        :create-title="$t('backup create')"
        :reconnect-title="$t('backup reconnect')"
        :section-name="sectionName"
        :section-route="sectionRoute"
        :section-active="sectionActive"
        :section-icon="sectionIcon"
        :nested-sections="nestedSections"
        :is-active-authorization="isActiveAuthorization"
        :is-locked="isLocked"
        @create="createBackup"
        @navigate="navigateSection"
        @reconnect="showConnectionPopup = true"
    >
        <template v-slot:under-section>
            <div
                v-if="isActivePlan"
                class="shrink-0 pt-2 w-60"
                :class="collapsed ? 'md:hidden' : ''"
            >
                <plan-card :plan="backupPlan"/>
            </div>
            <create-popup
                v-if="backupPlan"
                :show="showCreatePopup"
                @closePopup="showCreatePopup = false"
            />
            <connection-popup
                v-model="showConnectionPopup"
                :connection="connection"
                type="source"
                locked
            />
        </template>
    </sidebar-nested-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { get } from 'lodash';
import SidebarNestedSection from '@/components/app/sidebar/section/nested-section';
import PlanCard from '@/components/backup/components/pricing/plan/card';
import CreatePopup from '@/components/backup/components/create-popup';
import SidebarSection from '@/components/app/sidebar/section/section';
import ConnectionPopup from '@/modules/connection/components/popup';

export default {
    name:       'SidebarBackup',
    extends:    SidebarSection,
    components: {
        CreatePopup,
        ConnectionPopup,
        SidebarNestedSection,
        PlanCard,
    },
    data() {
        return {
            sectionName:  'backup',
            sectionRoute: 'backupList',
            sectionIcon:  { name: 'database' },

            showCreatePopup:     false,
            showConnectionPopup: false,
        };
    },
    computed: {
        ...mapState('sidebar', {
            collapsed: 'collapsed',
        }),
        ...mapState('backup', {
            connection: state => state.data.source,
        }),
        ...mapGetters({
            backupPlan:            'backup/getPlan',
            isActiveAuthorization: 'backup/isAuthorized',
            isLocked:              'backup/isLocked',
        }),
        nestedSections() {
            return [
                {
                    label: this.$t('backup runs'),
                    icon:  {
                        name:   'database',
                        prefix: 'fas',
                    },
                    route: {
                        name:   'backupInstances',
                        params: {
                            backupId: this.$route.params.backupId,
                        },
                    },
                },
                {
                    label: this.$t('backup objects'),
                    icon:  {
                        name:   'list-ul',
                        prefix: 'fas',
                    },
                    route: {
                        name:   'backupObjects',
                        params: {
                            backupId: this.$route.params.backupId,
                        },
                    },
                },
                {
                    label: this.$t('backup exports'),
                    icon:  {
                        name:   'download',
                        prefix: 'fas',
                    },
                    route: {
                        name:   'backupExports',
                        params: {
                            backupId: this.$route.params.backupId,
                        },
                    },
                },
                {
                    label: this.$t('backup schedule'),
                    icon:  {
                        name:   'calendar-alt',
                        prefix: 'far',
                    },
                    route: {
                        name:   'backupSchedule',
                        params: {
                            backupId: this.$route.params.backupId,
                        },
                    },
                },
                {
                    label: this.$t('backup pricing'),
                    icon:  {
                        name:   'wallet',
                        prefix: 'fas',
                    },
                    route: {
                        name:   'backupPricing',
                        params: {
                            backupId: this.$route.params.backupId,
                        },
                    },
                },
                // {
                //     label: 'History',
                //     icon:  {
                //         name:   'history',
                //         prefix: 'fas',
                //     },
                //     route: {
                //         name:   'backupHistory',
                //         params: {
                //             backupId: this.$route.params.backupId,
                //         },
                //     },
                // },
            ];
        },
        isActivePlan() {
            return get(this.backupPlan, 'status') === 'active';
        },
    },
    methods:  {
        createBackup() {
            this.showCreatePopup = true;
        },
        navigateSection() {
            this.$emit('navigate');
        },
    },
};
</script>

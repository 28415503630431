var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.controlsPosition === _setup.CONTROLS_POSITION.OUTSIDE)?_c('div',{staticClass:"flex items-stretch",class:_setup.sizeClass},[_c('button',{class:_setup.getOutsideButtonClass(_setup.decreaseDisabled),attrs:{"disabled":_setup.decreaseDisabled},on:{"mousedown":_setup.startDecrease,"mouseup":_setup.endDecrease}},[_c(_setup.BaseInputIconMinus,{class:_setup.iconClass})],1),_c('base-input',{attrs:{"center":"","value":_setup.model,"name":_vm.name,"placeholder":_vm.placeholder,"size":_vm.size,"type":_vm.type,"disabled":_vm.disabled},on:{"focus":_setup.handleFocus,"blur":_setup.handleBlur,"input":_setup.input}}),_c('button',{class:_setup.getOutsideButtonClass(_setup.increaseDisabled),attrs:{"disabled":_setup.increaseDisabled},on:{"mousedown":_setup.startIncrease,"mouseup":_setup.endIncrease}},[_c(_setup.BaseInputIconPlus,{class:_setup.iconClass})],1)],1):_c('base-input',{class:_setup.sizeClass,attrs:{"value":_setup.model,"prepend-class":"flex items-center justify-center","append-class":"flex items-center justify-center","center":"","name":_vm.name,"placeholder":_vm.placeholder,"size":_vm.size,"type":_vm.type,"disabled":_vm.disabled},on:{"focus":_setup.handleFocus,"blur":_setup.handleBlur,"input":_setup.input},scopedSlots:_vm._u([(_vm.controlsPosition === _setup.CONTROLS_POSITION.DEFAULT)?{key:"prepend",fn:function(){return [_c('button',{staticClass:"-mr-px h-full rounded-l",class:_setup.getButtonClass(_setup.decreaseDisabled),attrs:{"disabled":_setup.decreaseDisabled},on:{"mousedown":_setup.startDecrease,"mouseup":_setup.endDecrease}},[_c(_setup.BaseInputIconMinus,{class:_setup.iconClass})],1)]},proxy:true}:null,{key:"append",fn:function(){return [(_vm.controlsPosition === _setup.CONTROLS_POSITION.RIGHT)?_c('div',{staticClass:"flex h-full flex-col",class:[
                {
                    [_setup.SIZE.SMALL]:  'w-6',
                    [_setup.SIZE.MEDIUM]: 'w-8',
                    [_setup.SIZE.LARGE]:  'w-10',
                }[_vm.size]
            ]},[_c('button',{staticClass:"-ml-px h-[calc(50%+1px)] rounded-tr",class:_setup.getButtonClass(_setup.increaseDisabled),attrs:{"disabled":_setup.increaseDisabled},on:{"mousedown":_setup.startIncrease,"mouseup":_setup.endIncrease}},[_c(_setup.BaseInputIconUp,{staticClass:"w-3"})],1),_c('button',{staticClass:"-mt-px -ml-px h-[calc(50%+1px)] rounded-br",class:_setup.getButtonClass(_setup.decreaseDisabled),attrs:{"disabled":_setup.decreaseDisabled},on:{"mousedown":_setup.startDecrease,"mouseup":_setup.endDecrease}},[_c(_setup.BaseInputIconDown,{staticClass:"w-3"})],1)]):_vm._e(),(_vm.controlsPosition === _setup.CONTROLS_POSITION.DEFAULT)?_c('button',{staticClass:"-ml-px h-full rounded-r",class:_setup.getButtonClass(_setup.increaseDisabled),attrs:{"disabled":_setup.increaseDisabled},on:{"mousedown":_setup.startIncrease,"mouseup":_setup.endIncrease}},[_c(_setup.BaseInputIconPlus,{class:_setup.iconClass})],1):_vm._e()]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }
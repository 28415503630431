import { render, staticRenderFns } from "./base-json-form-item-error.vue?vue&type=template&id=64bd945c"
import script from "./base-json-form-item-error.vue?vue&type=script&setup=true&lang=js"
export * from "./base-json-form-item-error.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"leading-none"},[_vm._t("default"),_c('portal',[(_setup.render)?_c('transition',_vm._b({attrs:{"appear":""},on:{"after-leave":_setup.afterTransition}},'transition',_vm.transition,false),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.show),expression:"show"}],ref:"tooltipRef",staticClass:"flex w-max items-center rounded-md bg-opacity-100 text-xs font-normal text-opacity-100 z-max",class:[
                    {
                        'pointer-events-none': !_vm.enterable,
                        'hidden': _setup.referenceHidden
                    },
                    {
                        dark: 'bg-gray-600 text-white',
                        light: 'bg-white border text-gray-600',
                    }[_vm.type],
                    _vm.tooltipClass || 'max-w-sm p-2 text-center'
                ],style:({
                    position: _setup.strategy,
                    left: _setup.tooltipX,
                    top: _setup.tooltipY,
                }),on:{"mouseenter":_setup.tooltipEnter,"mouseleave":_setup.tooltipLeave}},[_vm._t("content",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.content)}})]}),(_vm.arrow)?_c('div',{ref:"arrowRef",staticClass:"absolute z-[-1]",style:({
                        left: _setup.arrowX,
                        top: _setup.arrowY,
                        right: '',
                        bottom: '',
                        [_setup.oppositeSide]: _setup.addUnit(-_vm.arrowOffset),
                    })},[_vm._t("arrow",function(){return [_c('div',{staticClass:"box-border rotate-45 w-2.5 h-2.5",class:[
                                _vm.arrowClass || {
                                    dark: 'bg-gray-600',
                                    light: 'bg-white border'
                                }[_vm.type],
                                {
                                    [_setup.PLACEMENTS.TOP]: 'border-b-gray-200/0 border-r-gray-200/0',
                                    [_setup.PLACEMENTS.BOTTOM]: 'border-t-gray-200/0 border-l-gray-200/0',
                                    [_setup.PLACEMENTS.RIGHT]: 'border-b-gray-200/0 border-l-gray-200/0',
                                    [_setup.PLACEMENTS.LEFT]: 'border-t-gray-200/0 border-r-gray-200/0',
                                }[_setup.oppositeSide],
                            ]})]},{"side":_setup.side,"oppositeSide":_setup.oppositeSide})],2):_vm._e()],2)]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
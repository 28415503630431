import qs from 'qs';

// eslint-disable-next-line import/prefer-default-export
export function getUrl(input, queryParams = {}) {
    const url = new URL(input, window.location.origin);

    url.search = qs.stringify(queryParams);

    return url.toString();
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"inline-flex select-none items-center gap-x-2 text-sm font-medium leading-none text-gray-500 group",class:[
        _vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        {
            'opacity-50': _vm.disabled,
        },
        _setup.textTypeClass,
    ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.click.apply(null, arguments)}}},[_c('input',{staticClass:"pointer-events-none m-0 grid h-5 w-5 appearance-none place-content-center rounded-full border bg-transparent bg-white transition-colors before:content-[' '] before:h-2 before:w-2 before:scale-0 before:rounded-full before:bg-white before:transition-transform checked:before:scale-100",class:[
            _setup.typeClass,
            {
                'group-hover:border-gray-400': !_vm.checked && !_vm.disabled
            }
        ],attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.checked}}),_c('span',{staticClass:"block"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
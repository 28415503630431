import { render, staticRenderFns } from "./base-breadcrumb.vue?vue&type=template&id=1f0480ba"
import script from "./base-breadcrumb.vue?vue&type=script&setup=true&lang=js"
export * from "./base-breadcrumb.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
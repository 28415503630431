import Vue from 'vue';
import mitt from 'mitt';
import App from '@/app';
import store from '@/store';
import createRouter from '@/router';
import usePlugins from '@/plugins';
import hideLoader from '@/assets/util/loader';
import registerVersionWorker from '@/workers/register-version-worker';
import authorizationWrapper from '@/components/authorization-wrapper';
import generalError from '@/components/general-error/general-error';
import useModules from '@/use/modules';
import { APP } from '@/assets/util/event-constants';
import '@/components/base-components';
import '@/assets/libs/helpers';
import '@/assets/style/tailwind.css';

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== 'production';

window.eventBus = new Vue();
window.emitter = mitt();

const options = {
    headers:     {
        'X-Requested-With': 'XMLHttpRequest',
    },
};

fetch('/config', options).then(response => response.json()).then(async (config) => {
    const { i18n, pinia } = usePlugins(Vue, { store, config });
    const router = createRouter({ store, i18n, config });

    useModules({ router, store }, await Promise.all([
        import(/* webpackChunkName: "calendah-module" */'@/modules/calendah'),
        import(/* webpackChunkName: "connection-module" */'@/modules/connection'),
        import(/* webpackChunkName: "estimate-module" */'@/modules/estimate'),
        import(/* webpackChunkName: "knowledge-base-module" */'@/modules/knowledge-base'),
        import(/* webpackChunkName: "mapping-module" */'@/modules/mapping'),
        import(/* webpackChunkName: "matching-module" */'@/modules/matching'),
        import(/* webpackChunkName: "monday-module" */'@/modules/monday'),
    ]));

    window.config = config.window;

    await store.dispatch('aclAccess/SET_ACCESSES', config.window.aclAccess);
    await store.dispatch('user/SET_DATA', { data: config.user });

    /* eslint-disable no-new */
    const app = new Vue({
        pinia,
        router,
        store,
        components: {
            authorizationWrapper,
            generalError,
        },
        created() {
            if (process.env.NODE_ENV === 'production') {
                window.emitter.on(APP.UPDATE, (updated) => {
                    if (!updated) return;

                    store.dispatch('SET_VERSION_ACTUAL', false);
                });

                registerVersionWorker();
            }

            this.$socket.on(APP.MAINTENANCE, (message) => {
                const { mode } = JSON.parse(message);

                store.dispatch('SET_MAINTENANCE', mode);
            });

            if (window.config.cobranding) {
                document.getElementById('app-favicon').href = `${process.env.BASE_URL}img/cobranding/${window.config.cobranding}/favicon.svg`;
            }

            hideLoader();
        },
        i18n,
        render: h => h(App),
    }).$mount('#app');

    if (window.Cypress) {
        // only available during E2E tests
        window.app = app;
    }
});

<script>
export default {
    name: 'BasePaginationButton',
};
</script>

<script setup>
import { ref } from 'vue';
import { SIZE, TYPE } from '@/components/base-components/components/pagination';

const props = defineProps({
    type:       {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:       {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    active:     {
        type: Boolean,
    },
    background: {
        type: Boolean,
    },
    disabled:   {
        type: Boolean,
    },
});

const hovered = ref(false);

function onHover() {
    if (props.disabled) return;

    hovered.value = true;
}

function onLeave() {
    if (props.disabled) return;

    hovered.value = false;
}
</script>

<template>
    <button
        class="flex select-none items-center justify-center overflow-hidden rounded p-1 font-semibold"
        :class="[
            {
                'opacity-50 cursor-not-allowed': disabled,
            },
            {
                [SIZE.LARGE]:  'text-base h-10 min-w-[2.5rem]',
                [SIZE.MEDIUM]: 'text-sm h-8 min-w-[2rem]',
                [SIZE.SMALL]:  'text-sm h-7 min-w-[1.75rem]',
            }[size],
            {
                [TYPE.DEFAULT]:       active ? 'text-white bg-brand-big-stone' : 'text-gray-500 bg-gray-500/10 enabled:hover:bg-gray-500/20 enabled:hover:text-gray-700',
                [TYPE.PRIMARY]:       active ? 'text-white bg-primary-600' : 'text-primary-600 bg-primary-600/10 enabled:hover:bg-primary-600/20 enabled:hover:text-primary-800',
                [TYPE.SECONDARY]:     active ? 'text-white bg-secondary-950' : 'text-secondary-950 bg-secondary-950/10 enabled:hover:bg-secondary-950/20 enabled:hover:text-black',
                [TYPE.PRIMARY_OLD]:   active ? 'text-white bg-science-blue-500' : 'text-science-blue-500 bg-science-blue-500/10 enabled:hover:bg-science-blue-500/20 enabled:hover:text-science-blue-700',
                [TYPE.SECONDARY_OLD]: active ? 'text-white bg-science-blue-800' : 'text-science-blue-800 bg-science-blue-800/10 enabled:hover:bg-science-blue-800/20 enabled:hover:text-science-blue-950',
                [TYPE.SUCCESS]:       active ? 'text-white bg-emerald-600' : 'text-emerald-600 bg-emerald-600/10 enabled:hover:bg-emerald-600/20 enabled:hover:text-emerald-800',
                [TYPE.WARNING]:       active ? 'text-white bg-amber-500' : 'text-amber-500 bg-amber-500/10 enabled:hover:bg-amber-500/20 enabled:hover:text-amber-700',
                [TYPE.DANGER]:        active ? 'text-white bg-red-600' : 'text-red-600 bg-red-600/10 enabled:hover:bg-red-600/20 enabled:hover:text-red-800',
                [TYPE.INFO]:          active ? 'text-white bg-gray-500' : 'text-gray-500 bg-gray-500/10 enabled:hover:bg-gray-500/20 enabled:hover:text-gray-700',
            }[type]
        ]"
        :disabled="disabled"
        @mouseover="onHover"
        @mouseleave="onLeave"
        @click="e => $emit('click', e)"
    >
        <slot :hovered="hovered" :disabled="disabled"/>
    </button>
</template>

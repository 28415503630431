<template>
    <div class="flex flex-1 justify-center">
        <the-loader/>
        <app-sidebar/>
        <main class="flex overflow-hidden flex-col flex-1 w-0">
            <app-header/>
            <div class="overflow-auto relative flex-1 focus:outline-none" data-test="content">
                <slot/>
            </div>
            <allow-cookie-banner
                class="ml-0"
                :class="collapsed ? 'md:ml-20' : 'md:ml-64'"
            />
        </main>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AllowCookieBanner from '@/components/allow-cookie-banner';
import AppHeader from '@/components/app/header';
import AppSidebar from '@/components/app/sidebar';
import TheLoader from '@/components/the-loader';

export default {
    name:       'LayoutDefault',
    components: {
        AllowCookieBanner,
        AppHeader,
        AppSidebar,
        TheLoader,
    },
    computed: {
        ...mapState('sidebar', {
            collapsed: 'collapsed',
        }),
    },
};
</script>

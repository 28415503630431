<script>
export default {
    name: 'BackupPricingPlanRequirement',
};
</script>

<script setup>
// eslint-disable-next-line import/extensions
import { useRouter } from 'vue-router/composables';

const emit = defineEmits({
    click: null,
});

const router = useRouter();

function click() {
    if (router.currentRoute.name !== 'backupPricing') {
        try {
            router.push({ name: 'backupPricing', params: { backupId: router.currentRoute.params.backupId } });
        } catch (error) {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        }
    }

    emit('click');
}
</script>

<template>
    <base-tooltip
        placement="top"
        :content="$t('plan feature not allowed')"
    >
        <div
            class="flex cursor-pointer items-center gap-x-2 hover:opacity-75"
            @click="click"
        >
            <slot/>

            <base-icon
                class="text-yellow-500"
                name="lock"
            />
        </div>
    </base-tooltip>
</template>

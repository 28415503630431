var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex items-center gap-x-2 overflow-hidden"},[(_vm.showArrows || !!_vm.$slots.prefix)?_c('div',{staticClass:"flex items-center mb-1.5 gap-x-2"},[_vm._t("prefix"),(_vm.quickNavigationLeft && _vm.showArrows)?_c('base-tabs-select',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(_vm.showArrows)?_c('base-button',{staticClass:"h-7 px-2",attrs:{"link":"","icon":{ name: 'chevron-left' },"disabled":_vm.disabledLeft},on:{"click":_vm.scrollToLeft}}):_vm._e()],2):_vm._e(),_c('div',{key:"baseTabsNavigationWrapper",ref:"baseTabsNavigationWrapper",staticClass:"relative flex flex-1 items-center gap-x-3 overflow-hidden"},[_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.name,ref:"baseTabsNavigationItem",refInFor:true,staticClass:"px-3 py-0.5 text-base font-semibold mb-1.5 text-center whitespace-nowrap rounded-md transition-all duration-75 ease-in-out select-none font-avenir",class:[
                tab.active ? 'text-science-blue-900' : 'text-gray-600',
                {
                    'flex-1': _vm.stretch,
                    'cursor-not-allowed opacity-50': tab.disabled || _vm.disabled,
                    'cursor-pointer hover:text-science-blue-900 hover:bg-gray-100': !tab.active && !tab.disabled && !_vm.disabled
                }
            ],attrs:{"id":tab.name},on:{"click":function($event){return _vm.click(tab)}}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(tab.label || tab.index)+" ")]},{"tab":tab})],2)}),_c('div',{staticClass:"absolute bottom-0 h-0.5 bg-science-blue-900 z-10",style:(_vm.activeIndicatorStyle)})],2),(_vm.showArrows || !!_vm.$slots.suffix)?_c('div',{staticClass:"flex items-center mb-1.5 gap-x-2"},[(_vm.showArrows)?_c('base-button',{staticClass:"h-7 px-2",attrs:{"link":"","icon":{ name: 'chevron-right' },"disabled":_vm.disabledRight},on:{"click":_vm.scrollToRight}}):_vm._e(),(_vm.quickNavigationRight && _vm.showArrows)?_c('base-tabs-select',{attrs:{"tabs":_vm.tabs}}):_vm._e(),_vm._t("suffix")],2):_vm._e(),_c('div',{staticClass:"absolute bottom-0 w-full h-0.5 bg-gray-200"})])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"flex select-none items-center justify-center overflow-hidden rounded p-1 font-semibold",class:[
        {
            'opacity-50 cursor-not-allowed': _vm.disabled,
        },
        {
            [_setup.SIZE.LARGE]:  'text-base h-10 min-w-[2.5rem]',
            [_setup.SIZE.MEDIUM]: 'text-sm h-8 min-w-[2rem]',
            [_setup.SIZE.SMALL]:  'text-sm h-7 min-w-[1.75rem]',
        }[_vm.size],
        {
            [_setup.TYPE.DEFAULT]:       _vm.active ? 'text-white bg-brand-big-stone' : 'text-gray-500 bg-gray-500/10 enabled:hover:bg-gray-500/20 enabled:hover:text-gray-700',
            [_setup.TYPE.PRIMARY]:       _vm.active ? 'text-white bg-primary-600' : 'text-primary-600 bg-primary-600/10 enabled:hover:bg-primary-600/20 enabled:hover:text-primary-800',
            [_setup.TYPE.SECONDARY]:     _vm.active ? 'text-white bg-secondary-950' : 'text-secondary-950 bg-secondary-950/10 enabled:hover:bg-secondary-950/20 enabled:hover:text-black',
            [_setup.TYPE.PRIMARY_OLD]:   _vm.active ? 'text-white bg-science-blue-500' : 'text-science-blue-500 bg-science-blue-500/10 enabled:hover:bg-science-blue-500/20 enabled:hover:text-science-blue-700',
            [_setup.TYPE.SECONDARY_OLD]: _vm.active ? 'text-white bg-science-blue-800' : 'text-science-blue-800 bg-science-blue-800/10 enabled:hover:bg-science-blue-800/20 enabled:hover:text-science-blue-950',
            [_setup.TYPE.SUCCESS]:       _vm.active ? 'text-white bg-emerald-600' : 'text-emerald-600 bg-emerald-600/10 enabled:hover:bg-emerald-600/20 enabled:hover:text-emerald-800',
            [_setup.TYPE.WARNING]:       _vm.active ? 'text-white bg-amber-500' : 'text-amber-500 bg-amber-500/10 enabled:hover:bg-amber-500/20 enabled:hover:text-amber-700',
            [_setup.TYPE.DANGER]:        _vm.active ? 'text-white bg-red-600' : 'text-red-600 bg-red-600/10 enabled:hover:bg-red-600/20 enabled:hover:text-red-800',
            [_setup.TYPE.INFO]:          _vm.active ? 'text-white bg-gray-500' : 'text-gray-500 bg-gray-500/10 enabled:hover:bg-gray-500/20 enabled:hover:text-gray-700',
        }[_vm.type]
    ],attrs:{"disabled":_vm.disabled},on:{"mouseover":_setup.onHover,"mouseleave":_setup.onLeave,"click":e => _vm.$emit('click', e)}},[_vm._t("default",null,{"hovered":_setup.hovered,"disabled":_vm.disabled})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="flex flex-col flex-1 justify-center items-center py-24 px-6 w-full min-h-full">
        <div class="flex flex-col justify-center items-center -mt-20 space-y-10 w-full h-full text-center">
            <base-icon class="text-green-500" name="thumbs-up" prefix="far" size="10x"/>
            <div class="flex flex-col justify-center items-center space-y-5">
                <span class="text-7xl font-semibold">
                    {{ $t('thank you') }}
                </span>
                <span class="text-lg">
                    {{ $t('thank you request message') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AssistedThankYouPage',
};
</script>

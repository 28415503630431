<script>
export default {
    name: 'JsonEditorPanel',
};
</script>

<script setup>
import Vue from 'vue';

defineProps({
    json:     {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits({
    format: null,
    compat: null,
});

function format(value) {
    return JSON.stringify(JSON.parse(value), null, '  ');
}

function compat(value) {
    return JSON.stringify(JSON.parse(value));
}

function copy(value) {
    Vue.prototype.$copyToClipboard(value);
}

defineExpose({
    format,
    compat,
    copy,
});
</script>

<template>
    <div class="inline-flex items-center justify-end">
        <base-button
            size="small"
            label="Format"
            content-class="uppercase tracking-wide text-2xs"
            text
            :disabled="disabled || !json"
            @click="emit('format', format(json))"
        >
            <template #prefix>
                <svg class="h-3 w-3" viewBox="0 0 512 512">
                    <path
                        d="M 0,32 v 64 h 416 v -64 z M 160,160 v 64 h 352 v -64 z M 160,288 v 64 h 288 v -64 z M 0,416 v 64 h 320 v -64 z"
                    />
                </svg>
            </template>
        </base-button>
        <base-button
            size="small"
            label="Compat"
            content-class="uppercase tracking-wide text-2xs"
            text
            :disabled="disabled || !json"
            @click="emit('compat', compat(json))"
        >
            <template #prefix>
                <svg class="h-3 w-3" viewBox="0 0 512 512">
                    <path d="M 0,32 v 64 h 512 v -64 z M 0,160 v 64 h 512 v -64 z M 0,288 v 64 h 352 v -64 z"/>
                </svg>
            </template>
        </base-button>
        <base-button
            size="small"
            label="Copy"
            content-class="uppercase tracking-wide text-2xs"
            text
            :icon="{ name: 'copy', prefix: 'far', class: 'text-sm' }"
            :disabled="disabled || !json"
            @click="copy(json)"
        />
    </div>
</template>

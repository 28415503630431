<template>
    <div
        v-show="nativeType !== 'hidden'"
        class="inline-flex group"
        :class="{
            'cursor-not-allowed': disabled,
        }"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave"
        @click.stop.prevent=""
    >
        <template v-if="isTextarea">
            <div
                class="relative flex flex-1 items-center gap-x-2 self-start overflow-hidden rounded border transition-colors focus:outline-none"
                :class="[
                    typeClass,
                    borderClass,
                    backgroundClass,
                    {
                        'pointer-events-none': disabled,
                    }
                ]"
            >
                <textarea
                    v-model="model"
                    ref="textarea"
                    class="grow border-none bg-transparent outline-none focus:outline-none"
                    :class="[
                        textareaTextClass,
                        placeholderClass,
                        paddingClass,
                        {
                            'pointer-events-none select-none': disabled,
                            'text-center': center,
                        }
                    ]"
                    :style="textareaStyle"
                    :name="name"
                    :placeholder="placeholder"
                    :minlength="minlength"
                    :maxlength="maxlength"
                    :readonly="readonly"
                    :autocomplete="autocomplete"
                    :disabled="disabled"
                    :rows="rows"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    @change="handleChange"
                    @keydown="handleKeydown"
                />

                <span
                    v-if="showLimitation"
                    class="absolute right-2 whitespace-nowrap rounded px-1 text-xs text-opacity-75 bottom-0.5 py-0.5"
                    :class="[
                        typeClass,
                        backgroundClass,
                    ]"
                >
                    {{ length }} / {{ limit }}
                </span>
            </div>
        </template>

        <template v-else>
            <div
                v-if="$slots.prepend"
                :class="[
                    prependClass || ['rounded-l border-r-0', slotClass],
                    heightClass
                ]"
            >
                <slot name="prepend"/>
            </div>
            <div
                class="relative flex flex-1 items-center self-start overflow-hidden border transition-colors focus:outline-none"
                :class="[
                    wrapperClass || [typeClass, borderClass, backgroundClass,],
                    {
                        'rounded-l': !$slots.prepend,
                        'rounded-r': !$slots.append,
                        'pointer-events-none': disabled,
                    }
                ]"
            >
                <span
                    v-if="showPrefix"
                    class="inline-flex items-center gap-x-2"
                    :class="paddingClass"
                >
                    <slot name="prefix"/>
                </span>

                <input
                    v-model="model"
                    class="w-full border-none bg-transparent outline-none focus:outline-none"
                    ref="input"
                    :class="[
                        inputClass || [paddingClass,],
                        textClass,
                        heightClass,
                        placeholderClass,
                        {
                            'pointer-events-none select-none': disabled,
                            'text-center': center,
                            'pl-0': showPrefix,
                            'pr-0': showSuffix,
                        }
                    ]"
                    :type="internalNativeType"
                    :name="name"
                    :placeholder="placeholder"
                    :minlength="minlength"
                    :maxlength="maxlength"
                    :readonly="readonly"
                    :autocomplete="autocomplete"
                    :disabled="disabled"
                    :size="nativeSize"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    @change="handleChange"
                    @keydown="handleKeydown"
                />

                <span
                    v-if="showSuffix"
                    class="inline-flex shrink-0 items-center gap-x-2"
                    :class="paddingClass"
                >
                    <template v-if="$slots.suffix">
                        <slot name="suffix"/>
                    </template>

                    <span
                        v-if="showClear"
                        class="inline-flex cursor-pointer items-center justify-end opacity-0 transition-opacity hover:opacity-100"
                        :class="{
                            'opacity-50': focused || hovering,
                        }"
                        @mousedown.prevent="handleMousedown"
                        @click.prevent="handleClear"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4"
                        >
                          <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                          />
                        </svg>
                    </span>

                    <span
                        v-if="showPasswordVisible"
                        class="inline-flex cursor-pointer items-center justify-end opacity-0 transition-opacity hover:opacity-100"
                        :class="{
                            'opacity-50': !isEmptyModel || focused || hovering,
                        }"
                        @mousedown.prevent="handleMousedown"
                        @click="handlePasswordVisible"
                    >
                        <svg
                            v-if="passwordVisible"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                        <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4"
                        >
                          <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                    </span>

                    <span
                        v-if="showLimitation"
                        class="whitespace-nowrap text-xs text-opacity-75"
                        :class="typeClass"
                    >
                        {{ length }} / {{ limit }}
                    </span>
                </span>
            </div>
            <div
                v-if="$slots.append"
                :class="[
                    appendClass || ['rounded-r border-l-0', slotClass],
                    heightClass
                ]"
            >
                <slot name="append"/>
            </div>
        </template>
    </div>
</template>

<script>
import { SIZE, TYPE } from '@/components/base-components/components/input';

export default {
    name:  'BaseInput',
    props: {
        value:        {
            type:    [String, Number],
            default: '',
        },
        name:         {
            type: String,
        },
        placeholder:  {
            type: String,
        },
        type:         {
            type:      String,
            default:   TYPE.DEFAULT,
            validator: value => Object.values(TYPE).includes(value),
        },
        nativeType:   {
            type:      String,
            default:   'text',
            validator: value => ['text', 'textarea', 'email', 'password', 'hidden'].includes(value),
        },
        size:         {
            type:      String,
            default:   SIZE.MEDIUM,
            validator: value => Object.values(SIZE).includes(value),
        },
        nativeSize:   {
            type:    [String, Number],
            default: 20,
        },
        minlength:    {
            type: [String, Number],
        },
        maxlength:    {
            type: [String, Number],
        },
        center:       {
            type: Boolean,
        },
        disabled:     {
            type: Boolean,
        },
        clearable:    {
            type: Boolean,
        },
        readonly:     {
            type: Boolean,
        },
        autocomplete: {
            type: String,
        },
        showPassword: {
            type: Boolean,
        },
        showLimit:    {
            type: Boolean,
        },
        autofocus:    {
            type: Boolean,
        },
        rows:         {
            type:    Number,
            default: 2,
        },
        resize:       {
            type:      String,
            validator: value => ['none', 'both', 'horizontal', 'vertical'].includes(value),
        },
        inputClass:   {
            type: [String, Array, Object],
        },
        wrapperClass: {
            type: [String, Array, Object],
        },
        prependClass: {
            type: [String, Array, Object],
        },
        appendClass:  {
            type: [String, Array, Object],
        },
    },
    data() {
        return {
            focused:         false,
            hovering:        false,
            passwordVisible: false,

            textareaStyle: undefined,
        };
    },
    mounted() {
        this.calculateTextareaStyle();

        if (this.autofocus) {
            this.focus();
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        isTextarea() {
            return this.nativeType === 'textarea';
        },
        isEmptyModel() {
            return this.model === null
                || this.model === undefined
                || String(this.model) === '';
        },
        textClass() {
            return {
                [SIZE.SMALL]:  '-my-px text-xs leading-7',
                [SIZE.MEDIUM]: '-my-px text-sm leading-8',
                [SIZE.LARGE]:  '-my-px text-sm leading-10',
            }[this.size];
        },
        placeholderClass() {
            return [
                'placeholder-opacity-40',
                {
                    [TYPE.DEFAULT]:       'placeholder-gray-500',
                    [TYPE.PRIMARY]:       'placeholder-primary-600',
                    [TYPE.SECONDARY]:     'placeholder-secondary-600',
                    [TYPE.PRIMARY_OLD]:   'placeholder-science-blue-700',
                    [TYPE.SECONDARY_OLD]: 'placeholder-science-blue-800',
                    [TYPE.SUCCESS]:       'placeholder-emerald-700',
                    [TYPE.WARNING]:       'placeholder-orange-600',
                    [TYPE.DANGER]:        'placeholder-red-700',
                    [TYPE.INFO]:          'placeholder-gray-500',
                }[this.type],
            ];
        },
        paddingClass() {
            return {
                [SIZE.SMALL]:  'px-1.5',
                [SIZE.MEDIUM]: 'px-2',
                [SIZE.LARGE]:  'px-3',
            }[this.size];
        },
        heightClass() {
            return {
                [SIZE.SMALL]:  'h-7',
                [SIZE.MEDIUM]: 'h-8',
                [SIZE.LARGE]:  'h-10',
            }[this.size];
        },
        typeClass() {
            return {
                [TYPE.DEFAULT]:       'text-gray-500',
                [TYPE.PRIMARY]:       'text-primary-600',
                [TYPE.SECONDARY]:     'text-secondary-950',
                [TYPE.PRIMARY_OLD]:   'text-science-blue-700',
                [TYPE.SECONDARY_OLD]: 'text-science-blue-800',
                [TYPE.SUCCESS]:       'text-emerald-700',
                [TYPE.WARNING]:       'text-orange-600',
                [TYPE.DANGER]:        'text-red-700',
                [TYPE.INFO]:          'text-gray-500',
            }[this.internalType];
        },
        borderClass() {
            if (this.focused) {
                return {
                    [TYPE.DEFAULT]:       'border-science-blue-800',
                    [TYPE.PRIMARY]:       'border-primary-600',
                    [TYPE.SECONDARY]:     'border-secondary-950',
                    [TYPE.PRIMARY_OLD]:   'border-science-blue-500',
                    [TYPE.SECONDARY_OLD]: 'border-science-blue-700',
                    [TYPE.SUCCESS]:       'border-emerald-600',
                    [TYPE.WARNING]:       'border-orange-500',
                    [TYPE.DANGER]:        'border-red-600',
                    [TYPE.INFO]:          'border-gray-500',
                }[this.internalType];
            }

            return {
                [TYPE.DEFAULT]:       'border-gray-400/40 hover:border-gray-400/80',
                [TYPE.PRIMARY]:       'border-primary-600/40 hover:border-primary-600/80',
                [TYPE.SECONDARY]:     'border-secondary-950/40 hover:border-secondary-950/80',
                [TYPE.PRIMARY_OLD]:   'border-science-blue-500/40 hover:border-science-blue-500/80',
                [TYPE.SECONDARY_OLD]: 'border-science-blue-700/40 hover:border-science-blue-700/80',
                [TYPE.SUCCESS]:       'border-emerald-600/40 hover:border-emerald-600/80',
                [TYPE.WARNING]:       'border-orange-500/40 hover:border-orange-500/80',
                [TYPE.DANGER]:        'border-red-600/40 hover:border-red-600/80',
                [TYPE.INFO]:          'border-gray-500/40 hover:border-gray-500/80',
            }[this.internalType];
        },
        backgroundClass() {
            if (!this.disabled) return 'bg-white';

            return {
                [TYPE.DEFAULT]:       'bg-gray-500 bg-opacity-5 opacity-75',
                [TYPE.PRIMARY]:       'bg-primary-600 bg-opacity-5 opacity-75',
                [TYPE.SECONDARY]:     'bg-secondary-950 bg-opacity-5 opacity-75',
                [TYPE.PRIMARY_OLD]:   'bg-science-blue-700 bg-opacity-5 opacity-75',
                [TYPE.SECONDARY_OLD]: 'bg-science-blue-800 bg-opacity-5 opacity-75',
                [TYPE.SUCCESS]:       'bg-emerald-700 bg-opacity-5 opacity-75',
                [TYPE.WARNING]:       'bg-orange-600 bg-opacity-5 opacity-75',
                [TYPE.DANGER]:        'bg-red-700 bg-opacity-5 opacity-75',
                [TYPE.INFO]:          'bg-gray-500 bg-opacity-5 opacity-75',
            }[this.internalType];
        },
        slotClass() {
            return [
                'flex items-center border text-opacity-75',
                this.typeClass,
                this.paddingClass,
                {
                    [TYPE.DEFAULT]:       'border-gray-400/40 bg-gray-500/5',
                    [TYPE.PRIMARY]:       'border-primary-600/40 bg-primary-600/5',
                    [TYPE.SECONDARY]:     'border-secondary-950/40 bg-secondary-950/5',
                    [TYPE.PRIMARY_OLD]:   'border-science-blue-500/40 bg-science-blue-700/5',
                    [TYPE.SECONDARY_OLD]: 'border-science-blue-700/40 bg-science-blue-800/5',
                    [TYPE.SUCCESS]:       'border-emerald-600/40 bg-emerald-700/5',
                    [TYPE.WARNING]:       'border-orange-500/40 bg-orange-600/5',
                    [TYPE.DANGER]:        'border-red-600/40 bg-red-700/5',
                    [TYPE.INFO]:          'border-gray-500/40 bg-gray-500/5',
                }[this.internalType],
            ];
        },
        textareaTextClass() {
            return {
                [SIZE.SMALL]:  '-my-px text-xs py-1.5',
                [SIZE.MEDIUM]: '-my-px text-sm py-1.5',
                [SIZE.LARGE]:  '-my-px text-sm py-2.5',
            }[this.size];
        },
        showPrefix() {
            return this.$slots.prefix;
        },
        showSuffix() {
            return this.$slots.suffix
                || this.showClear
                || this.showPasswordVisible
                || this.showLimitation;
        },
        internalNativeType() {
            if (this.nativeType === 'password' && this.showPassword) {
                return this.passwordVisible ? 'text' : 'password';
            }

            return this.nativeType;
        },
        internalType() {
            if (this.minLengthNotValid) return 'danger';

            return this.type;
        },
        minLengthNotValid() {
            return !this.isEmptyModel
                && this.minlength
                && this.length < this.minlength;
        },
        length() {
            if (this.isEmptyModel) return 0;

            return [...this.model].length;
        },
        limit() {
            if (this.minlength && this.length < this.minlength) return this.minlength;
            if (this.maxlength && this.length < this.maxlength) return this.maxlength;

            return this.maxlength || this.minlength;
        },
        showClear() {
            return this.clearable
                && !this.isEmptyModel
                && !this.readonly
                && !this.disabled;
        },
        showPasswordVisible() {
            return this.showPassword
                && !this.isEmptyModel
                && !this.readonly
                && !this.disabled;
        },
        showLimitation() {
            return this.showLimit
                && (!!this.minlength || !!this.maxlength)
                && !this.readonly
                && !this.disabled;
        },
    },
    methods:  {
        getInput() {
            return this.$refs.input || this.$refs.textarea;
        },
        focus() {
            this.getInput().focus();
        },
        select() {
            this.getInput().select();
        },
        blur() {
            this.getInput().blur();
        },
        handleFocus(event) {
            this.focused = true;
            this.$emit('focus', event);
        },
        handleBlur(event) {
            this.focused = false;
            this.$emit('blur', event);
        },
        handleChange(event) {
            this.$emit('change', event.target.value);
        },
        handleKeydown(event) {
            this.$emit('keydown', event);
        },
        handleClear() {
            this.$emit('clear');
            this.$emit('change', '');
            this.model = '';
        },
        handleMouseover(event) {
            this.hovering = true;
            this.$emit('mouseover', event);
        },
        handleMouseleave(event) {
            this.hovering = false;
            this.$emit('mouseleave', event);
        },
        handleMousedown() {
        },
        handlePasswordVisible() {
            this.passwordVisible = !this.passwordVisible;
        },
        calculateTextareaStyle() {
            if (!this.isTextarea) return;

            const style = window.getComputedStyle(this.$refs.textarea);

            const paddingSize = Number.parseFloat(style.getPropertyValue('padding-bottom')) + Number.parseFloat(style.getPropertyValue('padding-top'));
            const lineHeight = Number.parseFloat(style.getPropertyValue('line-height'));

            const minHeight = (lineHeight * this.rows) + paddingSize;

            const formatValue = value => (value ? `${value}px` : undefined);

            this.textareaStyle = {
                minHeight: formatValue(minHeight),
                resize:    this.resize,
            };
        },
    },
};
</script>

import i18n from '@/plugins/i18n';
import { script } from '@/assets/util/append-file';

export default {
    install(Vue, { config }) {
        if (!config.scripts?.google?.clientKey) return;

        script('https://apis.google.com/js/client.js');

        let onSelectCallback;
        let onCloseCallback;
        let onShowCallback;

        /* eslint-disable */
        function fileGetCallback(file) {
            if (!onSelectCallback) return;

            onSelectCallback(file, gapi.auth.getToken());
        }

        function pickerCallback(data) {
            if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
                const request = gapi.client.drive.files.get({
                    fileId: data[google.picker.Response.DOCUMENTS][0][google.picker.Document.ID],
                });

                request.execute(fileGetCallback);
                return;
            }

            if (data[google.picker.Response.ACTION] === google.picker.Action.CANCEL) {
                if (!onCloseCallback) return;

                onCloseCallback();
            }
        }

        function showPicker() {
            if (!gapi.auth.getToken()) return;

            new google.picker.PickerBuilder()
                .addView(google.picker.ViewId.DOCS)
                .setAppId(config.scripts?.google?.clientKey)
                .setOAuthToken(gapi.auth.getToken().access_token)
                .setCallback(pickerCallback)
                .build()
                .setVisible(true);

            if (!onShowCallback) return;

            onShowCallback();
        }

        function signIn() {
            gapi.auth2.getAuthInstance().signIn().then(showPicker).catch(errorHandle)
        }

        function errorHandle(response) {
            if (response.error === 'idpiframe_initialization_failed') {
                Vue.prototype.$notify.error({
                    title:    i18n.t('request error notification'),
                    message:  response.details,
                    duration: 7500,
                });
            }

            return onCloseCallback?.();
        }

        Vue.prototype.$googleFilePicker = {
            open(onSelect, onClose, onShow) {
                onSelectCallback = onSelect;
                onCloseCallback = onClose;
                onShowCallback = onShow;

                gapi.auth2.init({
                    client_id: `${config.scripts.google.clientKey}.apps.googleusercontent.com`,
                    scope:     'https://www.googleapis.com/auth/drive.readonly',
                });

                gapi.client.load('drive', 'v2');
                gapi.load('picker', '1');

                gapi.auth2.getAuthInstance().then(signIn, errorHandle);
            },
        };
        /* eslint-enable */
    },
};

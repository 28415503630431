var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('portal',[_c('base-overlay',{attrs:{"show":_setup.model,"transition":_vm.overlayTransition,"z-index":_setup.zIndex,"custom-class":_vm.overlayClass},on:{"after-enter":function($event){_setup.overlayClosed = false},"enter":function($event){_setup.overlayClosed = null},"after-leave":function($event){_setup.overlayClosed = true},"click":_setup.clickOverlay}}),_c('transition',_vm._b({attrs:{"appear":""},on:{"after-enter":function($event){_setup.modalClosed = false},"enter":function($event){_setup.modalClosed = null},"after-leave":function($event){_setup.modalClosed = true}}},'transition',_vm.transition,false),[(_setup.model)?_c('div',{staticClass:"pointer-events-none fixed inset-0 flex flex-col items-center overflow-hidden",class:[
                {
                    top: 'justify-start',
                    center: 'justify-center',
                }[_vm.verticalAlign],
            ],style:({
                'z-index': _setup.zIndex,
            })},[_c('div',{staticClass:"fixed pointer-events-auto flex flex-col overflow-hidden rounded-xl m-4 bg-white shadow-md max-h-[calc(100vh-2rem)] max-w-[calc(100vw-2rem)]",style:(_setup.modalStyle)},[(_vm.showClose || _vm.$scopedSlots.close)?_c('div',{staticClass:"absolute right-2 top-1.5"},[_vm._t("close",function(){return [_c('div',{staticClass:"cursor-pointer p-1 text-gray-500 opacity-50 transition-opacity duration-300 hover:opacity-100",on:{"click":_setup.close}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})])])]},{"close":_setup.close})],2):_vm._e(),_vm._t("header-content",function(){return [(_vm.title || _vm.$scopedSlots.header)?_c('div',{staticClass:"w-full px-5 pt-5"},[_vm._t("header",function(){return [_c('span',{staticClass:"text-2xl font-black"},[_vm._v(_vm._s(_vm.title))])]},{"close":_setup.close})],2):_vm._e()]},{"close":_setup.close}),_vm._t("content",function(){return [(_vm.$scopedSlots.default)?_c('div',{staticClass:"my-4 overflow-auto px-5"},[_vm._t("default",null,{"close":_setup.close})],2):_vm._e()]},{"close":_setup.close}),_vm._t("footer-content",function(){return [(_vm.$scopedSlots.footer)?_c('div',{staticClass:"w-full px-5 pb-5 text-right"},[_vm._t("footer",null,{"close":_setup.close})],2):_vm._e()]},{"close":_setup.close})],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
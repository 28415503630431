<script>
export default {
    name: 'BasePopover',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import { isValid, PLACEMENTS } from '@/components/base-components/utils/placement';

const props = defineProps({
    value:          {
        type: Boolean,
    },
    content:        {
        type: String,
    },
    placement:      {
        type:      String,
        default:   PLACEMENTS.BOTTOM,
        validator: isValid,
    },
    trigger:        {
        type:      String,
        default:   'hover',
        validator: value => ['hover', 'click', 'manual'].includes(value),
    },
    offset:         {
        type:    Number,
        default: 12,
    },
    disabled:       {
        type: Boolean,
    },
    arrow:          {
        type:    Boolean,
        default: true,
    },
    arrowClass:     {
        type: String,
    },
    noClickOutside: {
        type: Boolean,
    },
    noEnter:        {
        type: Boolean,
    },
    render:         {
        type:      String,
        default:   'default',
        validator: value => ['default', 'lazy', 'always'].includes(value),
    },
    openDelay:      {
        type:    Number,
        default: 0,
    },
    hideDelay:      {
        type:    Number,
        default: 50,
    },
    contentClass:   {
        type: String,
    },
    transition:     {
        type: Object,
        default() {
            return {
                'enter-active-class': 'transition duration-150 ease-in-out scale-100',
                'leave-active-class': 'transition duration-150 ease-in-out scale-100',
                'leave-to-class':     'opacity-0 scale-95',
                'enter-class':        'opacity-0 scale-95',
            };
        },
    },
});

const emit = defineEmits({
    input:  null,
    show:   null,
    hide:   null,
    hidden: null,
});

const model = computed({
    get() {
        return props.value;
    },
    set(value) {
        emit('input', value);
    },
});

const viewClass = computed(() => {
    if (props.contentClass) return props.contentClass;

    return props.content ? 'm-2 max-w-sm' : null;
});

const tooltip = ref(null);

const close = () => tooltip.value?.close();

defineExpose({
    close,
});
</script>

<template>
    <base-tooltip
        v-model="model"
        ref="tooltip"
        type="light"
        tooltip-class="shadow-lg"
        :enterable="!noEnter"
        :placement="placement"
        :trigger="trigger"
        :offset="offset"
        :disabled="disabled"
        :arrow="arrow"
        :arrow-class="arrowClass"
        :open-delay="openDelay"
        :hide-delay="hideDelay"
        :transition="transition"
        :no-click-outside="noClickOutside"
        :render="render"
        @show="emit('show')"
        @hide="emit('hide')"
        @hidden="emit('hidden')"
    >
        <slot name="reference" :close="close"/>

        <template #content>
            <slot name="content" :close="close">
                <base-scroll
                    ref="scrollbar"
                    class="flex max-h-96 flex-col text-sm"
                    wrap-class="flex flex-col"
                    :view-class="viewClass"
                >
                    <slot :close="close">
                        <span class="max-w-sm" v-html="content"/>
                    </slot>
                </base-scroll>
            </slot>
        </template>
    </base-tooltip>
</template>

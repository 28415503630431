<template>
    <div class="flex">
        <div class="inline-block origin-top-left" :class="baseClass" :title="name"></div>
    </div>
</template>

<script>
export default {
    name:  'CrmLogo',
    props: {
        crm:       {
            type:    String,
            default: 'default',
        },
        name:      String,
        size:      {
            type: String,
            validator(value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1;
            },
        },
        showTitle: {
            type:    Boolean,
            default: true,
        },
        autoSize:  Boolean,
    },
    data() {
        return {
            sizes: {
                xs: 'scale-50 -mr-20 -mb-6',
                sm: 'scale-75 -mr-10 -mb-3',
                md: 'scale-90 -mr-4 -mb-1',
                lg: 'scale-95 -mr-2 -mb-0.5',
                xl: 'scale-100',
            },
        };
    },
    computed: {
        calculatedSize() {
            if (this.autoSize && this.size) {
                const order = this.$screen.config.breakpointsOrder;

                if (order.indexOf(this.$screen.breakpoint) >= order.indexOf(this.size)) return this.sizes[this.size];
            }

            if (this.autoSize) return this.sizes[this.$screen.breakpoint];

            return this.sizes[this.size ?? 'xl'];
        },
        baseClass() {
            return [
                'crm_sprite',
                `crm-${this.crm.toLowerCase()}`,
                this.calculatedSize,
            ];
        },
    },
};
</script>

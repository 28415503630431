var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center gap-x-3",class:{
        'opacity-75 cursor-not-allowed': _vm.disabled,
        'cursor-pointer': !_vm.disabled,
    },on:{"click":_setup.click}},[_c('button',{staticClass:"inline-flex relative shrink-0 p-0.5 w-8 h-5 rounded-full transition-colors duration-200 ease-in-out focus:outline-none disabled:cursor-not-allowed",class:_setup.typeClass,attrs:{"disabled":_vm.disabled,"type":"button"}},[_c('span',{staticClass:"pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transition duration-200 ease-in-out",class:[
                _setup.active ? 'translate-x-3' : 'translate-x-0',
                _setup.typeTextClass,
            ]},[_vm._t("inner",function(){return [(_vm.showIcon)?_c('span',{staticClass:"absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",class:_setup.active ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'},[_vm._t("inactive-icon",function(){return [_c('svg',{staticClass:"h-3 w-3",attrs:{"fill":"none","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]})],2):_vm._e(),(_vm.showIcon)?_c('span',{staticClass:"absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out",class:_setup.active ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'},[_vm._t("active-icon",function(){return [_c('svg',{staticClass:"h-3 w-3",attrs:{"fill":"currentColor","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"}})])]})],2):_vm._e()]},{"active":_setup.active})],2)]),_vm._t("label",function(){return [(_vm.label)?_c('span',{staticClass:"select-none font-medium leading-none",class:_setup.typeTextClass,domProps:{"textContent":_vm._s(_setup.actualLabel)}}):_vm._e()]},{"active":_setup.active,"label":_setup.actualLabel,"typeClass":_setup.typeTextClass})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
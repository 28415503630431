var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"flex fixed inset-0 justify-center items-center w-full h-full bg-gray-900 bg-opacity-50",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeGallery.apply(null, arguments)}}},[_c('div',{staticClass:"flex justify-center items-start"},[(_vm.showNavigation)?_c('div',{staticClass:"flex justify-center items-center w-48 opacity-40 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-100",style:({
                height: _vm.containerHeight + 'px'
            }),on:{"click":_vm.navLeft}},[_c('svg',{staticClass:"w-12 h-12 text-gray-200",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})])]):_vm._e(),_c('div',{staticClass:"flex flex-col gap-y-3 justify-center items-center"},[_c('div',{staticClass:"w-full h-full bg-no-repeat bg-cover rounded-md transition-all duration-300 ease-in-out",style:({
                    backgroundImage: 'url(' + _vm.items[_vm.currentImg].url + ')',
                    width: _vm.containerWidth + 'px',
                    height: _vm.containerHeight + 'px'
                })}),_c('div',{staticClass:"px-3 py-2 bg-sky-50 rounded-md",style:({ width: _vm.containerWidth + 'px' })},[_c('span',{staticClass:"mr-1 font-semibold"},[_vm._v(_vm._s(_vm.currentImg + 1)+")")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.items[_vm.currentImg].text)}})])]),(_vm.showNavigation)?_c('div',{staticClass:"flex justify-center items-center w-48 opacity-40 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-100",style:({
                height: _vm.containerHeight + 'px'
            }),on:{"click":_vm.navLeft}},[_c('svg',{staticClass:"w-12 h-12 text-gray-200",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"inline-flex items-center self-center border",class:[
        {
            [_setup.TYPE.DEFAULT]:       'text-gray-600/60 bg-gray-600/5 border-gray-600/10',
            [_setup.TYPE.PRIMARY]:       'text-primary-600/60 bg-primary-600/5 border-primary-600/20',
            [_setup.TYPE.SECONDARY]:     'text-secondary-800/60 bg-secondary-800/5 border-secondary-800/10',
            [_setup.TYPE.PRIMARY_OLD]:   'text-science-blue-500/60 bg-science-blue-500/5 border-science-blue-500/10',
            [_setup.TYPE.SECONDARY_OLD]: 'text-science-blue-800/60 bg-science-blue-800/5 border-science-blue-800/10',
            [_setup.TYPE.SUCCESS]:       'text-emerald-600/60 bg-emerald-600/5 border-emerald-600/10',
            [_setup.TYPE.WARNING]:       'text-orange-500/60 bg-orange-500/5 border-orange-500/10',
            [_setup.TYPE.DANGER]:        'text-red-600/60 bg-red-600/5 border-red-600/10',
            [_setup.TYPE.INFO]:          'text-gray-500/60 bg-gray-500/5 border-gray-500/10',
        }[_vm.type],
        {
            [_setup.SIZE.LARGE]:  'text-base',
            [_setup.SIZE.MEDIUM]: 'text-base',
            [_setup.SIZE.SMALL]:  'text-sm',
            [_setup.SIZE.MINI]:   'text-xs',
        }[_vm.size],
        _vm.round ? 'rounded-full' : 'rounded',
    ]},[_c('span',{class:[
            {
                [_setup.SIZE.LARGE]: 'px-2.5 leading-7',
                [_setup.SIZE.MEDIUM]: 'px-2 leading-6',
                [_setup.SIZE.SMALL]: 'px-1.5 leading-5',
                [_setup.SIZE.MINI]: 'px-1 leading-4',
            }[_vm.size],
        ]},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2),(_vm.closable)?_c('svg',{staticClass:"cursor-pointer transition-colors duration-150 hover:text-white",class:[
            {
                [_setup.TYPE.DEFAULT]:       'text-gray-600/50 hover:bg-gray-600/20',
                [_setup.TYPE.PRIMARY]:       'text-primary-500/50 hover:bg-primary-500/20',
                [_setup.TYPE.SECONDARY]:     'text-secondary-800/50 hover:bg-secondary-800/20',
                [_setup.TYPE.PRIMARY_OLD]:   'text-science-blue-500/50 hover:bg-science-blue-500/20',
                [_setup.TYPE.SECONDARY_OLD]: 'text-science-blue-800/50 hover:bg-science-blue-800/20',
                [_setup.TYPE.SUCCESS]:       'text-emerald-600/50 hover:bg-emerald-600/20',
                [_setup.TYPE.WARNING]:       'text-orange-500/50 hover:bg-orange-500/20',
                [_setup.TYPE.DANGER]:        'text-red-600/50 hover:bg-red-600/20',
                [_setup.TYPE.INFO]:          'text-gray-500/50 hover:bg-gray-500/20',
            }[_vm.type],
            {
                [_setup.SIZE.LARGE]: 'mr-1 h-5 w-5',
                [_setup.SIZE.MEDIUM]: 'mr-0.5 h-5 w-5',
                [_setup.SIZE.SMALL]: 'mr-0.5 h-4 w-4',
                [_setup.SIZE.MINI]: 'mr-px h-3.5 w-3.5',
            }[_vm.size],
            _vm.round ? 'rounded-full' : 'rounded-sm'
        ],attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"},on:{"click":e => _setup.emit('close', e)}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <base-button
        id="login-button"
        size="small"
        text
        stretch
        :label="$t('tmp Login')"
        :data-test="dataTest()"
        @click="go"
    >
        <template v-slot:prefix>
            <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                />
            </svg>
        </template>
    </base-button>
</template>

<script>
export default {
    name:    'NavigationLogin',
    methods: {
        go() {
            this.$emit('navigate');

            this.$router.push({ name: 'userLogin' });
        },
    },
};
</script>

<template>
    <base-button
        id="login-button"
        size="small"
        text
        stretch
        :label="$t('partner request')"
        :data-test="dataTest()"
        :disabled="disabled"
        :icon="{ name: 'handshake', prefix: 'far' }"
        @click="sendPartnerRequest"
    />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name:     'NavigationPartner',
    computed: {
        ...mapGetters({
            isGranted:         'aclAccess/isGranted',
            getPartnerRequest: 'user/getPartnerRequest',
        }),
        disabled() {
            return this.getPartnerRequest === 'sent';
        },
    },
    methods:  {
        ...mapActions({
            showLoader:     'loader/SHOW',
            hideLoader:     'loader/HIDE',
            partnerRequest: 'user/PARTNER_REQUEST',
        }),
        async sendPartnerRequest() {
            this.$emit('navigate');

            this.showLoader();

            try {
                await this.partnerRequest();

                this.$notify.success({
                    title:   this.$t('request success notification'),
                    message: this.$t('partner request message'),
                });
            } catch {
                this.$notify.error(this.$t('tmp An error occurred'));
            } finally {
                this.hideLoader();
            }
        },
    },
};
</script>

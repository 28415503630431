var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",style:({ marginRight: `${_vm.marginRight}px` })},[_vm._t("default"),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],key:"baseBadgeContainer",ref:"baseBadgeContainer",staticClass:"absolute top-0 right-0 z-10 translate-x-1/2 -translate-y-1/2",class:_vm.wrapperClass},[_vm._t("content",function(){return [_c('div',{staticClass:"inline-flex items-center justify-center whitespace-nowrap rounded-full text-xs text-white",class:[
                    {
                        [_vm.TYPE.PRIMARY]: 'bg-primary-600',
                        [_vm.TYPE.SECONDARY]: 'bg-secondary-950',
                        [_vm.TYPE.PRIMARY_OLD]: 'bg-science-blue-500',
                        [_vm.TYPE.SECONDARY_OLD]: 'bg-science-blue-800',
                        [_vm.TYPE.SUCCESS]: 'bg-emerald-600',
                        [_vm.TYPE.WARNING]: 'bg-amber-500',
                        [_vm.TYPE.DANGER]: 'bg-red-500',
                        [_vm.TYPE.INFO]: 'bg-gray-500',
                    }[_vm.type],
                    {
                        'w-2.5 h-2.5': _vm.isDot,
                        'px-1.5 h-4': !_vm.isDot
                    }
                ],domProps:{"textContent":_vm._s(_vm.content)}})]})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
export default {
    name: 'ServicePlanFeatures',
};
</script>

<script setup>
import { computed } from 'vue';
import { FIELD } from '@/components/payments/components/service-plan/components/features/constants';
import { getFeatureComponent, getVisibleFeatures } from '@/components/payments/components/service-plan/components/features/utils';

const props = defineProps({
    features:  {
        type:     Object,
        required: true,
    },
    itemClass: {
        type: String,
    },
    inline:    {
        type: Boolean,
    },
});

const list = computed(() => Object.values(getVisibleFeatures(props.features)).map(feature => ({
    feature,
    component: getFeatureComponent(feature[FIELD.NAME]),
})));
</script>

<template>
    <div>
        <div
            v-for="({ feature, component }) in list"
            :key="feature.name"
        >
            <slot name="item-prefix" :feature="feature"/>

            <component
                :is="component"
                :class="itemClass"
                :feature="feature"
                :inline="inline"
            />
        </div>
    </div>
</template>

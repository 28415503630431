<template>
    <div
        class="flex flex-col"
        :class="[
            isActive ? 'border-gray-200/100' : 'border-gray-200/0',
            {
                'border-t border-b transition-all duration-300 ease-in-out': !collapseDivide
            }
        ]"
    >
        <div
            class="inline-flex items-center p-2 my-1 space-x-2 rounded"
            :class="{
                'hover:bg-gray-50': titleBackground && !disabled,
                'group hover:text-science-blue-900 cursor-pointer': !disabled,
            }"
            @click="click"
        >
            <div class="flex-1 font-medium select-none">
                <slot name="title">
                    <span v-if="title !== undefined">{{ title }}</span>
                </slot>
            </div>
            <div
                class="text-gray-300 rounded-full transition-transform duration-300 ease-in-out group-hover:text-science-blue-900"
                :class="[
                    isActive ? 'rotate-90' : 'rotate-0',
                    disabled ? 'opacity-0' : 'opacity-100'
                ]"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                </svg>
            </div>
        </div>
        <div
            v-if="!disabled"
            class="overflow-hidden"
            ref="content"
            :style="{ height: contentHeight }"
        >
            <div class="p-2 my-1">
                <slot>
                    <span class="flex justify-center items-center text-gray-400">No content</span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import animate from '@/assets/util/animate';

export default {
    name:   'BaseCollapseItem',
    props:  {
        name:            {
            type:     String,
            required: true,
        },
        title:           String,
        disabled:        Boolean,
        titleBackground: Boolean,
    },
    inject: ['collapse'],
    data() {
        return {
            contentHeight: '0px',
        };
    },
    mounted() {
        if (!this.isActive || this.disabled) return;

        this.show();
    },
    computed: {
        isActive() {
            return this.collapse.activeNames.includes(this.name);
        },
        collapseDivide() {
            return this.collapse.divide;
        },
    },
    watch:    {
        isActive(value) {
            if (this.disabled) return;

            if (value) {
                this.show();
            } else {
                this.hide();
            }
        },
    },
    methods:  {
        click() {
            if (this.disabled) return;

            this.collapse.changeActive(this.name);
        },
        show() {
            this.animate(0, this.$refs.content.scrollHeight);
        },
        hide() {
            this.animate(this.$refs.content.scrollHeight, 0);
        },
        animate(start, end) {
            const change = end - start;

            animate(
                (progress) => {
                    const height = parseInt(progress * change + start, 10);

                    this.contentHeight = `${height}px`;
                },
                () => {
                    if (!this.isActive) return;

                    this.contentHeight = 'auto';
                },
                300,
                { x1: 0, y1: 0, x2: 1, y2: 1 },
            );
        },
    },
};
</script>

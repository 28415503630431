<template>
    <portal>
        <div
            class="fixed inset-0 flex items-center justify-center bg-opacity-90 bg-gradient-to-br font-sans backdrop-blur transition-opacity duration-500 from-secondary-950/90 from-35% to-primary-700/80 z-max"
            :class="[show ? 'opacity-100' : 'opacity-0 pointer-events-none']"
        >
            <div
                class="relative m-8 flex w-full max-w-5xl justify-center gap-x-16 overflow-hidden rounded-xl bg-white px-8 py-6 shadow-2xl transition-all md:px-16 md:py-12"
            >
                <svg
                    class="absolute top-0 right-0 h-96 w-96 shrink-0 translate-x-28 -translate-y-28 text-secondary-600/20"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                >
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                </svg>
                <img
                    class="hidden w-64 lg:block"
                    src="@/assets/img/server.svg"
                    alt="Server"
                />
                <div class="z-10 flex flex-1 flex-col justify-center gap-y-6 lg:gap-y-0">
                    <div class="flex flex-1 flex-col justify-center gap-y-6">
                        <span
                            class="break-words bg-gradient-to-br bg-clip-text text-5xl font-black text-transparent from-primary-600 to-secondary-800">Service Unavailable</span>
                        <span class="text-3xl font-semibold text-gray-600">We’ll be back soon!</span>
                        <span class="max-w-lg text-lg text-gray-500">
                            Sorry for the inconvenience but we’re getting a tune up and performing some maintenance at the moment.
                        </span>
                    </div>
                    <span class="self-end text-right text-xs font-light italic text-gray-500">
                        This message will automatically disappear when we’ll be back.
                    </span>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
import { mapState } from 'vuex';
import { DOWN } from '@/assets/util/maintenance';

export default {
    name:     'AppMaintenance',
    computed: {
        ...mapState({
            mode: state => state.maintenance.mode,
        }),
        show() {
            return this.mode === DOWN;
        },
    },
};
</script>

<template>
    <span v-if="info">
        <base-icon
            name="question-circle"
            prefix="far"
            type="info"
            clickable
            @click="click"
        />
        <base-modal
            v-model="show"
            :size="size"
            :close-on-click-overlay="false"
        >
            <template v-if="$slots.header" #header>
                <slot name="header"/>
            </template>

            <slot>
                <span v-html="info"/>
            </slot>
        </base-modal>
    </span>
</template>

<script>
export default {
    name:  'BaseInfo',
    props: {
        info: {
            type: String,
        },
        size: {
            type:    [Number, String],
            default: 'medium',
        },
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        click() {
            this.show = true;
            this.$emit('click');
        },
    },
};
</script>

<style lang="postcss">
.info-dialog {
    &__block {
        @apply pt-5 text-sm font-normal font-avenir first:pt-0;
    }

    &__title {
        @apply mt-2;
    }

    &__list {
        &--item {
            & b {
                @apply mr-1
            }

            & + & {
                @apply mt-2;
            }
        }
    }
}
</style>

import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
    faCalendarAlt,
    faCalendarXmark,
    faCheckCircle,
    faCheckSquare as faCheckSquareRegular,
    faComments,
    faCopy,
    faCreditCard,
    faEdit,
    faFileAlt as faFileAltRegular,
    faFrown,
    faHandshake,
    faMap,
    faQuestionCircle as faQuestionCircleRegular,
    faSquare as faSquareRegular,
    faStar,
    faThumbsUp,
    faTimesCircle,
    faTrashAlt,
    faUserCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAlignLeft,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faAward,
    faBan,
    faBars,
    faBolt,
    faCalculator,
    faCalendarCheck,
    faCalendarDay,
    faCalendarDays,
    faCircleArrowUp,
    faCircleArrowDown,
    faCode,
    faChartBar,
    faChartLine,
    faCheck,
    faCheckCircle as faCheckCircleSolid,
    faCheckSquare,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClipboardList,
    faClone,
    faCloudUploadAlt,
    faCog,
    faCogs,
    faColumns,
    faCommentDots,
    faCompress,
    faCookieBite,
    faCube,
    faDatabase,
    faDna,
    faDolly,
    faDollyFlatbed,
    faDownload,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEquals,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandArrowsAlt,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFileAlt as faFileAltSolid,
    faFileCode,
    faFileCsv,
    faFileDownload,
    faFileExport,
    faFileImport,
    faFileSignature,
    faFileUpload,
    faFilter,
    faFingerprint,
    faFolderOpen,
    faFont,
    faGhost,
    faGraduationCap,
    faHammer,
    faHistory,
    faHome,
    faIcons,
    faInfoCircle,
    faKey,
    faLayerGroup,
    faLifeRing,
    faLink,
    faListAlt,
    faListUl,
    faLock,
    faLockOpen,
    faMagic,
    faMoneyBillAlt,
    faMinus,
    faMinusSquare,
    faNotEqual,
    faNotesMedical,
    faPaintRoller,
    faPalette,
    faPause,
    faPauseCircle,
    faPencilAlt,
    faPercentage,
    faPlay,
    faPlug,
    faPlus,
    faPlusSquare,
    faProjectDiagram,
    faPuzzlePiece,
    faRandom,
    faRecycle,
    faRedoAlt,
    faRepeat,
    faSave,
    faSearch,
    faSearchDollar,
    faSeedling,
    faShareAlt,
    faShieldAlt,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faShieldHalved,
    faSkull,
    faSlidersH,
    faSortAmountUpAlt,
    faSortAmountDown,
    faSortNumericDownAlt,
    faSortNumericUp,
    faSpinner,
    faStar as faStarSolid,
    faStop,
    faStopCircle,
    faStream,
    faSwatchbook,
    faSyncAlt,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion,
    faTachometerAlt,
    faTag,
    faTasks,
    faTimes,
    faTimesCircle as faTimesCircleSolid,
    faToggleOn,
    faTools,
    faTruckLoading,
    faUndoAlt,
    faUnlink,
    faUser,
    faUserAstronaut,
    faUserCircle as faUserCircleSolid,
    faUserGroup,
    faUsers,
    faUserSecret,
    faUsersSlash,
    faUserTie,
    faVial,
    faWallet,
    faWandMagicSparkles,
    faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faAddNote,
    faArrowRight as faArrowRightCustom,
    faBackup,
    faBrush,
    faCancel,
    faFunction,
    faPercentageCircle,
    faPlusCircle,
    faPlusCircleMany,
    faStorageDown,
    faWallClock,
} from './custom';

const brands = [
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
    faYoutube,
];
const regular = [
    faCalendarAlt,
    faCalendarXmark,
    faCheckCircle,
    faCheckSquareRegular,
    faComments,
    faCopy,
    faCreditCard,
    faEdit,
    faFileAltRegular,
    faFrown,
    faHandshake,
    faMap,
    faQuestionCircleRegular,
    faSquareRegular,
    faStar,
    faThumbsUp,
    faTimesCircle,
    faTrashAlt,
    faUserCircle,
];
const solid = [
    faAlignLeft,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faAward,
    faBan,
    faBars,
    faBolt,
    faCalculator,
    faCalendarCheck,
    faCalendarDay,
    faCalendarDays,
    faCircleArrowUp,
    faCircleArrowDown,
    faCode,
    faChartBar,
    faChartLine,
    faCheck,
    faCheckCircleSolid,
    faCheckSquare,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClipboardList,
    faClone,
    faCloudUploadAlt,
    faCog,
    faCogs,
    faColumns,
    faCommentDots,
    faCompress,
    faCookieBite,
    faCube,
    faDatabase,
    faDna,
    faDolly,
    faDollyFlatbed,
    faDownload,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEquals,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandArrowsAlt,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFileAltSolid,
    faFileCode,
    faFileCsv,
    faFileDownload,
    faFileExport,
    faFileImport,
    faFileSignature,
    faFileUpload,
    faFilter,
    faFingerprint,
    faFolderOpen,
    faFont,
    faGhost,
    faGraduationCap,
    faHammer,
    faHistory,
    faHome,
    faIcons,
    faInfoCircle,
    faKey,
    faLayerGroup,
    faLifeRing,
    faLink,
    faListAlt,
    faListUl,
    faLock,
    faLockOpen,
    faMagic,
    faMoneyBillAlt,
    faMinus,
    faMinusSquare,
    faNotEqual,
    faNotesMedical,
    faPaintRoller,
    faPalette,
    faPause,
    faPauseCircle,
    faPencilAlt,
    faPercentage,
    faPlay,
    faPlug,
    faPlus,
    faPlusSquare,
    faProjectDiagram,
    faPuzzlePiece,
    faRandom,
    faRecycle,
    faRepeat,
    faRedoAlt,
    faSave,
    faSearch,
    faSearchDollar,
    faSeedling,
    faShareAlt,
    faShieldAlt,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faShieldHalved,
    faSkull,
    faSlidersH,
    faSortAmountUpAlt,
    faSortAmountDown,
    faSortNumericDownAlt,
    faSortNumericUp,
    faSpinner,
    faStarSolid,
    faStop,
    faStopCircle,
    faStream,
    faSwatchbook,
    faSyncAlt,
    faTachometerAlt,
    faTag,
    faQuestionCircleSolid,
    faQuestion,
    faTasks,
    faTimes,
    faTimesCircleSolid,
    faToggleOn,
    faTools,
    faTruckLoading,
    faUndoAlt,
    faUnlink,
    faUser,
    faUserAstronaut,
    faUserCircleSolid,
    faUserGroup,
    faUsers,
    faUserSecret,
    faUsersSlash,
    faUserTie,
    faVial,
    faWallet,
    faWandMagicSparkles,
    faStopwatch,
];
const custom = [
    faAddNote,
    faArrowRightCustom,
    faBackup,
    faBrush,
    faCancel,
    faFunction,
    faPercentageCircle,
    faPlusCircle,
    faPlusCircleMany,
    faStorageDown,
    faWallClock,
];

library.add(...brands, ...regular, ...solid, ...custom);

Vue.component('font-awesome-icon', FontAwesomeIcon);

<script>
import get from 'lodash/get';

export default {
    name:    'get-data',
    props:   {
        dataPath: {
            type:    String,
            default: 'wizard.stepData',
        },
    },
    methods: {
        getData(subPath, def = undefined) {
            let path = this.dataPath;

            if (subPath) {
                path = `${path}.${subPath}`;
            }

            return get(this.$store.state, path.replace(/\.{2,}/, '.').replace(/^\./, '').replace(/\.$/, ''), def);
        },
    },
};
</script>

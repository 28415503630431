import Vue from 'vue';
import {
    join,
    map,
    replace,
} from 'lodash';
import { Notification } from 'element-ui';
import StackTracey from 'stacktracey';
import { OTHER } from '@/store/modules/acl-access/constants';

let instance;

const showNotification = (vm, notificationTitle, messageCallback) => {
    if (instance) {
        return;
    }

    const hasAccessToError = vm.$store.getters['aclAccess/isGranted'](OTHER.ERROR_NOTIFICATION);

    const title = hasAccessToError ? notificationTitle : vm.$t('general error page title');
    const message = hasAccessToError ? messageCallback() : vm.$t('general error page subtitle', { email: 'support@syncmatters.com' });
    const customClass = hasAccessToError ? 'el-notification-custom' : '';

    instance = Notification.error({
        title,
        message,
        duration:                 0,
        dangerouslyUseHTMLString: true,
        customClass,
        onClose() {
            instance = null;
        },
    });
};

const buildMessage = (message, error) => () => {
    const stack = (new StackTracey(error)).clean.withSources;
    const stackTrace = join(map(stack, (item) => {
        const path = replace(item.fileShort, item.fileName, '');
        const file = `${path}<b>${item.fileName}:${item.line}</b>`;

        return `- <b>${item.calleeShort}</b> (${file})`;
    }), '</br>') || error;

    return stackTrace ? `${message}</br>${stackTrace}` : message;
};

Vue.config.errorHandler = (error, vm) => {
    showNotification(vm, error.name, buildMessage(error.message, error));

    throw error;
};

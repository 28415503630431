<template>
    <div class="flex">
        <textarea
            class="px-3 py-2 w-full bg-white rounded-md border border-gray-300 transition-all duration-300 outline-none hover:border-science-blue-500 focus:border-science-blue-500"
            :rows="rows"
            :name="name"
            v-model="internalValue"
        ></textarea>
        <fieldError
            v-if="showError"
            :errorText="errorMessage"
        ></fieldError>
    </div>

</template>

<script>
import fieldError from '@/components/field-error/field-error';

export default {
    name:       'UiTextarea',
    components: {
        fieldError,
    },
    props:      {
        value:     {
            type:    String,
            default: '',
        },
        name:      {
            type:    String,
            default: '',
        },
        maxLength: {
            type:    Number,
            default: null,
        },
        rows:      {
            type:    Number,
            default: 4,
        },
    },
    data() {
        return {
            internalValue: this.value,
            showError:     false,
            errorMessage:  '',
        };
    },
    watch: {
        internalValue() {
            this.$emit('update:value', this.internalValue);
            this.$emit('updateValue');
            if (this.maxLength !== null && this.internalValue.length > this.maxLength) {
                this.showError = true;
                this.errorMessage = 'too big';
                this.internalValue = this.internalValue.slice(0, this.maxLength);
            } else {
                this.showError = false;
            }
        },
    },
};
</script>

<template>
    <router-link
        class="block group hover:bg-gray-50"
        :disabled="disabled"
        :to="route"
    >
        <div class="grid grid-cols-12 gap-x-2">
            <div
                class="col-span-10 py-4 pl-4 min-w-0 sm:pl-6 sm:col-span-9 md:col-span-10 lg:col-span-9 xl:col-span-10"
                :class="wrapClass"
            >
                <slot/>
            </div>
            <div
                v-if="goMessage"
                class="flex col-span-2 justify-end items-center pr-4 text-gray-400 transition-colors duration-200 ease-in-out sm:space-x-1 sm:pr-6 sm:col-span-3 md:col-span-2 lg:col-span-3 xl:col-span-2 group-hover:text-science-blue-900"
                :title="goMessage"
            >
                <span class="hidden text-sm sm:inline-block md:hidden lg:inline-block">
                    {{ goMessage }}
                </span>
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"></path>
                </svg>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name:  'ProductListItemWrapper',
    props: {
        route:     {
            type:     [String, Object],
            required: true,
        },
        wrapClass: String,
        goMessage: String,
        disabled:  Boolean,
    },
};
</script>

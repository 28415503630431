export default {
    data() {
        return {
            autoFetch:            true,
            loaded:               false,
            loading:              false,
            loaderOptions:        {},
            defaultLoaderOptions: {
                opaque:        true,
                message:       this.$t('fetching data'),
                socketMessage: undefined,
                showMessage:   true,
            },
        };
    },
    async created() {
        if (this.autoFetch) {
            await this.fetchData();
        }
    },
    computed: {
        loader() {
            return {
                show: this.loading,
                ...this.loaderOptions,
            };
        },
    },
    methods:  {
        async fetchData() {
            this.showLoader();
            try {
                const data = await this.$http.get(this.$route.fullPath);

                this.onFetchData(data);
            } catch (error) {
                this.onFailData(error);
            } finally {
                this.hideLoader();
                this.loaded = true;

                this.onFinallyData();
            }
        },
        onFetchData() {
        },
        onFailData() {
            this.$notify.error(this.$t('tmp An error occurred'));
        },
        onFinallyData() {
        },
        showLoader(options = {}) {
            this.loaderOptions = {
                ...this.defaultLoaderOptions,
                ...options,
            };
            this.loading = true;
        },
        hideLoader() {
            this.loaderOptions = {
                ...this.defaultLoaderOptions,
            };
            this.loading = false;
        },
    },
};

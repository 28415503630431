<template>
    <div
        class="flex overflow-hidden relative flex-col rounded-xl"
        :class="{
            'shadow-md': this.shadow && !this.showOverlay,
            'border border-gray-200': !isDone
        }"
    >
        <div v-if="showLower" class="absolute bottom-0 w-full h-1/2 bg-secondary-950"></div>
        <div class="flex relative grow w-full rounded-xl" :class="contentWrapperClass" :data-test="dataTest(type)">
            <div
                v-if="showOverlay"
                class="flex absolute inset-0 z-10 justify-center items-center w-full h-full"
                :class="isDone ? 'bg-emerald-700 opacity-25' : 'bg-gray-600 opacity-25 cursor-not-allowed'"
            >
                <img v-if="isDone" class="select-none" src="@/assets/img/step-complete.png" alt="done"/>
            </div>
            <div class="max-w-full" :class="contentClass">
                <slot></slot>
            </div>
        </div>
        <div v-if="showLower" class="z-0 p-1">
            <slot name="lower-plate"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:     'block-container',
    props:    {
        type:         {
            type:     String,
            required: false,
            default:  'active',
            validator(type) {
                return ['active', 'done', 'disabled'].indexOf(type) !== -1;
            },
        },
        padding:      {
            type:    String,
            default: 'p-4',
        },
        contentClass: {
            type:    String,
            default: 'flex-1',
        },
        shadow:       {
            type:    Boolean,
            default: true,
        },
        background:   {
            type:    Boolean,
            default: true,
        },
    },
    computed: {
        isActive() {
            return this.type === 'active';
        },
        isDone() {
            return this.type === 'done';
        },
        contentWrapperClass() {
            return {
                [this.padding]: true,
                'bg-white':     this.background,
                'shadow-lg':    this.showLower,
            };
        },
        showOverlay() {
            return this.type === 'done' || this.type === 'disabled';
        },
        showLower() {
            return !!this.$slots['lower-plate'] && this.$slots['lower-plate'].length && this.isActive;
        },
    },
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';
import {
    arrow as arrowMiddleware,
    autoUpdate,
    flip,
    hide,
    offset,
    shift as shiftMiddleware,
    useFloating,
} from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { isValid, OPPOSITE_PLACEMENTS, PLACEMENTS } from '@/components/base-components/utils/placement';
import { useReference } from '@/components/base-components/composables/reference';
import { addUnit } from '@/components/base-components/utils/dom';

const props = defineProps({
    value:          {
        type: Boolean,
    },
    content:        {
        type: String,
    },
    placement:      {
        type:      String,
        default:   PLACEMENTS.BOTTOM,
        validator: isValid,
    },
    position:       {
        type:      String,
        default:   'fixed',
        validator: value => ['fixed', 'absolute'].includes(value),
    },
    type:           {
        type:      String,
        default:   'dark',
        validator: value => ['dark', 'light'].includes(value),
    },
    trigger:        {
        type:      String,
        default:   'hover',
        validator: value => ['hover', 'click', 'manual'].includes(value),
    },
    offset:         {
        type:    Number,
        default: 12, // px
    },
    shift:          {
        type:    Number,
        default: 5, // px
    },
    disabled:       {
        type: Boolean,
    },
    arrow:          {
        type:    Boolean,
        default: true,
    },
    arrowOffset:    {
        type:    Number,
        default: 5, // px
    },
    arrowClass:     {
        type: String,
    },
    noClickOutside: {
        type: Boolean,
    },
    openDelay:      {
        type:    Number,
        default: 0, // ms
    },
    hideDelay:      {
        type:    Number,
        default: 100, // ms
    },
    enterable:      {
        type: Boolean,
    },
    render:         {
        type:      String,
        default:   'default',
        validator: value => ['default', 'lazy', 'always'].includes(value),
    },
    tooltipClass:   {
        type: String,
    },
    transition:     {
        type: Object,
        default() {
            return {
                'enter-active-class': 'transition-opacity duration-300 ease-in-out',
                'leave-active-class': 'transition-opacity duration-500 ease-in-out',
                'leave-to-class':     'opacity-0',
                'enter-class':        'opacity-0',
            };
        },
    },
});

const emit = defineEmits({
    input:  null,
    show:   null,
    hide:   null,
    hidden: null,
});

const referenceRef = ref(null);
const tooltipRef = ref(null);
const arrowRef = ref(null);

const {
    x, y, strategy, middlewareData, placement,
} = useFloating(referenceRef, tooltipRef, {
    whileElementsMounted: autoUpdate,
    placement:            props.placement,
    strategy:             props.position,
    middleware:           [
        offset(props.offset),
        flip(),
        hide(),
        shiftMiddleware({ padding: props.shift }),
        arrowMiddleware({ element: arrowRef }),
    ],
});

const referenceHidden = computed(() => !!middlewareData.value.hide?.referenceHidden);

const tooltipX = computed(() => addUnit(x.value ?? 0));
const tooltipY = computed(() => addUnit(y.value ?? 0));

const arrowX = computed(() => addUnit(middlewareData.value.arrow?.x));
const arrowY = computed(() => addUnit(middlewareData.value.arrow?.y));

const side = computed(() => placement.value.split('-')[0]);
const oppositeSide = computed(() => OPPOSITE_PLACEMENTS[side.value]);

const isHover = computed(() => props.trigger === 'hover');
const isClick = computed(() => props.trigger === 'click');
const isManual = computed(() => props.trigger === 'manual');

const isLazy = computed(() => props.render === 'lazy');
const isAlways = computed(() => props.render === 'always');

const localShow = ref(false);
const localRender = ref(isAlways.value);

const show = computed({
    get() {
        if (isManual.value) return props.value ?? false;

        return localShow.value;
    },
    set(newValue) {
        if (isManual.value) return emit('input', newValue);

        if (newValue) {
            localRender.value = true;
        }

        localShow.value = newValue;
    },
});

function changeShow(value) {
    show.value = value;

    emit(value ? 'show' : 'hide');
}

watch(() => props.disabled, () => {
    if (!props.disabled) return;

    changeShow(false);
});

watch(() => props.value, () => {
    if (!props.value) return;

    localRender.value = true;
});

const render = computed(() => {
    if (isAlways.value) return true;

    return localRender.value;
});

const afterTransition = () => {
    emit('hidden');

    if (isLazy.value) return;

    localRender.value = false;
};

const hideDelay = computed(() => {
    if (props.hideDelay !== 100) return props.hideDelay;

    return props.enterable ? 200 : props.hideDelay;
});

let timeoutId = null;

function showTooltip() {
    if (props.disabled || isManual.value) return;

    if (timeoutId !== null) {
        clearTimeout(timeoutId);
    }

    if (props.openDelay === 0) {
        return changeShow(true);
    }

    timeoutId = setTimeout(() => changeShow(true), props.openDelay);
}

function hideTooltip() {
    if (!show || isManual.value) return;

    if (timeoutId !== null) {
        clearTimeout(timeoutId);
    }

    if (hideDelay.value === 0) {
        return changeShow(false);
    }

    timeoutId = setTimeout(() => changeShow(false), hideDelay.value);
}

function tooltipEnter() {
    if (isClick.value) return;

    showTooltip();
}

function tooltipLeave() {
    if (isClick.value) return;

    hideTooltip();
}

function hoverShow() {
    if (!isHover.value) return;

    showTooltip();
}

function hoverHide() {
    if (!isHover.value) return;

    hideTooltip();
}

useReference((reference) => {
    referenceRef.value = reference;
}, {
    click() {
        if (!isClick.value) return;

        show.value ? hideTooltip() : showTooltip();
    },
    mouseenter: hoverShow,
    mouseleave: hoverHide,
    focus:      hoverShow,
    blur:       hoverHide,
    focusin:    hoverShow,
    focusout:   hoverHide,
});

let stopClickOutside;

watch([isClick, isManual, () => props.noClickOutside], () => {
    if ((!isClick.value && !isManual.value) || props.noClickOutside) return stopClickOutside?.();

    stopClickOutside = onClickOutside(tooltipRef, () => changeShow(false), { ignore: [referenceRef] });
}, { immediate: true });

defineExpose({
    close: () => changeShow(false),
});
</script>

<script>
export default {
    name: 'BaseTooltip',
};
</script>

<template>
    <div class="leading-none">
        <slot/>

        <portal>
            <transition
                v-if="render"
                v-bind="transition"
                appear
                @after-leave="afterTransition"
            >
                <div
                    v-show="show"
                    ref="tooltipRef"
                    class="flex w-max items-center rounded-md bg-opacity-100 text-xs font-normal text-opacity-100 z-max"
                    :class="[
                        {
                            'pointer-events-none': !enterable,
                            'hidden': referenceHidden
                        },
                        {
                            dark: 'bg-gray-600 text-white',
                            light: 'bg-white border text-gray-600',
                        }[type],
                        tooltipClass || 'max-w-sm p-2 text-center'
                    ]"
                    :style="{
                        position: strategy,
                        left: tooltipX,
                        top: tooltipY,
                    }"
                    @mouseenter="tooltipEnter"
                    @mouseleave="tooltipLeave"
                >
                    <slot name="content">
                        <span v-html="content"/>
                    </slot>

                    <div
                        v-if="arrow"
                        ref="arrowRef"
                        class="absolute z-[-1]"
                        :style="{
                            left: arrowX,
                            top: arrowY,
                            right: '',
                            bottom: '',
                            [oppositeSide]: addUnit(-arrowOffset),
                        }"
                    >
                        <slot
                            name="arrow"
                            :side="side"
                            :opposite-side="oppositeSide"
                        >
                            <div
                                class="box-border rotate-45 w-2.5 h-2.5"
                                :class="[
                                    arrowClass || {
                                        dark: 'bg-gray-600',
                                        light: 'bg-white border'
                                    }[type],
                                    {
                                        [PLACEMENTS.TOP]: 'border-b-gray-200/0 border-r-gray-200/0',
                                        [PLACEMENTS.BOTTOM]: 'border-t-gray-200/0 border-l-gray-200/0',
                                        [PLACEMENTS.RIGHT]: 'border-b-gray-200/0 border-l-gray-200/0',
                                        [PLACEMENTS.LEFT]: 'border-t-gray-200/0 border-r-gray-200/0',
                                    }[oppositeSide],
                                ]"
                            />
                        </slot>
                    </div>
                </div>
            </transition>
        </portal>
    </div>
</template>

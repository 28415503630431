<script>
/* eslint-disable no-underscore-dangle */

import { Chart } from 'chart.js';

export default {
    name:    'BaseChart',
    props:   {
        type:    {
            type:     String,
            required: true,
        },
        data:    {
            type:     [Object, Array],
            required: true,
        },
        options: {
            type:    Object,
            default: () => {
            },
        },
    },
    watch:   {
        data: {
            deep: true,
            handler(newData, oldData) {
                if (!oldData || !this._chart) {
                    this.destroy();
                    this.render();
                    return;
                }

                const chart = this._chart;

                if (this.checkDataLabels(newData, oldData)) {
                    this.destroy();
                    this.render();
                    return;
                }

                newData.datasets.forEach((dataset, i) => {
                    // Get new and old dataset keys
                    const oldDatasetKeys = Object.keys(oldData.datasets[i]);
                    const newDatasetKeys = Object.keys(dataset);
                    // Get keys that aren't present in the new data
                    const deletionKeys = oldDatasetKeys.filter(key => (key !== '_meta' && newDatasetKeys.indexOf(key) === -1));
                    // Remove outdated key-value pairs
                    deletionKeys.forEach((deletionKey) => {
                        delete chart.data.datasets[i][deletionKey];
                    });
                    // Update attributes individually to avoid re-rendering the entire chart
                    // eslint-disable-next-line no-restricted-syntax
                    for (const attribute in dataset) {
                        if (Object.prototype.hasOwnProperty.call(dataset, attribute)) {
                            chart.data.datasets[i][attribute] = dataset[attribute];
                        }
                    }
                });

                if (newData.labels) chart.data.labels = newData.labels;
                if (newData.xLabels) chart.data.xLabels = newData.xLabels;
                if (newData.yLabels) chart.data.yLabels = newData.yLabels;

                this.update();
            },
        },
        type() {
            if (!this._chart) {
                throw new Error('No chart!');
            }

            this.render();
        },
        options: {
            deep: true,
            handler() {
                if (!this._chart) {
                    throw new Error('No chart!');
                }

                this._chart.options = this.options;

                this.update();
            },
        },
    },
    methods: {
        render() {
            this.destroy();

            if (!this.$refs.canvas) {
                throw new Error('Initialisation error, no canvas available');
            }

            this._chart = new Chart(this.$refs.canvas.getContext('2d'), {
                type:    this.type,
                data:    this.data,
                options: this.options,
            });

            this.$emit('render');
        },
        update() {
            if (!this._chart) {
                throw new Error('No chart!');
            }

            this._chart.update();

            this.$emit('update');
        },
        destroy() {
            if (!this._chart) {
                return;
            }

            this._chart.destroy();
            this._chart = null;

            this.$emit('destroy');
        },
        checkDataLabels(newData, oldData) {
            const newDatasetLabels = newData.datasets.map(dataset => (dataset.label));
            const oldDatasetLabels = oldData.datasets.map(dataset => (dataset.label));

            const oldLabels = JSON.stringify(oldDatasetLabels);
            const newLabels = JSON.stringify(newDatasetLabels);

            return oldLabels !== newLabels || oldData.datasets.length !== newData.datasets.length;
        },
    },
    render(h) {
        return h('div', [
            h('canvas', {
                ref:   'canvas',
                class: 'w-full h-full',
            }),
        ]);
    },
    mounted() {
        this.render();
    },
    beforeDestroy() {
        if (this._chart) {
            this._chart.destroy();
        }
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden w-full bg-gray-100 rounded-full",class:_vm.height,attrs:{"role":"progressbar","aria-valuenow":_vm.percentage,"aria-valuemin":"0","aria-valuemax":"100"}},[_c('div',{staticClass:"flex overflow-hidden justify-end items-center h-full rounded-full duration-700 ease-in-out transition-size",class:{
            running: 'animate-candy-move bg-candy-green',
            completed: 'bg-green-400',
            failed: 'bg-candy-red',
            stopped: 'bg-candy-gray',
            unknown: 'animate-candy-move bg-candy-gray',
        }[_vm.status],style:(_vm.barStyle)},[(_vm.showText)?_c('span',{ref:"text",staticClass:"bg-black bg-opacity-10 flex h-full items-center px-1.5 rounded-full text-white text-xs"},[_vm._v(_vm._s(_vm.percent))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
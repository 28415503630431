<template>
    <transition
        enter-active-class="duration-500 origin-top"
        enter-class="scale-y-0"
    >
        <div v-if="show" class="mb-4">
            <base-alert
                class="w-full"
                :type="messageType"
                icon
            >
                <span v-html="message"/>
            </base-alert>
        </div>
    </transition>
</template>

<script>
export default {
    name:  'PageMessage',
    props: {
        type:         {
            type: String,
            default() {
                return 'info';
            },
            validator(type) {
                return ['info', 'warning', 'danger'].indexOf(type) !== -1;
            },
        },
        errorMessage: {
            type: String,
            default() {
                return this.$t('tmp An error occurred');
            },
        },
        supportTitle: {
            type: String,
            default() {
                return this.$t('tmp Need Help?\nBetter Call Irene!');
            },
        },
        showError:    {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            message:     this.errorMessage,
            title:       this.supportTitle,
            show:        this.showError,
            messageType: 'info',
        };
    },
    mounted() {
        window.eventBus.$on(this.$store.state.eventConstants.showPageMessage, (message, title) => {
            this.message = message.content;
            this.messageType = this.prepareType(message.type);
            if (title) {
                this.title = title;
            }
            if (message) {
                this.show = true;
            }
        });
        window.eventBus.$on(this.$store.state.eventConstants.hidePageMessage, () => {
            this.show = false;
        });
    },
    methods: {
        prepareType(type) {
            if (type === 'error') return 'danger';

            return type;
        },
    },
};
</script>

<template>
    <div
        class="inline-flex items-center gap-x-2"
        :class="{
            'opacity-50': disabled,
        }"
    >
        <div class="inline-flex items-center">
            <div
                v-for="index in max"
                class="px-1"
                :key="index"
                :class="[
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    {
                        'transition-transform hover:scale-110': !disabled,
                    },
                ]"
                @mouseover="mouseover(index)"
                @mouseleave="mouseleave(index)"
                @click="click(index)"
            >
                <base-icon v-bind="getIcon(index)"/>
            </div>
        </div>
        <span v-if="showText && activeText">
            {{ activeText }}
        </span>
    </div>
</template>

<script>
export default {
    name:  'BaseRate',
    props: {
        value:     {
            type:       Number,
            default:    0,
            validation: item => item >= 0,
        },
        max:       {
            type:       Number,
            default:    5,
            validation: item => item >= 1,
        },
        texts:     {
            type:    Array,
            default: () => [
                'Very bad',
                'Bad',
                'Good',
                'Very good',
                'Excellent',
            ],
        },
        showText:  {
            type: Boolean,
        },
        clearable: {
            type: Boolean,
        },
        disabled:  {
            type: Boolean,
        },
    },
    data() {
        return {
            hoverIndex: -1,
        };
    },
    computed: {
        activeIndex() {
            if (this.hoverIndex !== -1) return this.hoverIndex;

            return this.value;
        },
        activeText() {
            if (!this.activeIndex) return undefined;

            return this.texts[this.activeIndex - 1];
        },
    },
    methods:  {
        mouseover(index) {
            if (this.disabled) return;

            this.hoverIndex = index;
        },
        mouseleave() {
            if (this.disabled) return;

            this.hoverIndex = -1;
        },
        click(index) {
            if (this.disabled) return;

            let value = index;

            if (this.clearable && value === this.value) {
                value = 0;
            }

            this.$emit('input', value);
            this.$emit('change', value);
        },
        getIcon(index) {
            if (this.activeIndex === -1 || this.activeIndex < index) {
                return {
                    name:   'star',
                    prefix: 'far',
                    class:  'text-gray-300',
                };
            }

            return {
                name:   'star',
                prefix: 'fas',
                class:  'text-amber-400',
            };
        },
    },
};
</script>

<script>
export default {
    name: 'App',
};
</script>

<script setup>
import AppLayout from '@/layouts/layout';
import AppVersion from '@/components/app/version';
import AppMaintenance from '@/components/app/maintenance';
</script>

<template>
    <div id="app">
        <app-layout class="min-h-0">
            <router-view/>
        </app-layout>
        <knowledge-base/>
        <app-version/>
        <app-maintenance/>
    </div>
</template>

<template>
    <list-wrapper
        :title="$t('home backups title')"
        :title-message="$t('backup list message')"
        :create-button="$t('start new')"
        create-route="backupConnect"
        :connect-button="$t('backup connect new')"
        :connect-title="$t('backup connect new message')"
    >
        <template v-slot:list="{ list }">
            <product-list :list="list">
                <template v-slot:item="{ item }">
                    <backup-list-item :backup="item"/>
                </template>
            </product-list>
        </template>
    </list-wrapper>
</template>

<script>
import ProductList from '@/components/app/product/list';
import BackupListItem from '@/components/backup/components/list-item';
import ListWrapper from '@/pages/components/list-wrapper';

export default {
    name:       'BackupListPage',
    components: {
        BackupListItem,
        ProductList,
        ListWrapper,
    },
};
</script>

import { get } from 'lodash';
import reactive from '@/assets/util/reactive-object';

const getBaseState = () => ({
    stepData:        undefined,
    oldEventsSet:    false,
    stepExplanation: undefined,
});

export default {
    namespaced: true,
    state:      getBaseState(),
    getters:    {
        /**
         * @param state
         *
         * @returns {*}
         */
        getStep(state) {
            return get(state.stepData, 'step');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getUserSteps(state) {
            return get(state.stepData, 'userSteps', {});
        },

        /**
         * @param state
         *
         * @returns {Array}
         */
        getData(state) {
            return state.stepData;
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getCsrf(state) {
            return get(state.stepData, 'csrf');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        isLocked(state) {
            return get(state.stepData, 'isLocked', false);
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        isRunning(state) {
            return get(state.stepData, 'isRunning', false);
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        isStopped(state) {
            return get(state.stepData, 'instanceStatus') === 'terminated'
                && get(state.stepData, 'termination.type') === 'stop';
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        isFailed(state) {
            const terminationType = get(state.stepData, 'termination.type');

            return get(state.stepData, 'instanceStatus') === 'terminated'
                && ['fail', 'zombie'].includes(terminationType);
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getInstanceId(state) {
            return get(state.stepData, 'instance.id');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getInstanceSubType(state) {
            return get(state.stepData, 'instance.subType');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getStepExplanation(state) {
            return state.stepExplanation;
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        showStepExplanation(state) {
            const migrationStatus = get(state.stepData, 'migrationStatus');

            return migrationStatus && ['source', 'target', 'start', 'pre package'].indexOf(migrationStatus) === -1;
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getMigrationId(state) {
            return get(state.stepData, 'migrationId');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getMigrationType(state) {
            return get(state.stepData, 'migrationType');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getMigrationName(state) {
            return get(state.stepData, 'migrationName');
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getPlatform(state) {
            return {
                source: get(state.stepData, 'source.platform'),
                target: get(state.stepData, 'target.platform'),
            };
        },

        /**
         * @param state
         *
         * @returns {*}
         */
        getProduct(state) {
            return get(state.stepData, 'product');
        },
    },
    mutations:  {
        SET_STEP_DATA(state, { entities, ...rest }) {
            const entitiesValue = Array.isArray(entities) ? {} : entities;

            state.stepData = {
                ...rest,
                ...(entities ? { entities: entitiesValue } : {}),
            };
        },
        EXTEND_STEP_DATA(state, payload) {
            state.stepData = { ...state.stepData, ...payload };
        },
        clearStepData(state) {
            state.stepData = undefined;
        },
        setEvents(state) {
            state.oldEventsSet = true;
        },
        SET_STEP_EXPLANATION(state, value) {
            state.stepExplanation = value;
        },
        SET_ENTITY(state, { id, entity }) {
            reactive.set(state.stepData, ['entities', id], entity);
        },
        SET_DIRECTION_ENTITIES(state, { direction, entities }) {
            reactive.set(state.stepData, [direction, 'entities'], entities);
        },
        SET_DIRECTION_ENTITY(state, { id, direction, entity }) {
            reactive.set(state.stepData, [direction, 'entities', id], entity);
        },
        RESET_STATE(state) {
            Object.assign(state, getBaseState());
        },
    },
    actions:    {
        EXTEND_STEP_DATA({ commit }, payload) {
            commit('EXTEND_STEP_DATA', payload);
        },
        SET_ENTITY({ commit }, { id, entity }) {
            commit('SET_ENTITY', { id, entity });
        },
        SET_DIRECTION_ENTITIES({ commit }, { direction, entities }) {
            commit('SET_DIRECTION_ENTITIES', { direction, entities });
        },
        SET_DIRECTION_ENTITY({ commit }, { id, direction, entity }) {
            commit('SET_DIRECTION_ENTITY', { id, direction, entity });
        },
        RESET_STATE({ commit }) {
            commit('RESET_STATE');
        },
    },
};

<script>
export default {
    name: 'BaseButtonGroup',
};
</script>

<script setup>
import { provide, reactive, toRef } from 'vue';
import { baseButtonGroupKey, SIZE, TYPE } from '@/components/base-components/components/button';

const props = defineProps({
    size:  {
        type:      String,
        default:   SIZE.MEDIUM,
        validator: value => Object.values(SIZE).includes(value),
    },
    type:  {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    round: {
        type: Boolean,
    },
});

provide(baseButtonGroupKey, reactive({
    size:  toRef(props, 'size'),
    type:  toRef(props, 'type'),
    round: toRef(props, 'round'),
}));
</script>

<template>
    <div class="flex">
        <slot/>
    </div>
</template>

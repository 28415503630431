<template>
    <a class="flex shrink-0 items-center mr-4" href="/">
        <img
            class="w-8"
            src="@/assets/img/logo/manager.svg"
            alt="MigrateMyCRM"
        />
        <span v-if="environment" class="mt-1 ml-3 text-base font-semibold tracking-wider leading-none text-white uppercase">
            {{ environment }}
        </span>
    </a>
</template>

<script>
export default {
    name:  'ManagerHeaderTitle',
    props: {
        environment: String,
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('font-awesome-icon',{class:[
        {
            'cursor-pointer': _vm.clickable,
        },
        {
            success: 'text-success',
            info: 'text-primary-600',
            warning: 'text-warning',
            error: 'text-error',
            disabled: 'text-gray-400',
        }[_vm.type],
    ],attrs:{"size":_vm.fixedWidth ? null : _vm.size,"icon":[_vm.prefix, _vm.name],"fixed-width":_vm.fixedWidth},on:{"click":_vm.click}})
}
var staticRenderFns = []

export { render, staticRenderFns }
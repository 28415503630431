<template>
    <div
        v-if="show"
        :id="`base-tab-${name}`"
        :class="{ 'hidden': !active }"
        :aria-hidden="!active"
    >
        <slot :active="active"/>
    </div>
</template>

<script>
export default {
    name:   'BaseTab',
    props:  {
        name:     {
            type:     [String, Number],
            required: true,
        },
        label:    {
            type:     [String, Number],
            required: true,
        },
        meta:     Object,
        lazy:     Boolean,
        destroy:  Boolean,
        disabled: Boolean,
    },
    inject: ['baseTabs'],
    data() {
        return {
            index: undefined,

            loaded: false,
        };
    },
    computed: {
        active() {
            return this.baseTabs.isActive(this.name);
        },
        show() {
            if (this.active) return true;

            return this.loaded || !this.lazy;
        },
    },
    watch:    {
        active() {
            if (this.active) {
                this.loaded = true;
            } else if (this.destroy) {
                this.loaded = false;
            }
        },
    },
    created() {
        this.loaded = this.active;
    },
};
</script>

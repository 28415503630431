var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col gap-y-1"},[_c('div',{staticClass:"inline-flex items-center gap-x-2 text-sm font-medium text-gray-500"},[_c('span',[_vm._v(_vm._s(_vm.data.title || _vm.name))]),(_setup.model.length)?_c('span',[_vm._v("("+_vm._s(_setup.model.length)+")")]):_vm._e()]),_c('div',{staticClass:"ml-2 flex flex-col border-l pl-2",class:{
            'border-red-500': _setup.invalid,
        }},[(_setup.bulkEnabled)?_c('base-switcher',{staticClass:"mb-1 self-start",attrs:{"options":_setup.groupTypesSwitcher},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('base-tooltip',{attrs:{"content":option.tooltip,"disabled":!option.tooltip}},[_c('span',{staticClass:"text-center text-sm",domProps:{"textContent":_vm._s(option.label)}})])]}}],null,false,103859733),model:{value:(_setup.type),callback:function ($$v) {_setup.type=$$v},expression:"type"}}):_vm._e(),_c('base-scroll',{ref:"groupWrapper",attrs:{"wrap-class":"max-h-lg"}},[(_setup.type === _setup.groupTypes.ONE && _setup.model && _setup.model.length)?_c('div',{staticClass:"flex flex-col gap-y-1",class:{
                    'gap-y-0 divide-y divide-gray-100': _setup.isChildObject,
                }},_vm._l((_setup.model),function(item,index){return _c('div',{key:index,staticClass:"inline-flex items-center gap-x-2 pr-2",class:{
                        'py-2': _setup.isChildObject,
                    }},[(_setup.isChildObject)?_c('base-json-form',{key:`${_vm.name}-${index}`,attrs:{"schema":_vm.schema,"links":_vm.links,"errors":_vm.errors[index],"disabled":item && item.is_updateable === false,"wrap-class":"inline-flex flex-1 gap-x-2","item-class":"flex-1"},model:{value:(_setup.model[index]),callback:function ($$v) {_vm.$set(_setup.model, index, $$v)},expression:"model[index]"}}):_c(_setup.BaseJsonFormItem,{key:`${_vm.name}-${index}`,staticClass:"flex-1",attrs:{"name":`${_vm.name}-${index}`,"data":_vm.schema,"validations":_setup.getItemValidations(index),"errors":_vm.errors[index],"required":""},model:{value:(_setup.model[index]),callback:function ($$v) {_vm.$set(_setup.model, index, $$v)},expression:"model[index]"}}),_c('base-button',{attrs:{"size":"small","text":"","icon":{ name: 'trash-alt', prefix: 'far' },"disabled":!!item && item.is_updateable === false},on:{"click":function($event){return _setup.removeItem(index)}}})],1)}),0):_vm._e(),(_setup.bulkEnabled && _setup.type === _setup.groupTypes.BULK)?_c('base-input-array-as-string',{staticClass:"w-full",attrs:{"size":"medium","rows":10,"placeholder":_vm.data.title},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}}):_vm._e()],1),_c(_setup.BaseJsonFormItemError,{staticClass:"my-1",attrs:{"validations":_vm.validations}}),(_setup.type === _setup.groupTypes.ONE)?_c('div',{staticClass:"inline-flex items-center",class:{
                'pt-2': _setup.model.length || _setup.invalid,
            }},[_c('base-button',{attrs:{"size":"small","text":"","label":"Add"},on:{"click":function($event){return _setup.add()}}}),_c(_setup.BaseJsonFormGroupBulk,{attrs:{"schema":_vm.schema,"links":_vm.links},on:{"input":function($event){return _setup.scrollDown()}},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
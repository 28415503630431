var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        {
            [_setup.SIZE.LARGE]:  'text-base',
            [_setup.SIZE.MEDIUM]: 'text-sm',
            [_setup.SIZE.SMALL]:  'text-sm',
        }[_vm.size],
    ]},[_vm._v(" Showing "),_c('strong',{class:_setup.typeClass},[_vm._v(_vm._s(_setup.from)+"-"+_vm._s(_setup.to))]),_vm._v(" of "),_c('strong',{class:_setup.typeClass},[_vm._v(_vm._s(_vm.total))]),_vm._v(" "+_vm._s(_setup.displayUnits)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }
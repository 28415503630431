import { useModule } from '@/use/modules';
import useRouter from '@/use/router';

export default ({ store, router }, name, module) => {
    try {
        return useModule({
            router: useRouter,
        })({ store, router }, { name, module });
    } catch (e) {
        console.error(`Use manager for module error: ${name}\n${e.toString()}`);
    }
};

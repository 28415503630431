import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('@/lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            messages[matched[1]] = locales(key);
        }
    });

    return messages;
}

export default new VueI18n({
    locale:         process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages:       loadLocaleMessages(),
    missing:        (locale, key) => {
        const error = new Error(`i18n :: Key '${key}' not found for locale '${locale}'`);

        Vue.prototype.$bugsnag?.notify(error, (event) => {
            event.groupingHash = 'missingTranslation';
        });
    },
});

<template>
    <div class="flex relative px-2 my-3 font-normal text-red-600 break-normal">
        <base-icon name="times-circle" prefix="far" size="lg" type="error"></base-icon>
        <div class="ml-2">{{ errorText }}</div>
    </div>
</template>

<script>
export default {
    name:  'FieldError',
    props: {
        errorText: {
            type: String,
        },
    },
};
</script>

export const TYPES = {
    BOOLEAN: 'boolean',
    OBJECT:  'object',
    NUMBER:  'number',
    ANY:     'any',
    STRING:  'string',
    MAP:     'map',
    SET:     'set',
    DATE:    'date',
};

export const isAny = v => v == null;
export const isSet = v => v instanceof Set;
export const isMap = v => v instanceof Map;
export const isDate = v => v instanceof Date;
export const isBoolean = v => typeof v === 'boolean';
export const isString = v => typeof v === 'string';
export const isObject = v => typeof v === 'object';
export const isNumber = v => !Number.isNaN(v);

export const serializers = {
    any:     {
        is:  isAny,
        get: v => v,
        set: v => String(v),
    },
    set:     {
        is:  isSet,
        get: v => new Set(JSON.parse(v)),
        set: v => JSON.stringify(Array.from(v)),
    },
    map:     {
        is:  isMap,
        get: v => new Map(JSON.parse(v)),
        set: v => JSON.stringify(Array.from((v).entries())),
    },
    date:    {
        is:  isDate,
        get: v => new Date(v),
        set: v => v.toISOString(),
    },
    boolean: {
        is:  isBoolean,
        get: v => v === 'true',
        set: v => String(v),
    },
    string:  {
        is:  isString,
        get: v => v,
        set: v => String(v),
    },
    object:  {
        is:  isObject,
        get: v => JSON.parse(v),
        set: v => JSON.stringify(v),
    },
    number:  {
        is:  isNumber,
        get: v => Number.parseFloat(v),
        set: v => String(v),
    },
};

export const guessSerializer = (value) => {
    if (isAny(value)) return TYPES.ANY;

    if (isSet(value)) return TYPES.SET;
    if (isMap(value)) return TYPES.MAP;
    if (isDate(value)) return TYPES.DATE;

    if (isBoolean(value)) return TYPES.BOOLEAN;
    if (isString(value)) return TYPES.STRING;
    if (isObject(value)) return TYPES.OBJECT;

    if (isNumber(value)) return TYPES.NUMBER;

    return TYPES.ANY;
};

export const getSerializerByValue = value => serializers[guessSerializer(value)];
export const getSerializerByType = type => serializers[type];

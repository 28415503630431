import Vue from 'vue';
import qs from 'qs';
import useWebSockets from '@/composables/useWebSockets';

const channels = {
    'acl-access-create': 'SET_ACCESS',
    'acl-access-update': 'SET_ACCESS',
    'acl-access-remove': 'REMOVE_ACCESS',
};

const {
    on:  onWebSocket,
    off: offWebSocket,
} = useWebSockets();

export default {
    namespaced: true,
    state:      {
        accesses: {},
    },
    getters:    {
        isExists(state) {
            return Object.keys(state.accesses).length;
        },
        isGranted(state) {
            return access => ['all', 'my'].includes(state.accesses[access]);
        },
    },
    mutations:  {
        SET_ACCESSES(state, accesses) {
            Vue.set(state, 'accesses', accesses);

            // TODO backward compatibility with
            //  * public/js/manager/scripts.js:749
            //  * src/assets/util/manager/scripts.js:2094
            Vue.set(window.config, 'aclAccess', accesses);
        },
        SET_ACCESS(state, data) {
            Vue.set(state.accesses, data.access, data.value);

            // TODO backward compatibility with
            //  * public/js/manager/scripts.js:749
            //  * src/assets/util/manager/scripts.js:2094
            Vue.set(window.config.aclAccess, data.access, data.value);
        },
        REMOVE_ACCESS(state, data) {
            Vue.delete(state.accesses, data.access);

            // TODO backward compatibility with
            //  * public/js/manager/scripts.js:749
            //  * src/assets/util/manager/scripts.js:2094
            Vue.delete(window.config.aclAccess, data.access);
        },
    },
    actions:    {
        async GET_ACCESSES({ commit, dispatch }, role) {
            commit('SET_ACCESSES', await dispatch(
                'REQUEST',
                {
                    action: 'acl/available-access-list',
                    data:   { role },
                },
            ));
        },
        SET_ACCESSES({ commit }, data) {
            commit('SET_ACCESSES', data);
        },
        SUBSCRIBE_ROLE_ACCESS({ commit }, role) {
            Object.entries(channels).forEach(([channel, mutation]) => {
                onWebSocket(`${channel}-${role}`, (message) => {
                    const data = JSON.parse(message);

                    commit(mutation, data);
                });
            });
        },
        UNSUBSCRIBE_ROLE_ACCESS(context, role) {
            Object.keys(channels).forEach(channel => offWebSocket(`${channel}-${role}`));
        },
        REQUEST(context, { action, data }) {
            return Vue.prototype.$http.post(
                `${window.location.origin}/${action}`,
                qs.stringify(data),
            );
        },
    },
};

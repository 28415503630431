<script>
export default {
    name: 'BaseBreadcrumbItem',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    label:     {
        type: [String, Number, Function],
    },
    component: {
        type: [Object, Function],
    },
    attrs:     {
        type: Object,
    },
});

const displayLabel = computed(() => {
    if (typeof props.label === 'function') return props.label();

    return props.label;
});
</script>

<template>
    <div v-if="displayLabel || component" class="base-breadcrumb-item" :class="{ 'overflow-hidden': !component }">
        <component v-if="component" :is="component" v-bind="attrs"/>
        <span v-else class="truncate">
            {{ displayLabel }}
        </span>
    </div>
</template>

<style>
.base-breadcrumb-item {
    @apply flex items-center;
}
</style>

// eslint-disable-next-line import/prefer-default-export
export const TYPE = Object.freeze({
    PRIMARY:       'primary',
    SECONDARY:     'secondary',
    PRIMARY_OLD:   'primary-old',
    SECONDARY_OLD: 'secondary-old',
    SUCCESS:       'success',
    WARNING:       'warning',
    DANGER:        'danger',
    INFO:          'info',
});

import { ref, watch } from 'vue';
import { toValue, tryOnScopeDispose } from '@vueuse/core';

export default function useBroadcastChannel(name, handler = null) {
    const data = ref();
    const channel = ref();

    function post(message) {
        if (!channel) return;

        channel.value.postMessage(message);
    }

    function close() {
        if (!channel) return;

        channel.value.close();
    }

    watch(() => toValue(name), (to, from) => {
        if (to === from) return;

        if (from) {
            close();
        }

        if (!to) return;

        channel.value = new BroadcastChannel(name);
        channel.value.onmessage = (event) => {
            data.value = event.data;
            handler?.(event.data);
        };
    }, { immediate: true });

    tryOnScopeDispose(() => {
        close();
    });

    return {
        data,
        post,
        close,
    };
}

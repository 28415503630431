import Migration from '@/pages/components/migration';
import MigrationList from '@/pages/components/migration/list';
import { MIGRATION, MIGRATION_CUSTOM_MIGRATION_QUOTE, MIGRATION_WIZARD } from '@/store/modules/acl-access/constants';

const stepTitles = {
    demo:               i18n => i18n.t('page title migration step demo'),
    'demo-completed':   i18n => i18n.t('page title migration step demo-completed'),
    start:              i18n => i18n.t('page title migration step start'),
    entities:           i18n => i18n.t('page title migration step entities'),
    full:               i18n => i18n.t('page title migration step full'),
    'full-completed':   i18n => i18n.t('page title migration step full-completed'),
    mapping:            i18n => i18n.t('page title migration step mapping'),
    matching:           i18n => i18n.t('page title migration step matching'),
    package:            i18n => i18n.t('page title migration step package'),
    'pre-package':      i18n => i18n.t('page title migration step pre-package'),
    source:             i18n => i18n.t('page title migration step source'),
    target:             i18n => i18n.t('page title migration step target'),
};

function migrationWizardTitle(route, { i18n }) {
    const step = ((route.hash ?? '').match(/step=(.+)/) ?? [])[1] ?? '';

    return stepTitles[step]?.(i18n) ?? i18n.t('page title migration');
}

export default [
    {
        name:      'migration',
        path:      '/migration/',
        component: Migration,
        redirect:  { name: 'migrationList' },
        meta:      {
            section: 'migration',
        },
        children:  [
            {
                name:      'migrationList',
                path:      'list/',
                component: MigrationList,
                meta:      {
                    acl:         MIGRATION.LIST,
                    title:       (route, { i18n }) => i18n.t('page title migration'),
                    breadcrumbs: (route, { i18n }) => ([
                        {
                            label: i18n.t('tmp migration'),
                        },
                        {
                            label: i18n.t('header breadcrumb list'),
                        },
                    ]),
                },
            },
            {
                name:      'migrationCustomMigrationQuote',
                path:      'custom-migration-quote/',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/custom-migration-quote'),
                meta:      {
                    acl:         MIGRATION_CUSTOM_MIGRATION_QUOTE.FORM,
                    title:       (route, { i18n }) => i18n.t('page title custom migration'),
                    breadcrumbs: (route, { i18n }) => ([
                        {
                            component: () => import('@/components/app/header/breadcrumbs/link'),
                            attrs:     {
                                label: i18n.t('tmp migration'),
                                to:    {
                                    name: 'migrationList',
                                },
                                icon:  {
                                    name:   'file-import',
                                    prefix: 'fas',
                                },
                            },
                        },
                        {
                            label: i18n.t('custom migration quote title'),
                        },
                    ]),
                },
            },
            {
                name:      'migrationWizard',
                path:      'wizard/:hash?',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/wizard'),
                meta:      {
                    acl:         MIGRATION_WIZARD.PROCESS,
                    title:       migrationWizardTitle,
                    breadcrumbs: (route, { i18n, store }) => ([
                        {
                            component: () => import('@/components/app/header/breadcrumbs/link'),
                            attrs:     {
                                label: i18n.t('tmp migration'),
                                to:    {
                                    name: 'migrationList',
                                },
                                icon:  {
                                    name:   'file-import',
                                    prefix: 'fas',
                                },
                            },
                        },
                        {
                            label: () => store.getters['wizard/getMigrationId'],
                        },
                        {
                            label: i18n.t('header breadcrumb wizard'),
                        },
                    ]),
                },
            },
            {
                name:      'migrationWebAccess',
                path:      'web-access/:hash?',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/web-access'),
                meta:      {
                    acl:   MIGRATION.WEB_ACCESS,
                    title: (route, { i18n }) => i18n.t('page title migration web access'),
                },
            },
            {
                name:      'migrationDetails',
                path:      ':migrationId/',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/details'),
                meta:      {
                    acl:   MIGRATION.DETAILS,
                    title: (route, { i18n }) => i18n.t('page title migration details'),
                },
            },
            {
                name:      'migrationEdit',
                path:      ':migrationId/edit',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/edit'),
                meta:      {
                    acl:   MIGRATION.EDIT,
                    title: (route, { i18n }) => i18n.t('page title migration edit'),
                },
            },
            {
                name:      'migrationMapping',
                path:      'mapping/:hash',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/mapping'),
                meta:      {
                    acl:    MIGRATION.MAPPING,
                    title:  (route, { i18n }) => i18n.t('page title migration mapping'),
                    layout: 'content',
                },
            },
            {
                name:      'migrationMatching',
                path:      'matching/:hash',
                component: () => import(/* webpackChunkName: "migration" */'@/pages/components/migration/matching'),
                meta:      {
                    acl:    MIGRATION.MATCHING,
                    title:  (route, { i18n }) => i18n.t('page title migration matching'),
                    layout: 'content',
                },
            },
            {
                path:     '*',
                redirect: { name: 'migrationList' },
            },
        ],
    },
];

<template>
    <div v-loader.full="loaderOptions"/>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name:     'the-loader',
    computed: {
        ...mapState('loader', [
            'show',
            'showMessage',
            'message',
            'socketMessage',
            'dot',
            'onShow',
            'onHide',
        ]),
        loaderOptions() {
            return {
                show:          this.show,
                message:       this.message,
                showMessage:   this.showMessage,
                socketMessage: this.socketMessage,
                dot:           this.dot,
                onShow:        this.onShow,
                onHide:        this.onHide,
            };
        },
    },
};
</script>

<script>
export default {
    name: 'BasePaginationPager',
};
</script>

<script setup>
import { computed } from 'vue';
import BasePaginationButton from '@/components/base-components/components/pagination/components/button';
import { SIZE, TYPE } from '@/components/base-components/components/pagination';

const props = defineProps({
    value:      {
        type:       Number,
        validation: v => v > 0,
    },
    count:      {
        type:     Number,
        required: true,
    },
    pageSize:   {
        type:     Number,
        required: true,
    },
    pagerCount: {
        type:       Number,
        default:    7,
        validation: v => v >= 7 && v <= 21 && v % 2 === 1,
    },
    type:       {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:       {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    disabled:   {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
});

const pagesCount = computed(() => {
    if (props.count > 0) return Math.ceil(props.count / props.pageSize);

    return 1;
});
const pages = computed(() => Array.from({ length: pagesCount.value }).map((_, index) => ({
    page: index + 1,
})));

function getSlices(leftLength, rightLength) {
    let leftSlice = 0;
    let rightSlice = 0;

    const leftDisplay = props.pagerCount - 3;

    if (leftLength >= leftDisplay && rightLength >= leftDisplay) {
        leftSlice = (leftDisplay / 2) - 1;
        rightSlice = (leftDisplay / 2) - 1;
    } else if (leftLength >= props.pagerCount) {
        leftSlice = leftDisplay - rightLength;
    } else {
        rightSlice = leftDisplay - leftLength;
    }

    return {
        leftSlice,
        rightSlice,
        leftDisplay,
    };
}

const ELLIPSIS_LEFT = 'left';
const ELLIPSIS_RIGHT = 'right';

const list = computed(() => {
    if (pages.value.length <= props.pagerCount) return pages.value;

    let left = pages.value.slice(0, props.value);
    const current = left.pop();
    let right = pages.value.slice(props.value);

    const { leftSlice, rightSlice, leftDisplay } = getSlices(left.length, right.length);

    if (left.length) {
        const first = left.shift();

        if (leftSlice > 0) {
            const slice = left.slice(-leftSlice);
            const page = slice[0].page - first.page > leftDisplay ? props.value - leftDisplay : first.page + 1;

            left = [{
                page,
                ellipsis: ELLIPSIS_LEFT,
            }, ...slice];
        }

        left = [first, ...left];
    }

    if (right.length) {
        const last = right.pop();

        if (rightSlice > 0) {
            const slice = right.slice(0, rightSlice);
            const page = last.page - slice[slice.length - 1].page > leftDisplay ? props.value + leftDisplay : last.page - 1;

            right = [...slice, {
                page,
                ellipsis: ELLIPSIS_RIGHT,
            }];
        }

        right = [...right, last];
    }

    return [...left, current, ...right];
});

function getEllipsisIcon(direction, hover = false) {
    if (!hover) return { name: 'ellipsis-h' };

    return {
        [ELLIPSIS_LEFT]:  {
            name: 'angle-double-left',
        },
        [ELLIPSIS_RIGHT]: {
            name: 'angle-double-right',
        },
    }[direction];
}

function goTo(page) {
    if (page < 1 || page > pagesCount.value) return;

    emit('input', page);
}

const prevDisabled = computed(() => props.disabled || !props.value || props.value <= 1);

function onPrev() {
    goTo(props.value - 1);
}

const nextDisabled = computed(() => props.disabled || !props.value || props.value >= pages.value.length);

function onNext() {
    goTo(props.value + 1);
}

defineExpose({
    goTo,
});
</script>

<template>
    <div class="inline-flex items-center gap-2 flex-wrap">
        <base-pagination-button
            :size="size"
            :type="type"
            :disabled="prevDisabled"
            @click="onPrev"
        >
            <base-icon name="chevron-left" size="sm"/>
        </base-pagination-button>

        <base-pagination-button
            v-for="item in list"
            :key="item.ellipsis ? `ell-${item.page}` : item.page"
            :size="size"
            :type="type"
            :disabled="disabled"
            :active="value === item.page"
            @click="goTo(item.page)"
        >
            <template #default="{ hovered }">
                <base-icon
                    v-if="item.ellipsis"
                    v-bind="getEllipsisIcon(item.ellipsis, hovered)"
                    size="sm"
                />
                <template v-else>
                    {{ item.page }}
                </template>
            </template>
        </base-pagination-button>

        <base-pagination-button
            :size="size"
            :type="type"
            :disabled="nextDisabled"
            @click="onNext"
        >
            <base-icon name="chevron-right" size="sm"/>
        </base-pagination-button>
    </div>
</template>

<template>
    <block-container>
        <div class="flex flex-col gap-y-8">
            <h1>{{($t('general error page title'))}}</h1>
            <p style="text-align: center" v-html="($t('general error page subtitle', { email:'support@syncmatters.com' }))"></p>
        </div>
    </block-container>
</template>

<script>
import blockContainer from '@/components/block-container';

export default {
    name:       'GeneralError',
    components: {
        blockContainer,
    },
};
</script>

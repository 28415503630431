export const DIRECTION = {
    SOURCE: 'source',
    TARGET: 'target',
};

export const MAPPING = {
    CUSTOM_RULES:      'c',
    GENERATED_ENUMS:   'g',
    FIELDS_USAGES:     'u',
    NOTES:             'n',
    MAPPING:           'm',
    SOURCE:            's',
    TARGET:            't',
    REQUIRED_ENTITIES: 'r',
    OVERRIDDEN:        'o',
    FIELDS_MAX_ITEMS:  'fm',
    COMPLEX_LIMITS:    'cl',
    FIELDS_FILTERS:    'fs',
    ENTITIES_MAPPING:  'em',
    RECORD_TYPE:       'rt',
    VERSION:           '_ver',
};

export const RULE = {
    MAPPING:  'm',
    SOURCE:   's',
    TARGET:   't',
    IGNORE:   'i',
    OVERTURN: 'o',
};

export const BLOCK = {
    CHECKED:   'c',
    DEFAULT:   'd',
    FUNCTION:  'f',
    SEPARATOR: 's',
    VALUE:     'v',
    HASH:      'h',
    FILTERS:   'fs',
    OPTIONS:   'o',
    FORMAT:    'fr',
    PARENT:    'p',
};

export const FUNCTION = {
    BLOCK:      'b',
    CONSTANT:   'cs',
    COMBINE:    'cb',
    DEPENDENCY: 'd',
    FIELD:      'f',
    JOIN:       'j',
    MULTIPLY:   'm',
    TECHNICAL:  't',
    VALUE:      'v',
};

export const FIELD = {
    ID:               'id',
    ACCESS:           'a',
    COMBINATIONS:     'cbs',
    COMPLEX:          'c',
    CREATED_FIELD:    'cf',
    DEFAULT:          'd',
    ENTITY:           'e',
    ENUM:             'en',
    EXTERNAL_TYPE:    'et',
    EXTRA:            'ex',
    FAKE:             'fk',
    GENERATED:        'g',
    HTML_PRETTY:      'hp',
    IS_ID:            'i',
    KEY:              'k',
    KEY_FIELD:        'kf',
    LABEL:            'l',
    MAX_FORMAT:       'mxf',
    MAX_ITEMS:        'mi',
    MAX_LENGTH:       'mxl',
    MAX_PARENT_ITEMS: 'mpi',
    MAX_PROPERTIES:   'mxp',
    MAX_VALUE:        'mx',
    MIN_FORMAT:       'mnf',
    MIN_LENGTH:       'mnl',
    MIN_PROPERTIES:   'mnp',
    MIN_VALUE:        'mn',
    MULTI:            'm',
    NATIVE:           'n',
    ONE_OF:           'oo',
    PATH:             'p',
    POINTERS:         'ps',
    PRECISION:        'pcs',
    RECORD_TYPES:     'rts',
    REQUIRED:         'rq',
    RESTRICTED:       'rst',
    ROLE:             'r',
    STRING_WRAPPER:   'sw',
    SUBTYPE:          'st',
    TYPE:             't',
    UNIQUE:           'uq',
    UNIQUE_WITH:      'uw',
    UPDATABLE:        'u',
    VALIDATORS:       'v',
    VISIBLE:          'vsb',
    WEIGHT:           'w',
};

export const TYPES = {
    STRING:   's',
    INTEGER:  'i',
    DECIMAL:  'dec',
    FLOAT:    'f',
    DATETIME: 'dt',
    DATE:     'd',
    TIME:     't',
    BOOLEAN:  'b',
};

export const TYPES_LIST = {
    [TYPES.STRING]:   'string',
    [TYPES.INTEGER]:  'integer',
    [TYPES.DECIMAL]:  'decimal',
    [TYPES.FLOAT]:    'float',
    [TYPES.DATETIME]: 'datetime',
    [TYPES.DATE]:     'date',
    [TYPES.TIME]:     'time',
    [TYPES.BOOLEAN]:  'boolean',
};

export const TYPES_DEFAULT = {
    [TYPES.STRING]:   '_',
    [TYPES.INTEGER]:  0,
    [TYPES.DECIMAL]:  0,
    [TYPES.FLOAT]:    0,
    [TYPES.DATETIME]: '2023-02-10T17:14:56+0000',
    [TYPES.DATE]:     '2023-02-10',
    [TYPES.TIME]:     '17:14:56',
    [TYPES.BOOLEAN]:  true,
};

export const ROLE = {
    UNIFIED:     'u',
    NOT_UNIFIED: 'nu',
    CUSTOM:      'c',
};

export const COMBINATION = {
    REQUIRED: 'rq',
    UNIQUE:   'uq',
    UNITE:    'ut',
};

export const DEPENDENCY = {
    CONDITION: 'c',
    OPTIONS:   'o',
    TYPES:     't',
};

export const DEPENDENCY_CONDITION = {
    EMPTY: 'em',
    EQUAL: 'eq',
};

export const OTHER = {
    NULL_POINTER:       1000,
    DEFAULT_SEPARATOR:  '; ',
    DELIMITER:          ' || ',
    MULTIPLY_DELIMITER: ' || ',
};

export const USAGE = {
    VALUE:    'v',
    ITEMS:    'i',
    POINTERS: 'ps',
    RECORDS:  'r',
    COUNT:    'c',
};

export const USAGE_LABELS = {
    __true__:  'True',
    __false__: 'False',
};

export const CONSTANT_FORMAT = {
    STRING:   TYPES.STRING,
    INTEGER:  TYPES.INTEGER,
    DECIMAL:  TYPES.DECIMAL,
    FLOAT:    TYPES.FLOAT,
    DATETIME: TYPES.DATETIME,
    DATE:     TYPES.DATE,
    TIME:     TYPES.TIME,
    BOOLEAN:  TYPES.BOOLEAN,
    ENUM:     'en',
};

export const CONSTANT_FORMATS = {
    string:   CONSTANT_FORMAT.STRING,
    integer:  CONSTANT_FORMAT.INTEGER,
    decimal:  CONSTANT_FORMAT.DECIMAL,
    float:    CONSTANT_FORMAT.FLOAT,
    datetime: CONSTANT_FORMAT.DATETIME,
    date:     CONSTANT_FORMAT.DATE,
    time:     CONSTANT_FORMAT.TIME,
    boolean:  CONSTANT_FORMAT.BOOLEAN,
    enum:     CONSTANT_FORMAT.ENUM,
};

export const FILTER = {
    FUNCTION: 'f',
    OPTIONS:  'o',
    TYPES:    't',
};

export const VISIBILITY = {
    VISIBLE:   true,
    INVISIBLE: false,
    UNKNOWN:   null,
};

export const ENUM_WEIGHT = {
    MIN: 0,
    MAX: 100,
};

export const ENUM_WEIGHT_THRESHOLDS = [
    ENUM_WEIGHT.MIN,
    ENUM_WEIGHT.MAX,
];

export const COMPLEX_OPTION = {
    UNTIL_FOUND:          'until_found',
    USE_PROCESSED:        'use_processed',
    USE_POINTERS_IN_JOIN: 'use_pointers_in_join',
};

export const FIELD_OPTION_NOT_IN_LIST = 'nil';

export const FIELD_OPTIONS = {
    not_in_list: FIELD_OPTION_NOT_IN_LIST,
};

export const MAPPING_STRATEGY = {
    NATIVE:  'native',
    OPEN_AI: 'openai',
};

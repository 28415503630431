export default [
    {
        name:      'payment',
        path:      '/payment/',
        component: () => import(/* webpackChunkName: "payment" */'@/pages/components/payment'),
        redirect:  { name: 'root' },
        children:  [
            {
                name:      'paymentResult',
                path:      ':paymentHash/result/',
                component: () => import(/* webpackChunkName: "payment" */'@/pages/components/payment/result'),
                meta:      {
                    title:  (route, { i18n }) => i18n.t('payment.page.result.title'),
                    layout: 'content',
                },
            },
        ],
    },
];

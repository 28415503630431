<template>
    <component :is="layout">
        <slot/>
    </component>
</template>

<script>
import BlankLayout from '@/layouts/blank';
import ContentLayout from '@/layouts/content';
import ExternalLayout from '@/layouts/external';
import ManagerLayout from '@/layouts/manager';
import SidebarLayout from '@/layouts/sidebar';

export default {
    name:     'AppLayout',
    computed: {
        layout() {
            return {
                blank:    BlankLayout,
                content:  ContentLayout,
                external: ExternalLayout,
                manager:  ManagerLayout,
                sidebar:  SidebarLayout,
            }[this.$route.meta.layout || 'blank'];
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-flex items-center gap-x-2",class:{
        'opacity-50': _vm.disabled,
    }},[_c('div',{staticClass:"inline-flex items-center"},_vm._l((_vm.max),function(index){return _c('div',{key:index,staticClass:"px-1",class:[
                _vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                {
                    'transition-transform hover:scale-110': !_vm.disabled,
                },
            ],on:{"mouseover":function($event){return _vm.mouseover(index)},"mouseleave":function($event){return _vm.mouseleave(index)},"click":function($event){return _vm.click(index)}}},[_c('base-icon',_vm._b({},'base-icon',_vm.getIcon(index),false))],1)}),0),(_vm.showText && _vm.activeText)?_c('span',[_vm._v(" "+_vm._s(_vm.activeText)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import cubicBezier from '@/assets/util/cubic-bezier';

export default (progress, end, duration, bezierPoints = { x1: 0.42, y1: 0, x2: 0.58, y2: 1 }) => {
    const { x1, y1, x2, y2 } = bezierPoints;
    const epsilon = 1000 / 60 / duration / 4;

    const timing = cubicBezier(x1, y1, x2, y2, epsilon);

    const start = performance.now();

    requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;

        if (timeFraction < 0) timeFraction = 0;
        if (timeFraction > 1) timeFraction = 1;

        progress(timing(timeFraction));

        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        } else {
            end?.();
        }
    });
};

<template>
    <div class="grid flex-1 text-gray-500">
        <div
            v-for="(item, index) in list"
            :key="index"
            class="inline-flex items-center space-x-1"
            :class="item.class"
        >
            <div class="inline-flex justify-center items-center w-6">
                <base-icon
                    v-if="item.icon.name"
                    :class="item.icon.class"
                    :name="item.icon.name"
                    :prefix="item.icon.prefix"
                />
                <div v-else-if="item.icon.class" :class="item.icon.class"/>
            </div>
            <div
                v-if="item.label"
                class="hidden text-gray-400 xl:block"
            >
                {{ item.label }}:
            </div>
            <div class="font-medium">{{ item.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name:  'ProductListItemInfo',
    props: {
        list: {
            type:     Array,
            required: true,
        },
    },
};
</script>

<template>
    <div class="flex h-full w-full flex-col items-center" v-loader="loader">
        <div class="w-full flex-1 pt-6 pb-8 max-w-8xl lg:pt-8 2xl:pt-12">
            <div v-if="showList" class="flex h-full flex-col space-y-2 sm:space-y-6">
                <div class="flex flex-col justify-between px-4 space-y-2 max-w-8xl sm:px-6 lg:space-x-4 lg:space-y-0 lg:flex-row lg:px-8">
                    <slot name="title">
                        <div class="flex flex-col space-y-2">
                            <span class="text-2xl font-semibold lg:text-3xl">
                                {{ title }}
                            </span>
                            <span class="text-base text-gray-600 lg:text-lg">
                                {{ titleMessage }}
                            </span>
                        </div>
                    </slot>
                    <slot name="actions">
                        <base-button
                            v-if="createRoute"
                            class="self-start sm:block"
                            type="primary"
                            round
                            @click="$router.push({ name: createRoute })"
                        >
                            {{ createButton }}
                        </base-button>
                    </slot>
                </div>
                <div class="sm:px-6 lg:px-8">
                    <slot name="list" :list="list"/>
                </div>
            </div>
            <div v-else class="flex h-full w-full flex-col items-center justify-center">
                <div class="mb-12 flex w-full max-w-3xl flex-col items-center justify-center space-y-4">
                    <slot name="empty">
                        <base-button
                            type="primary"
                            round
                            @click="$router.push({ name: createRoute })"
                        >
                            <span class="px-4 text-xl leading-10">
                                {{ connectButton }}
                            </span>
                        </base-button>
                        <span class="text-center text-xl">
                            {{ connectTitle }}
                        </span>
                        <div class="flex items-center justify-center text-xs text-gray-500 space-x-2">
                            <base-icon name="lock" prefix="fas"/>
                            <span>
                                {{ $t('no credit card') }}
                            </span>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isEmpty, orderBy } from 'lodash';
import pageFetchDataMixin from '@/pages/mixins/fetch-data';

export default {
    name:   'ListWrapper',
    props:  {
        title:         String,
        titleMessage:  String,
        createButton:  String,
        createRoute:   String,
        connectButton: String,
        connectTitle:  String,
    },
    mixins: [pageFetchDataMixin],
    data() {
        return {
            list: [],
        };
    },
    computed: {
        showList() {
            return !isEmpty(this.list);
        },
    },
    methods:  {
        onFetchData(data) {
            this.list = orderBy(data.list, 'updatedAt', 'desc');

            this.$emit('fetch-list', this.list);
        },
    },
};
</script>

<script>
export default {
    name: 'BaseSelect',
};
</script>

<script setup>
import { sortBy } from 'lodash';
import { computed, nextTick, onMounted, provide, ref, toRef, useSlots, watch } from 'vue';
import i18n from '@/plugins/i18n';
import formatNumber from '@/plugins/filters/format-number';
import { SIZE, TYPE } from '@/components/base-components/components/select';
import { TYPE as TAG_TYPE } from '@/components/base-components/components/tag';
import { isValid, PLACEMENTS } from '@/components/base-components/utils/placement';

const props = defineProps({
    value:               {
        type: [Number, String, Array],
    },
    options:             {
        type:     Array,
        required: true,
    },
    optionsLimit:        {
        type:    Number,
        default: 50,
    },
    optionsCount:        {
        type: Number,
    },
    optionValue:         {
        type:    [Number, String],
        default: 'name',
    },
    optionFilter:        {
        type: Function,
    },
    optionLabel:         {
        type: Function,
    },
    useFirst:            {
        type: Boolean,
    },
    type:                {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:                {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    placeholder:         {
        type:    String,
        default: 'Select...',
    },
    noDataText:          {
        type: String,
    },
    noMatchText:         {
        type: String,
    },
    disabled:            {
        type: Boolean,
    },
    clearable:           {
        type: Boolean,
    },
    creatable:           {
        type: Boolean,
    },
    noSorting:           {
        type: Boolean,
    },
    noFiltering:         {
        type: Boolean,
    },
    filterExternal:      {
        type: Boolean,
    },
    limitExternal:       {
        type: Boolean,
    },
    loading:             {
        type: Boolean,
    },
    multiple:            {
        type: Boolean,
    },
    multipleDraggable:   {
        type: Boolean,
    },
    multipleLimit:       {
        type:    Number,
        default: 0,
    },
    collapseMultiple:    {
        type: Boolean,
    },
    maxCollapseMultiple: {
        type:      Number,
        default:   1,
        validator: value => value > 0,
    },
    placement:           {
        type:      String,
        default:   PLACEMENTS.BOTTOM_START,
        validator: isValid,
    },
    trigger:             {
        type:      String,
        default:   'click',
        validator: value => ['hover', 'click'].includes(value),
    },
    noClickOutside:      {
        type: Boolean,
    },
    noHideOnClick:       {
        type: Boolean,
    },
    render:              {
        type:      String,
        default:   'default',
        validator: value => ['default', 'lazy', 'always'].includes(value),
    },
    openDelay:           {
        type:    Number,
        default: 0,
    },
    hideDelay:           {
        type:    Number,
        default: 50,
    },
    transition:          {
        type: Object,
        default() {
            return {
                'enter-active-class': 'transition duration-150 ease-in-out origin-top scale-y-100',
                'leave-active-class': 'transition duration-150 ease-in-out origin-top scale-y-100',
                'leave-to-class':     'opacity-0 origin-top scale-y-50',
                'enter-class':        'opacity-0 origin-top scale-y-50',
            };
        },
    },
    autoWidth:           {
        type: Boolean,
    },
    contentClass:        {
        type: [String, Object],
    },
});

const emit = defineEmits({
    input:   null,
    change:  null,
    clear:   null,
    visible: null,
    filter:  null,
    more:    null,
});

const model = computed({
    get: () => {
        if (props.multiple) return [...(props.value ?? [])];

        return props.value;
    },
    set: (value) => {
        emit('input', value);
        emit('change', value);
    },
});

const getOptionValue = option => option?.[props.optionValue] ?? option?.name ?? option;
const getOptionLabel = option => (props.optionLabel?.(option) ?? option.label ?? option).toString();
const findOption = value => props.options.find(o => getOptionValue(o) === value);
const mapActiveOption = (value) => {
    const option = findOption(value);

    if (option !== undefined) return option;
    if (!props.creatable) return;

    return {
        [props.optionValue]: value,
        label:               value,
        __created:           true,
    };
};
const isActiveOption = (option) => {
    const value = getOptionValue(option);

    return props.multiple ? model.value.includes(value) : model.value === value;
};

const activeOptions = computed(() => {
    if (model.value === null || model.value === undefined) return [];

    const options = props.multiple ? model.value?.map(mapActiveOption) : [mapActiveOption(model.value)];

    return options.filter(Boolean);
});
const createdOptions = computed(() => activeOptions.value?.filter(o => o.__created) ?? []);

watch(activeOptions, () => {
    if (activeOptions.value?.length) return;
    if (!props.useFirst || !props.options.length) return;

    const value = getOptionValue(props.options[0]);

    model.value = props.multiple ? [value] : value;
}, { immediate: true });

const filter = ref('');

const filteredOptions = computed(() => {
    const allOptions = [...createdOptions.value, ...props.options];

    if (props.noFiltering || props.filterExternal) return allOptions;

    return allOptions.filter(o => props.optionFilter?.(o, filter.value) ?? getOptionLabel(o).toLowerCase().includes(filter.value.toLowerCase()));
});

const sortedOptions = computed(() => {
    if (props.noSorting || props.multiple) return filteredOptions.value;

    return sortBy(filteredOptions.value, o => !isActiveOption(o));
});

const limit = ref(props.optionsLimit);

const displayOptions = computed(() => {
    if (props.limitExternal) return sortedOptions.value;

    return sortedOptions.value.slice(0, limit.value);
});

const showCreatedOption = computed(() => {
    if (!props.creatable || props.noSorting || !filter.value) return false;

    if (props.multiple) return !model.value.includes(filter.value);

    return model.value !== filter.value;
});

const limitLabel = computed(() => {
    if (!props.limitExternal && filteredOptions.value.length <= limit.value) return;
    if (props.limitExternal && props.optionsCount <= displayOptions.value.length) return;

    return i18n.t('select display part of all', {
        displayCount: formatNumber(displayOptions.value.length),
        allCount:     props.limitExternal ? props.optionsCount : formatNumber(filteredOptions.value.length),
    });
});

const limitMore = computed(() => {
    if (props.limitExternal) {
        if (props.optionsCount >= displayOptions.value.length + props.optionsLimit) return props.optionsLimit;

        return props.optionsCount - displayOptions.value.length;
    }

    if (filteredOptions.value.length >= displayOptions.value.length + props.optionsLimit) return props.optionsLimit;

    return filteredOptions.value.length - displayOptions.value.length;
});

const increaseLimit = () => {
    limit.value += props.optionsLimit;

    emit('more', limit.value);
};

const referenceRef = ref(null);
const contentMinWidth = ref(null);

const updateContentWidth = () => {
    if (!referenceRef.value) return;

    contentMinWidth.value = `${referenceRef.value.clientWidth}px`;
};

const referenceHover = ref(false);
const onReferenceHover = (value) => {
    if (props.disabled) return;

    referenceHover.value = value;
};

onMounted(updateContentWidth);

const opened = ref(false);
const inputRef = ref(null);

const changeOpened = () => {
    if (props.disabled) return;

    opened.value = !opened.value;
};

watch(opened, async () => {
    if (!opened.value) {
        filter.value = '';
        limit.value = props.optionsLimit;
        inputRef.value.blur();
    } else if (!props.noFiltering) {
        await nextTick();

        inputRef.value.focus();
    }
});

const showClear = computed(() => props.clearable
    && !props.multiple
    && referenceHover.value
    && model.value);
const clear = () => {
    model.value = null;
};

const showInput = computed(() => {
    if (!props.multiple) return true;

    return !model.value.length || (!props.noFiltering && opened.value);
});

const inputValue = computed({
    get() {
        if (opened.value) return filter.value;
        if (props.multiple || !activeOptions.value.length) return '';

        return getOptionLabel(activeOptions.value[0]);
    },
    set(value) {
        filter.value = value;

        emit('filter', value);
    },
});

const inputPlaceholder = computed(() => {
    if (props.multiple) return model.value.length ? null : props.placeholder;
    if (!activeOptions.value.length || !opened.value) return props.placeholder;

    return getOptionLabel(activeOptions.value[0]);
});

const inputNativeSize = computed(() => {
    if (!props.autoWidth) return 5;

    if (opened.value) return inputPlaceholder.value.length + 1;

    return inputValue.value.length + 1;
});

const slots = useSlots();

const inputClass = computed(() => {
    if (slots.prefix) return;

    return opened.value && props.multiple && activeOptions.value.length ? 'pl-2' : 'pl-3.5';
});

const multipleTags = computed({
    get: () => {
        if (!props.multiple) return;
        if (!props.collapseMultiple) return activeOptions.value;

        return [...activeOptions.value].slice(0, props.maxCollapseMultiple);
    },
    set: (tags) => {
        const values = tags.map(tag => tag[props.optionValue]);

        let rest = [];

        if (props.collapseMultiple && model.value.length > props.maxCollapseMultiple) {
            rest = [...model.value].slice(props.maxCollapseMultiple, model.value.length);
        }

        const result = [...values, ...rest];

        emit('input', result);
        emit('change', result);
    },
});
const multipleTagsLeftover = computed(() => {
    if (!props.multiple || !props.collapseMultiple) return;

    return model.value.length - multipleTags.value.length;
});
const tagSize = computed(() => ({
    [SIZE.LARGE]:  'small',
    [SIZE.MEDIUM]: 'mini',
    [SIZE.SMALL]:  'mini',
})[props.size]);

const popoverRef = ref(null);

const change = (option, selected) => {
    if (props.creatable) {
        filter.value = '';
        inputRef.value.focus();
    }

    if (!props.multiple) {
        model.value = option;
        popoverRef.value?.close();

        return;
    }

    if (!selected) {
        model.value.push(option);
    } else {
        model.value.splice(model.value.indexOf(option), 1);
    }

    emit('input', model.value);
    emit('change', model.value);
};

function onInput() {
    if (!props.creatable) return;

    change(filter.value, false);
}

const hoverOption = ref(null);
const setHoverOption = (value) => {
    hoverOption.value = value;
};

const typeClass = computed(() => {
    if (!opened.value) return 'border-gray-400/40';

    return {
        [TYPE.DEFAULT]:       'border-science-blue-800',
        [TYPE.PRIMARY]:       'border-primary-600',
        [TYPE.SECONDARY]:     'border-secondary-950',
        [TYPE.PRIMARY_OLD]:   'border-science-blue-500',
        [TYPE.SECONDARY_OLD]: 'border-science-blue-700',
        [TYPE.SUCCESS]:       'border-emerald-600',
        [TYPE.WARNING]:       'border-orange-500',
        [TYPE.DANGER]:        'border-red-600',
        [TYPE.INFO]:          'border-gray-500',
    }[props.type];
});

provide('base-select', {
    multiple:      toRef(props, 'multiple'),
    multipleLimit: toRef(props, 'multipleLimit'),
    value:         model,
    change,
    hoverOption,
    setHoverOption,
});

defineExpose({
    focus: async () => {
        await nextTick();

        opened.value = true;
        emit('visible', true);
    },
    blur:  async () => {
        await nextTick();

        opened.value = false;
        emit('visible', false);
    },
});
</script>

<template>
    <base-popover
        v-model="opened"
        ref="popoverRef"
        trigger="manual"
        :placement="placement"
        :disabled="disabled"
        :no-click-outside="noClickOutside"
        :render="render"
        :open-delay="openDelay"
        :hide-delay="hideDelay"
        :transition="transition"
        @show="emit('visible', true)"
        @hidden="emit('visible', false)"
    >
        <template #reference>
            <div
                ref="referenceRef"
                class="relative flex flex-1 items-center self-start overflow-hidden rounded border bg-white text-gray-500 transition-colors focus:outline-none"
                :class="[
                    typeClass,
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    {
                        'bg-science-blue-700 bg-opacity-5 opacity-75': disabled,
                        'hover:border-gray-400/80': !disabled && !opened,
                    },
                    {
                        [SIZE.LARGE]:  'min-h-[2.5rem]',
                        [SIZE.MEDIUM]: 'min-h-[2rem]',
                        [SIZE.SMALL]:  'min-h-[1.75rem]',
                    }[props.size]
                ]"
                @click="changeOpened"
                @mouseover="onReferenceHover(true)"
                @mouseleave="onReferenceHover(false)"
            >
                <div class="flex flex-1 flex-wrap">
                    <div
                        v-if="multiple && model.length"
                        class="flex flex-wrap gap-1 p-1"
                    >
                        <base-draggable
                            v-model="multipleTags"
                            :disabled="!multipleDraggable"
                            class="flex flex-wrap gap-1"
                        >
                            <template #default="{ item: tag }">
                                <base-tag
                                    :closable="!disabled"
                                    :key="getOptionValue(tag)"
                                    :label="getOptionLabel(tag)"
                                    :size="tagSize"
                                    :type="tag.__created ? TAG_TYPE.PRIMARY : TAG_TYPE.SECONDARY"
                                    @close.stop="change(getOptionValue(tag), true)"
                                />
                            </template>
                        </base-draggable>

                        <base-tag
                            v-if="multipleTagsLeftover"
                            type="info"
                            :label="`+${ multipleTagsLeftover }`"
                            :size="tagSize"
                        />
                    </div>

                    <base-input
                        v-show="showInput"
                        v-model="inputValue"
                        ref="inputRef"
                        class="pointer-events-none flex-grow"
                        :wrapper-class="[
                            'border-none',
                            {
                                'bg-gray-500 bg-opacity-5 opacity-75': disabled,
                            },
                        ]"
                        :input-class="[
                            'flex-grow',
                            inputClass
                        ]"
                        :placeholder="inputPlaceholder"
                        :size="size"
                        :native-size="inputNativeSize"
                        :disabled="disabled"
                        @keydown.enter="onInput"
                    >
                        <template #prefix>
                            <slot name="prefix"/>
                        </template>
                        <template #suffix>
                            <slot name="suffix"/>
                        </template>
                    </base-input>
                </div>

                <div class="mr-2 ml-1 flex items-center justify-center text-gray-400/80 h-3.5 w-3.5">
                    <svg
                        v-if="showClear"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor"
                        @click.stop="clear"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <svg
                        v-else
                        class="transition-all duration-300"
                        :class="{
                            '-rotate-180': opened,
                        }"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                    </svg>
                </div>

            </div>
        </template>

        <template #content>
            <div
                v-loader.immediate="loading"
                class="flex flex-col"
                :class="contentClass"
                :style="{
                    'min-width': contentMinWidth,
                }"
            >
                <slot name="content-header"/>

                <base-scroll
                    class="flex max-h-64 flex-col text-sm"
                    wrap-class="flex flex-col"
                    view-class="my-1.5"
                >
                    <div v-if="limitLabel" class="px-5 pt-2 pb-2 text-xs text-gray-400">
                        {{ limitLabel }}
                    </div>

                    <template v-if="displayOptions.length || showCreatedOption">
                        <base-select-option
                            v-if="showCreatedOption"
                            :value="filter"
                            :label="filter"
                            :type="type"
                        >
                            <template #prefix>
                                <slot name="option-prefix" :option="{ [optionValue]: filter, label: filter }"/>
                            </template>

                            <slot name="option" :option="{ [optionValue]: filter, label: filter }"/>

                            <template #suffix>
                                <slot name="option-suffix" :option="{ [optionValue]: filter, label: filter }"/>
                            </template>
                        </base-select-option>

                        <base-select-option
                            v-for="option in displayOptions"
                            :key="getOptionValue(option)"
                            :value="getOptionValue(option)"
                            :label="getOptionLabel(option)"
                            :type="option.__created ? TYPE.PRIMARY : type"
                            :created="option.__created"
                            :disabled="!!option.disabled"
                        >
                            <template #prefix>
                                <slot name="option-prefix" :option="option"/>
                            </template>

                            <slot name="option" :option="option"/>

                            <template #suffix>
                                <slot name="option-suffix" :option="option"/>
                            </template>
                        </base-select-option>
                    </template>
                    <slot v-else name="empty">
                        <div class="px-3 py-2 text-sm text-gray-400">
                            <template v-if="!options.length">
                                {{ noDataText || 'There are no options to display' }}
                            </template>
                            <template v-else>
                                {{ noMatchText || 'No options found by this filter' }}
                            </template>
                        </div>
                    </slot>

                    <div
                        v-show="$slots.group || limitMore"
                        class="mx-2 mt-1 flex items-center justify-between gap-x-3 border-t pt-1"
                    >
                        <base-button
                            v-if="limitLabel"
                            size="small"
                            text
                            :label="`Show ${limitMore} more...`"
                            @click="increaseLimit"
                        />
                        <slot name="group"/>
                    </div>
                </base-scroll>

                <slot name="content-footer"/>
            </div>
        </template>
    </base-popover>
</template>

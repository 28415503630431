<script>
export default {
    name: 'BaseColorPaletteItem',
};
</script>

<script setup>
import { SIZE } from '@/components/base-components/components/color/index';
import { computed } from 'vue';

const props = defineProps({
    value:  {
        type:    String,
        default: '#ffffff',
    },
    size:   {
        type:       String,
        default:    SIZE.MEDIUM,
        validation: size => Object.values(SIZE).includes(size),
    },
    active: {
        type: Boolean,
    },
});

const sizeClass = computed(() => ({
    [SIZE.LARGE]:  'h-6 w-6',
    [SIZE.MEDIUM]: 'h-5 w-5',
    [SIZE.SMALL]:  'h-4 w-4',
}[props.size]));
</script>

<template>
    <div
        class="flex cursor-pointer items-center justify-center rounded ring-1 ring-inset ring-black/10"
        :class="sizeClass"
        :style="{
            backgroundColor: value,
        }"
        @click="$emit('click')"
    >
        <base-icon
            v-if="active"
            class="text-sm text-white"
            name="check"
        />
    </div>
</template>

<template>
    <router-view/>
</template>

<script>
import { USER } from '@/assets/util/event-constants';
import { redirect } from '@/assets/util/return-url';

export default {
    name: 'User',
    data() {
        return {
            handleGuid: true,
        };
    },
    created() {
        window.emitter.on(USER.REDIRECT, this.onRedirect);
        window.emitter.on(USER.GUID, this.onGuid);
    },
    beforeDestroy() {
        window.emitter.off(USER.REDIRECT, this.onRedirect);
        window.emitter.off(USER.GUID, this.onGuid);
    },
    methods: {
        onRedirect(value) {
            this.handleGuid = value;
        },
        onGuid() {
            if (!this.handleGuid) return;

            redirect(route => this.$router.push(route));
        },
    },
};
</script>

<script>

export default {
    name: 'BaseToggle',
};
</script>

<script setup>
import { computed } from 'vue';
import { isArray, isObject } from '@/components/base-components/utils/types';
import { TYPE } from '@/components/base-components/components/toggle';

const props = defineProps({
    value:    {
        type: Boolean,
    },
    label:    {
        type: [String, Array, Object],
    },
    type:     {
        type:      String,
        default:   TYPE.PRIMARY,
        validator: value => Object.values(TYPE).includes(value),
    },
    disabled: {
        type: Boolean,
    },
    showIcon: {
        type: Boolean,
    },
});

const emit = defineEmits({
    input:  null,
    change: null,
});

const active = computed({
    get: () => props.value,
    set(value) {
        emit('input', value);
        emit('change', value);
    },
});

function click() {
    if (props.disabled) return;

    active.value = !active.value;
}

const actualLabel = computed(() => {
    if (isObject(props.label)) return props.label[props.value ?? false];
    if (isArray(props.label)) return props.label[props.value ? 1 : 0];

    return props.label;
});

const typeClass = computed(() => {
    if (!active.value) return 'bg-gray-200';

    return {
        [TYPE.PRIMARY]:       'bg-primary-600',
        [TYPE.SECONDARY]:     'bg-secondary-950',
        [TYPE.PRIMARY_OLD]:   'bg-science-blue-500',
        [TYPE.SECONDARY_OLD]: 'bg-science-blue-700',
        [TYPE.SUCCESS]:       'bg-emerald-600',
        [TYPE.WARNING]:       'bg-orange-500',
        [TYPE.DANGER]:        'bg-red-600',
        [TYPE.INFO]:          'bg-gray-500',
    }[props.type];
});

const typeTextClass = computed(() => {
    if (!active.value) return 'text-gray-400';

    return {
        [TYPE.PRIMARY]:       'text-primary-600',
        [TYPE.SECONDARY]:     'text-secondary-950',
        [TYPE.PRIMARY_OLD]:   'text-science-blue-500',
        [TYPE.SECONDARY_OLD]: 'text-science-blue-900',
        [TYPE.SUCCESS]:       'text-emerald-600',
        [TYPE.WARNING]:       'text-orange-500',
        [TYPE.DANGER]:        'text-red-600',
        [TYPE.INFO]:          'text-gray-500',
    }[props.type];
});
</script>

<template>
    <div
        class="flex items-center gap-x-3"
        :class="{
            'opacity-75 cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
        }"
        @click="click"
    >
        <button
            class="inline-flex relative shrink-0 p-0.5 w-8 h-5 rounded-full transition-colors duration-200 ease-in-out focus:outline-none disabled:cursor-not-allowed"
            :class="typeClass"
            :disabled="disabled"
            type="button"
        >
            <span
                class="pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transition duration-200 ease-in-out"
                :class="[
                    active ? 'translate-x-3' : 'translate-x-0',
                    typeTextClass,
                ]"
            >
                <slot name="inner" :active="active">
                    <span
                        v-if="showIcon"
                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        :class="active ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'"
                    >
                      <slot name="inactive-icon">
                          <svg class="h-3 w-3" fill="none" viewBox="0 0 12 12">
                              <path
                                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                              />
                          </svg>
                      </slot>
                    </span>
                    <span
                        v-if="showIcon"
                        class="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out"
                        :class="active ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'"
                    >
                        <slot name="active-icon">
                            <svg class="h-3 w-3" fill="currentColor" viewBox="0 0 12 12">
                                <path
                                    d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                />
                            </svg>
                        </slot>
                    </span>
                </slot>
            </span>
        </button>

        <slot
            name="label"
            :active="active"
            :label="actualLabel"
            :type-class="typeTextClass"
        >
            <span
                v-if="label"
                v-text="actualLabel"
                class="select-none font-medium leading-none"
                :class="typeTextClass"
            />
        </slot>
    </div>
</template>

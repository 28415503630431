<template>
    <div
        v-if="show"
        class="flex fixed inset-0 justify-center items-center w-full h-full bg-gray-900 bg-opacity-50"
        @click.self="closeGallery"
    >
        <div class="flex justify-center items-start">
            <div
                v-if="showNavigation"
                class="flex justify-center items-center w-48 opacity-40 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-100"
                @click="navLeft"
                :style="{
                    height: containerHeight + 'px'
                }"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            </div>
            <div class="flex flex-col gap-y-3 justify-center items-center">
                <div
                    class="w-full h-full bg-no-repeat bg-cover rounded-md transition-all duration-300 ease-in-out"
                    :style="{
                        backgroundImage: 'url(' + items[currentImg].url + ')',
                        width: containerWidth + 'px',
                        height: containerHeight + 'px'
                    }"
                ></div>
                <div
                    :style="{ width: containerWidth + 'px' }"
                    class="px-3 py-2 bg-sky-50 rounded-md"
                >
                    <span class="mr-1 font-semibold">{{ currentImg + 1 }})</span>
                    <span v-html="items[currentImg].text"></span>
                </div>
            </div>
            <div
                v-if="showNavigation"
                class="flex justify-center items-center w-48 opacity-40 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-100"
                @click="navLeft"
                :style="{
                    height: containerHeight + 'px'
                }"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:  'UiGallery',
    props: {
        items:          {
            type:     Array,
            required: true,
        },
        pointer:        {
            type:    Number,
            default: 0,
        },
        showNavigation: {
            type:    Boolean,
            default: true,
        },
        showGallery:    {
            type:    Boolean,
            default: false,
        },
        fullScreen:     {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            show:            this.showGallery,
            currentImg:      this.pointer,
            containerWidth:  0,
            containerHeight: 0,
        };
    },
    methods: {
        navLeft() {
            if (this.currentImg === 0) {
                this.currentImg = this.items.length - 1;
            } else {
                this.currentImg -= 1;
            }
            this.calculateImgSize();
        },
        navRight() {
            if (this.items.length - 1 === this.currentImg) {
                this.currentImg = 0;
            } else {
                this.currentImg += 1;
            }
            this.calculateImgSize();
        },
        calculateImgSize() {
            const img = new Image();
            img.src = this.items[this.currentImg].url;
            img.onload = () => {
                this.calculateSize(img.width, img.height);
            };
        },
        closeGallery() {
            this.show = false;
            this.$emit('closeGallery');
        },
        calculateSize(width, height) {
            let calculateWidth = width;
            let calculatedHeight = height;
            const windowHeight = this.$el.offsetHeight;
            const windowWidth = this.$el.offsetWidth;
            const maxWidth = windowWidth - 400;
            const maxHeight = windowHeight * 0.9;
            let heightMultiplier = 1;
            let widthMultiplier = 1;

            if (calculatedHeight > maxHeight) {
                heightMultiplier = maxHeight / calculatedHeight;
            }

            if (calculateWidth > maxWidth) {
                widthMultiplier = maxWidth / calculateWidth;
            }

            if (heightMultiplier !== 1 || widthMultiplier !== 1) {
                calculatedHeight = heightMultiplier < widthMultiplier
                    ? calculatedHeight * heightMultiplier
                    : calculatedHeight * widthMultiplier;
                calculateWidth = heightMultiplier < widthMultiplier
                    ? calculateWidth * heightMultiplier
                    : calculateWidth * widthMultiplier;
            }

            this.containerWidth = calculateWidth;
            this.containerHeight = calculatedHeight;
        },
    },
    watch: {
        pointer() {
            this.currentImg = this.pointer;
        },
        showGallery() {
            this.show = this.showGallery;
            this.calculateImgSize();
        },
    },
};
</script>

import Vue2Filters from 'vue2-filters';
import VuePortal from '@linusborg/vue-simple-portal';
import VueScreen from 'vue-screen';
import vClickOutside from 'v-click-outside';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor';
import '@/plugins/chart-js';
import '@/plugins/element-ui';
import '@/plugins/error-handler';
import '@/plugins/flag-icon';
import '@/plugins/font-awesome-icons';
import '@/plugins/observe-visibility';
import '@/plugins/swiper';
import '@/plugins/platforms';

import autofocus from '@/plugins/autofocus';
import Bugsnag from '@/plugins/bugsnag';
import Cookies from '@/plugins/cookies';
import CopyToClipboard from '@/plugins/copy-to-clipboard';
import DataAttribute from '@/plugins/data-attribute';
import dropArea from '@/plugins/drop-area';
import loader from '@/plugins/loader';
import filters from '@/plugins/filters';
import HTTP from '@/plugins/http';
import i18n from '@/plugins/i18n';
import websocket from '@/plugins/websocket';
import statistic from '@/plugins/statistic';
import dropbox from '@/plugins/dropbox';
import googleFilePicker from '@/plugins/google-file-picker';

export default (Vue, { store, config }) => {
    Vue.directive('autofocus', autofocus);
    Vue.directive('drop-area', dropArea);
    Vue.directive('loader', loader);
    Vue.use(Bugsnag, { config });
    Vue.use(Cookies, { config });
    Vue.use(CopyToClipboard);
    Vue.use(DataAttribute);
    Vue.use(filters, { config });
    Vue.use(HTTP, { store });
    Vue.use(statistic, { store, config });
    Vue.use(Vue2Filters);
    Vue.use(VuePortal);
    Vue.use(VueScreen, 'tailwind');
    Vue.use(vClickOutside);
    Vue.use(VueMonacoEditorPlugin);
    Vue.use(websocket, { config });
    Vue.use(dropbox, { config });
    Vue.use(googleFilePicker, { config });

    Vue.use(PiniaVuePlugin);
    const pinia = createPinia();

    return { i18n, pinia };
};

<template>
    <div>
        <base-tooltip
            :content="$t('crm change disabled')"
            :disabled="!disabled"
            placement="top"
        >
            <div
                v-autofocus="autofocus"
                tabindex="1"
                class="flex h-11 w-full items-center justify-between rounded border px-2 py-1 focus:outline-none focus:ring ring-primary-400"
                :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                :data-test="dataTest('button')"
                @click.prevent="togglePicker"
            >
                <crm-logo v-if="selectedCRM" :crm="getSelectedType" size="sm"/>
                <div class="text-center text-sm font-bold leading-tight font-avenir">{{ getCRMName }}</div>
                <base-icon
                    class="mr-2 text-gray-600"
                    name="chevron-down"
                    prefix="fas"
                    size="sm"
                />
            </div>
        </base-tooltip>
        <base-modal
            v-if="!disabled"
            v-model="showPicker"
            vertical-align="top"
            size="extra"
            :title="$t(`crm picker title ${prefix}`)"
            :transition="{
                'enter-active-class': 'transition-all duration-300 ease-in-out',
                'leave-active-class': 'transition-all duration-500 ease-in-out',
                'leave-to-class':     'opacity-0 scale-95',
                'enter-class':        'opacity-0 scale-95',
            }"
            @opened="handlePickerOpen"
            @closed="handlePickerClosed"
        >
            <base-input
                v-model="filter"
                ref="filter"
                class="mb-4 w-full"
                size="large"
                :data-test="dataTest('popup-search')"
                :placeholder="$t('crm picker find query')"
            >
                <template #prefix>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-4 w-4 text-gray-300">
                        <path
                            fill-rule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </template>
            </base-input>
            <div v-if="connectionsListLoading" class="h-32 w-full" v-loader="true"/>
            <div v-else class="text-sm">
                <base-alert
                    v-if="!hasVisibleCRM"
                    class="w-full"
                    icon
                    :content="$t('crm picker empty')"
                />
                <div v-else class="space-y-4">
                    <div v-if="displayConnectionsList.length > 0" class="flex flex-col">
                        <h4>{{ $t('crm.picker.connections.list') }}</h4>
                        <div class="grid max-w-full auto-rows-fr gap-2 md:grid-cols-2 xl:grid-cols-3">
                            <div
                                v-for="connection in displayConnectionsList"
                                :key="connection.id"
                                :data-test="dataTest('popup-item')"
                                class="flex cursor-pointer flex-col justify-center gap-2 rounded-lg border px-3 py-2 transition-colors duration-100 ease-in-out hover:bg-gray-50"
                                @click="clickConnection(connection)"
                            >
                                <div class="flex flex-wrap items-center justify-between gap-x-3">
                                    <div class="flex flex-col gap-y-1">
                                        <div class="inline-flex gap-x-2">
                                            <span class="font-medium text-gray-600">{{ connection.platform.name }}</span>
                                            <span class="font-medium text-gray-400">#{{ connection.id }}</span>
                                        </div>
                                        <div class="font-medium text-gray-400">{{ connection.name }}</div>
                                    </div>
                                    <crm-logo :crm="connection.platform.type" size="sm"/>
                                </div>
                                <div v-if="connection.context.length" class="flex flex-wrap items-center justify-start gap-2">
                                    <div
                                        v-for="(item, index) in connection.context"
                                        :key="index"
                                        class="rounded bg-opacity-75 px-2 py-1 text-xs bg-gray-100 text-gray-600 group-hover:bg-gray-200 group-hover:bg-opacity-50"
                                    >
                                        {{ item }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <base-button
                            v-if="showConnectionsViewMore"
                            class="mt-2 self-center"
                            label="View more..."
                            size="small"
                            text
                            @click="displayConnectionsChunks++"
                        />
                    </div>
                    <div v-if="automaticList.length > 0">
                        <h4>{{ $t('crm picker automatic list title') }}</h4>
                        <div
                            class="grid max-w-full cursor-pointer grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                            <div
                                v-for="crm in automaticList"
                                :key="crm.slug"
                                :data-test="dataTest('popup-item')"
                                class="flex flex-col items-center justify-center rounded-lg border p-2 transition-colors duration-100 ease-in-out space-y-1 hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-100"
                                @click="clickNew(crm.type)"
                            >
                                <crm-logo :crm="crm.type" size="sm"/>
                                <span class="text-center">{{ crm.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="customList.length > 0">
                        <h4>{{ $t('crm picker custom list title') }}</h4>
                        <div
                            class="grid max-w-full cursor-pointer grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                            <div
                                v-for="crm in customList"
                                :key="crm.slug"
                                :data-test="dataTest('popup-item')"
                                class="flex flex-col items-center justify-center rounded-lg border p-2 transition-colors duration-100 ease-in-out space-y-1 hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-100"
                                @click="clickNew(crm.type)"
                            >
                                <crm-logo :crm="crm.type" size="sm"/>
                                <span class="text-center">{{ crm.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </base-modal>
    </div>
</template>

<script>
import { filter, has, get } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CrmLogo from '@/components/crm-logo';
import crmCookiesMixin from '@/assets/mixins/crm-cookie';
import { CONNECTION } from '@/store/modules/acl-access/constants';
import { CONNECTION as MODULE_CONNECTION } from '@/modules/list';

export default {
    name:       'CrmPicker',
    components: { CrmLogo },
    mixins:     [crmCookiesMixin],
    props:      {
        platforms:        {
            type: Object,
        },
        prefix:           {
            type:    String,
            default: '',
            validator(value) {
                return [
                    'source',
                    'target',
                    '',
                ].indexOf(value) !== -1;
            },
        },
        selectedPlatform: {
            type: String,
        },
        disabled:         {
            type:    Boolean,
            default: false,
        },
        autofocus:        {
            type:    Boolean,
            default: false,
        },
        showConnections:  Boolean,
    },
    data() {
        return {
            showPicker:     false,
            crmList:        undefined,
            selectedCRM:    null,
            filter:         '',
            filterInput:    null,
            sourceData:     undefined,
            targetData:     undefined,
            defaultCRMName: 'tmp CRM name',
            defaultCRMLogo: 'no-logo',

            displayConnectionsChunks: 1,
            displayConnectionsChunk:  3,
        };
    },
    mounted() {
        this.setDataFromProps();
        this.setPlatformsData();
        window.eventBus.$on(this.$store.state.eventConstants.platformDataSet, () => {
            this.setPlatformsData();
        });
    },
    computed: {
        ...mapGetters('aclAccess', ['isGranted']),
        ...mapGetters('connection', ['connectionsList']),
        ...mapState('connection', {
            connectionsListLoading: state => state.connections.loading,
            connectionsMetaData:    state => state.connections.metaData,
        }),
        getSelectedType() {
            if (this.selectedCRM) {
                return this.selectedCRM.toLowerCase();
            }

            if (this.prefix === 'target') {
                return get(
                    this.$store.state.platform.data,
                    `${this.prefix}.type`,
                    this.defaultCRMLogo,
                ).toLowerCase();
            }

            return this.defaultCRMLogo;
        },
        getCRMName() {
            if (this.selectedCRM && this.crmList && has(this.crmList, this.selectedCRM)) {
                return this.crmList[this.selectedCRM].name;
            }

            if (this.selectedPlatform) {
                return this.selectedPlatform;
            }

            if (has(this.$store.state.platform.data, this.prefix)
                && has(this.$store.state.platform.data[this.prefix], 'name')
            ) {
                return this.$store.state.platform.data[this.prefix].name;
            }

            if (this.selectedCRM) {
                return this.selectedCRM;
            }

            return this.$t(this.defaultCRMName);
        },
        connectionsAvailable() {
            return this.$appModules.has(MODULE_CONNECTION) && this.isGranted(CONNECTION.LIST) && this.showConnections;
        },
        automaticList() {
            return filter(this.crmList, crm => this.isCrm(crm, true));
        },
        customList() {
            return filter(this.crmList, crm => this.isCrm(crm, false));
        },
        showConnectionsViewMore() {
            return this.filteredConnectionsList.length > (this.displayConnectionsChunks * this.displayConnectionsChunk);
        },
        mappedConnectionsList() {
            return this.connectionsList
                .filter(connection => connection.authorization.type !== 'file')
                .map(connection => ({
                    ...connection,
                    context: this.getConnectionContext(connection),
                }))
                .sort((first, second) => second.context.length - first.context.length);
        },
        filteredConnectionsList() {
            if (!this.connectionsAvailable) return [];

            return this.mappedConnectionsList
                .filter((connection) => {
                    if (!this.crmList?.[connection.platform.type]) return false;

                    return connection.platform.name.toLowerCase().includes(this.filter.toLowerCase());
                });
        },
        displayConnectionsList() {
            return this.filteredConnectionsList.slice(0, this.displayConnectionsChunks * this.displayConnectionsChunk);
        },
        hasVisibleCRM() {
            return this.automaticList.length > 0 || this.customList.length > 0 || this.filteredConnectionsList.length > 0;
        },
    },
    watch:    {
        showPicker() {
            if (!this.connectionsAvailable) return;

            this.displayConnectionsChunks = 1;

            if (!this.showPicker) return;

            if (this.connectionsMetaData?.count === undefined) {
                this.loadConnections();
            }
        },
        selectedPlatform() {
            this.setPlatformsData();
        },
        platforms() {
            this.setDataFromProps();
        },
        filter() {
            if (!this.connectionsAvailable) return;

            this.displayConnectionsChunks = 1;
        },
    },
    methods:  {
        ...mapActions('connection', {
            loadConnections:   'LOAD_CONNECTIONS',
            unloadConnections: 'UNLOAD_CONNECTIONS',
        }),
        ...mapMutations({
            setPlatformData: 'platform/SET_PLATFORM_DATA',
        }),
        setPlatformsData() {
            if (has(this.$store.state.platform.data[this.prefix], 'list')) {
                this.crmList = this.$store.state.platform.data[this.prefix].list;
            }

            if (this.selectedPlatform && has(this.crmList, this.selectedPlatform)) {
                this.selectedCRM = this.selectedPlatform;
            } else if (has(this.$store.state.platform.data, this.prefix)) {
                this.selectedCRM = this.$store.state.platform.data[this.prefix].type;
            }

            if (this.selectedCRM && this.crmList && has(this.crmList, this.selectedCRM)) {
                this.$emit('crmChange', this.selectedCRM, null, this.crmList[this.selectedCRM]);
            }
        },
        setDataFromProps() {
            if (this.platforms && Object.keys(this.platforms).length) {
                this.crmList = this.platforms;
                this.setPlatformsData();
            }
        },
        togglePicker() {
            if (!this.disabled) {
                this.showPicker = !this.showPicker;
            }
        },
        isCrm(crm, isAutomatic) {
            const check = this.isCrmFilter(crm) && this.isCrmAvailable(crm);

            if (!check) {
                return false;
            }

            if (isAutomatic) {
                return this.isCrmAutomatic(crm);
            }

            return !this.isCrmAutomatic(crm);
        },
        isCrmAutomatic(crm) {
            if (this.prefix === 'source') {
                return get(crm, 'isSourceSupported', false);
            }

            if (this.prefix === 'target') {
                return get(crm, 'isTargetSupported', false);
            }

            return false;
        },
        isCrmFilter(crm) {
            return crm.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
        },
        isCrmAvailable(crm) {
            if (this.prefix === 'source') {
                return get(crm, 'isSourceAvailable', true);
            }

            if (this.prefix === 'target') {
                return get(crm, 'isTargetAvailable', true);
            }

            return true;
        },
        crmChange(type) {
            const previousCRM = this.selectedCRM;
            const crmData = this.crmList[type];
            this.selectedCRM = crmData.type;
            // this.setCrmCookie(this.prefix, {
            //     type: crmData.type,
            //     name: crmData.name,
            //     slug: crmData.slug,
            // });

            this.setPlatformData({
                prefix: this.prefix,
                data:   {
                    type: crmData.type,
                    name: crmData.name,
                },
            });

            this.showPicker = false;
            if (this.selectedCRM !== previousCRM) {
                this.$emit(
                    'crmChange',
                    this.selectedCRM,
                    previousCRM,
                    this.crmList[this.selectedCRM],
                );
            }
        },
        handlePickerOpen() {
            this.filter = '';
            this.$nextTick(() => this.$refs.filter.$refs.input.focus());
        },
        handlePickerClosed() {
            this.filter = '';

            if (!this.connectionsAvailable) return;

            this.unloadConnections();
        },
        getConnectionContext(connection) {
            return [
                connection.info.userFullName,
                connection.info.userEmail,
                connection.info.companyId,
            ].filter(Boolean);
        },
        clickConnection(connection) {
            this.$emit('use-connection', connection);

            this.crmChange(connection.platform.type);
        },
        clickNew(type) {
            this.$emit('new-connection', type);

            this.crmChange(type);
        },
    },
};
</script>

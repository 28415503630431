<template>
    <div class="flex flex-col justify-center items-center space-y-5 w-full h-full text-gray-400">
        <base-icon name="frown" prefix="far" size="8x"/>
        <span class="text-5xl">
            404 {{ $t('page not found') }}
        </span>
    </div>
</template>

<script>

export default {
    name:   'NotFoundPage',
    props: {
        content: String,
    },
};
</script>

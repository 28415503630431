var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative inline-flex select-none items-center gap-x-2 text-sm font-medium leading-none group",class:[
        _vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        {
            'opacity-50': _vm.disabled,
        },
        _setup.textTypeClass,
    ],on:{"click":_setup.click}},[_c('div',{staticClass:"inline-flex h-5 w-5 shrink-0 select-none appearance-none items-center justify-center rounded border p-0 align-middle text-white transition",class:[
             _setup.typeClass,
             {
                 'group-hover:border-gray-400': !_setup.activeValue && !_vm.disabled
             }
        ]},[(_vm.indeterminate)?_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M18 12H6"}})]):_c('svg',{staticClass:"h-4 transition-all",class:[
                _vm.value ? 'w-4' : 'w-0'
            ],attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M5 13l4 4L19 7"}})])]),(_vm.$slots.default)?[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})]:(_vm.label)?_c('span',{staticClass:"whitespace-nowrap",domProps:{"textContent":_vm._s(_setup.actualLabel)}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
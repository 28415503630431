var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col",class:[
        _vm.isActive ? 'border-gray-200/100' : 'border-gray-200/0',
        {
            'border-t border-b transition-all duration-300 ease-in-out': !_vm.collapseDivide
        }
    ]},[_c('div',{staticClass:"inline-flex items-center p-2 my-1 space-x-2 rounded",class:{
            'hover:bg-gray-50': _vm.titleBackground && !_vm.disabled,
            'group hover:text-science-blue-900 cursor-pointer': !_vm.disabled,
        },on:{"click":_vm.click}},[_c('div',{staticClass:"flex-1 font-medium select-none"},[_vm._t("title",function(){return [(_vm.title !== undefined)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e()]})],2),_c('div',{staticClass:"text-gray-300 rounded-full transition-transform duration-300 ease-in-out group-hover:text-science-blue-900",class:[
                _vm.isActive ? 'rotate-90' : 'rotate-0',
                _vm.disabled ? 'opacity-0' : 'opacity-100'
            ]},[_c('svg',{staticClass:"w-5 h-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])])]),(!_vm.disabled)?_c('div',{ref:"content",staticClass:"overflow-hidden",style:({ height: _vm.contentHeight })},[_c('div',{staticClass:"p-2 my-1"},[_vm._t("default",function(){return [_c('span',{staticClass:"flex justify-center items-center text-gray-400"},[_vm._v("No content")])]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
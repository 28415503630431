<template>
    <product-list-item-wrapper
        :route="route"
        :go-message="$t('backup view details button')"
        wrap-class="grid min-w-0 grid-cols-12 gap-x-1 text-xs sm:text-sm md:text-xs lg:gap-x-4 lg:text-sm"
    >
        <div class="col-span-5 flex flex-col lg:col-span-4 xl:space-x-2 xl:flex-row 2xl:space-x-6">
            <div class="flex w-20 items-center truncate font-medium text-gray-500">
                {{ $t('backup number') }}: {{ backup.id }}
            </div>
            <div class="my-auto shrink-0 py-1">
                <crm-logo-link
                    v-if="backup.source.type"
                    class="flex-1 overflow-hidden"
                    :crm="backup.source.type"
                    :name="backup.source.name"
                    :url="backup.source.url"
                    size="sm"
                    auto-size
                />
            </div>
        </div>
        <div class="col-span-5 flex items-center lg:col-span-6">
            <product-list-item-info
                class="gap-2 text-gray-500 lg:grid-cols-2 lg:gap-4"
                :list="infoItems"
            />
        </div>
        <div class="col-span-2 flex items-center justify-center">
            <div v-if="showRun">
                <base-button
                    class="hidden sm:block"
                    size="small"
                    round
                    :disabled="disableRun"
                    @click="run"
                >
                    Run: {{ runs.valueUsed }}/{{ runs.value }}
                </base-button>
                <base-button
                    class="sm:hidden"
                    link
                    :icon="{ name: 'redo-alt' }"
                    :disabled="disableRun"
                    @click="run"
                />
            </div>
        </div>
    </product-list-item-wrapper>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import { backupInstanceCreate } from '@/components/backup/api';
import ProductListItemWrapper from '@/components/app/product/list/item-wrapper';
import ProductListItemInfo from '@/components/app/product/list/item-info';
import CrmLogoLink from '@/components/crm-logo-link';

export default {
    name:       'BackupListItem',
    components: {
        ProductListItemWrapper,
        ProductListItemInfo,
        CrmLogoLink,
    },
    props:      {
        backup: {
            type:     Object,
            required: true,
        },
    },
    computed:   {
        route() {
            return {
                name:   'backup',
                params: {
                    backupId: this.backup.id,
                },
            };
        },
        runs() {
            return get(this.backup.plan, 'options.backup.features.BackupRun.data');
        },
        showRun() {
            return this.runs.value && this.runs.valueUsed;
        },
        availableRuns() {
            return this.runs.value - this.runs.valueUsed;
        },
        disableRun() {
            return this.availableRuns === 0 || this.hasRunningInstance;
        },
        hasRunningInstance() {
            return !!this.backup.instance?.isRunning;
        },
        demoPlan() {
            return this.backup.plan.type === 'default';
        },
        planExpired() {
            return this.backup.plan.status === 'expired';
        },
        planLabel() {
            if (this.planExpired) return this.$t('plan expired');

            return this.demoPlan ? this.$t('plan free') : this.$t('plan premium');
        },
        planClass() {
            if (this.planExpired) return 'text-red-500';

            return this.demoPlan ? 'text-amber-500' : 'text-emerald-500';
        },
        type() {
            return get(this.runs, 'scope') === 'demo' ? 'demo' : 'full';
        },
        infoItems() {
            return [
                {
                    condition: this.backup.size,
                    icon:      {
                        name:  'database',
                        class: 'text-gray-300',
                    },
                    label:     this.$t('backup size'),
                    value:     this.$filters.formatBytes(this.backup.size),
                },
                {
                    condition: this.backup.instances.demo,
                    icon:      {
                        name:  'eye',
                        class: 'text-gray-300',
                    },
                    label:     this.$t('backup samples'),
                    value:     this.backup.instances.demo,
                },
                {
                    condition: true,
                    icon:      {
                        name:  'award',
                        class: this.planClass,
                    },
                    value:     this.planLabel,
                    class:     'capitalize',
                },
                {
                    condition: this.backup.instances.full,
                    icon:      {
                        name:  'tachometer-alt',
                        class: 'text-gray-300',
                    },
                    label:     this.$t('backup fulls'),
                    value:     this.backup.instances.full,
                },
            ].filter(item => item.condition);
        },
    },
    methods:    {
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
        }),
        async run() {
            await this.showLoader();

            try {
                await backupInstanceCreate(this.backup.id, this.type);

                await this.$router.push(this.route);
            } catch ({ error, message }) {
                this.$notify.error({
                    title:    this.$t('request error notification'),
                    message,
                    duration: 7500,
                });

                throw error;
            } finally {
                await this.hideLoader();
            }
        },
    },
};
</script>
